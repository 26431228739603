// src/components/Teachers/TeacherHomepage.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { db, rtdb } from '../../../firebase';
import {
  doc,
  getDoc,
  collection,
  query,
  onSnapshot,
} from 'firebase/firestore';
import TeacherExcalidrawWhiteboard from '../Whiteboard/TeacherExcalidrawWhiteboard';
import TeacherWorkbook from '../Workbook/teacherworkbook';
import SlideshowApp from '../Slideshow/SlideshowApp';
import AssessmentApp from '../AssessmentApp';
import ClassManagement from '../Management/classmanagement';
import Sidebar from '../Management/Sidebar';
import Toolbar from './toolbar';
import { useDataController } from '../../../contexts/datacontroller';
import logo from '../../assets/beetitle.svg';
import { Hexagons2 } from './HexagonButton2';
import './HexagonButton2.css';
import Bee from './Bee copy';
import './TeacherHomepage.css';
import ViewSites from '../Sites/viewsites';
import Games from '../Read App/Read';
import Tests from '../Build/Build';
import treeImage from './gif1.gif';
import { ref as dbRef, get } from 'firebase/database';
import Progress from '../Progress/Progress.js';
import Timer from './Timer.js';

// Define constants for level calculations
const MAX_LEVEL = 100; // Ensure this matches the MAX_LEVEL in Progress.js
const xpPerLevel = Array(MAX_LEVEL).fill(0).map((_, index) => 100 * (index + 1)); // XP required for each level

// Function to calculate level from XP
const getLevelFromXP = (xp) => {
  if (xp < 0) return 0;
  let accumulatedXP = 0;
  let level = 0;

  while (level < MAX_LEVEL && accumulatedXP + xpPerLevel[level] <= xp) {
    accumulatedXP += xpPerLevel[level];
    level++;
  }

  return level;
};

const TeacherHomepage = () => {
  const { currentUser, userRole } = useAuth();
  console.log(
    'TeacherHomepage rendered. CurrentUser:',
    currentUser,
    'UserRole:',
    userRole
  );

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isToolbarVisible, setIsToolbarVisible] = useState(() => {
    const savedState = localStorage.getItem('isToolbarVisible');
    return savedState ? JSON.parse(savedState) : false;
  });

  // New state variables for toggles
  const [showNames, setShowNames] = useState(() => {
    const saved = localStorage.getItem('showNames');
    return saved ? JSON.parse(saved) : true; // Default to true
  });

  const [showActiveApps, setShowActiveApps] = useState(() => {
    const saved = localStorage.getItem('showActiveApps');
    return saved ? JSON.parse(saved) : true; // Default to true
  });

  // New state variable for Online/All toggle
  const [showOnlyOnline, setShowOnlyOnline] = useState(() => {
    const saved = localStorage.getItem('showOnlyOnline');
    return saved ? JSON.parse(saved) : true; // Default to true (show only online)
  });

  const [username, setUsername] = useState('');
  const [fullscreenApp, setFullscreenApp] = useState(null);
  const [classes, setClasses] = useState([]);
  const [showClassManagement, setShowClassManagement] = useState(false);

  const treeContainerRef = useRef(null);
  const [treeDimensions, setTreeDimensions] = useState(null);

  const { activeClasses, isLoading } = useDataController();
  const [shownClassCodes, setShownClassCodes] = useState({});

  // Update activeBees to store objects with classId, name, xp, currentApp, and isOnline
  const [activeBees, setActiveBees] = useState([]); // Changed from array of names to array of objects
  const [whiteboardInitialData, setWhiteboardInitialData] = useState(null);
  const [isLoadingWhiteboardData, setIsLoadingWhiteboardData] = useState(false);

  // State for Progress Modal
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [progressBees, setProgressBees] = useState([]);

  // New state for Timer visibility
  const [isTimerVisible, setIsTimerVisible] = useState(false);

  // Placeholder states for Poll and Calendar (grey buttons)
  const [isPollActive, setIsPollActive] = useState(false);
  const [isCalendarActive, setIsCalendarActive] = useState(false);

  // New State for Average XP and Average Level
  const [averageXP, setAverageXP] = useState(0);
  const [averageLevel, setAverageLevel] = useState(0); // New state for average level

  const toggleClassCode = (classId) => {
    setShownClassCodes((prev) => ({
      ...prev,
      [classId]: !prev[classId],
    }));
  };

  const updateTreeDimensions = useCallback(() => {
    if (treeContainerRef.current) {
      const rect = treeContainerRef.current.getBoundingClientRect();
      setTreeDimensions({
        width: rect.width,
        height: rect.height,
      });
    }
  }, []);

  useEffect(() => {
    updateTreeDimensions();
    window.addEventListener('resize', updateTreeDimensions);

    return () => {
      window.removeEventListener('resize', updateTreeDimensions);
    };
  }, [updateTreeDimensions]);

  useEffect(() => {
    console.log('TeacherHomepage useEffect triggered.');
    let unsubscribeClasses = null;

    const fetchData = async () => {
      if (currentUser) {
        console.log('Fetching data for currentUser:', currentUser.uid);
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          console.log('Fetching user document:', userDocRef.path);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            console.log('User data fetched:', userData);
            setUsername(userData.name || 'Teacher');
          } else {
            console.warn('No user document found for:', currentUser.uid);
            setUsername('Teacher');
          }

          const classesQuery = query(
            collection(db, `users/${currentUser.uid}/classes`)
          );
          console.log('Setting up onSnapshot listener for classes.');
          unsubscribeClasses = onSnapshot(classesQuery, (snapshot) => {
            const fetchedClasses = snapshot.docs.map((docSnap) => ({
              id: docSnap.id,
              ...docSnap.data(),
            }));
            console.log('Fetched classes:', fetchedClasses);
            setClasses(fetchedClasses);
          });
        } catch (error) {
          console.error('Error fetching data:', error);
          setUsername('Teacher');
        }
      } else {
        console.log('No currentUser. Clearing classes and username.');
        setClasses([]);
        setUsername('');
      }
    };

    fetchData();

    return () => {
      if (unsubscribeClasses) {
        console.log('Unsubscribing from classes onSnapshot.');
        unsubscribeClasses();
      }
    };
  }, [currentUser]);

  // Persist toolbar visibility
  useEffect(() => {
    localStorage.setItem('isToolbarVisible', JSON.stringify(isToolbarVisible));
  }, [isToolbarVisible]);

  // Persist showNames state
  useEffect(() => {
    localStorage.setItem('showNames', JSON.stringify(showNames));
  }, [showNames]);

  // Persist showActiveApps state
  useEffect(() => {
    localStorage.setItem('showActiveApps', JSON.stringify(showActiveApps));
  }, [showActiveApps]);

  // Persist showOnlyOnline state
  useEffect(() => {
    localStorage.setItem('showOnlyOnline', JSON.stringify(showOnlyOnline));
  }, [showOnlyOnline]);

  const handleStartApp = useCallback((appName, payload = {}) => {
    console.log(`handleStartApp called with appName: ${appName}, payload:`, payload);
    setFullscreenApp({ appName, ...payload });
  }, []);

  const handleCloseApp = useCallback(() => {
    console.log('handleCloseApp called.');
    setFullscreenApp(null);
  }, []);

  const toggleSidebar = useCallback(() => {
    console.log(`Sidebar toggle. Currently open: ${isSidebarOpen}`);
    setIsSidebarOpen((prev) => !prev);
  }, [isSidebarOpen]);

  const handleManageClasses = useCallback(() => {
    console.log('handleManageClasses called.');
    setShowClassManagement(true);
  }, []);

  const handleCloseClassManagement = useCallback(() => {
    console.log('handleCloseClassManagement called.');
    setShowClassManagement(false);
  }, []);

  // Handler Functions for Hexagons
  const handleStartSlides = useCallback(() => {
    console.log('handleStartSlides called.');
    if (classes.length === 0) {
      alert('No classes available to start Slides.');
      return;
    }
    const classID = classes[0].id;
    handleStartApp('Slideshow', { classID });
  }, [classes, handleStartApp]);

  const handleStartWhiteboard = useCallback(async () => {
    console.log('handleStartWhiteboard called.');
    if (classes.length === 0) {
      alert('No classes available to start the Whiteboard.');
      return;
    }

    setIsLoadingWhiteboardData(true);
    try {
      const activeClassIDs = classes.map((cls) => cls.id);
      const fetchedData = await fetchWhiteboardData(activeClassIDs);
      setWhiteboardInitialData(fetchedData);
      handleStartApp('Whiteboard', { activeClassIDs });
    } catch (error) {
      console.error('Error fetching whiteboard data:', error);
      alert('Failed to load whiteboard data.');
    } finally {
      setIsLoadingWhiteboardData(false);
    }
  }, [classes, handleStartApp]);

  const handleStartBookQuest = useCallback(() => {
    console.log('handleStartBookQuest called.');
    if (classes.length === 0) {
      alert('No classes available to start Book Quest.');
      return;
    }
    const classID = classes[0].id;
    handleStartApp('BookQuest', { classID });
  }, [classes, handleStartApp]);

  const handleStartAssessment = useCallback(() => {
    console.log('handleStartAssessment called.');
    if (classes.length === 0) {
      alert('No classes available to start Assessment.');
      return;
    }
    const classID = classes[0].id;
    handleStartApp('Assessment', { classID });
  }, [classes, handleStartApp]);

  const handleStartTeacherWorkbook = useCallback(() => {
    console.log('handleStartTeacherWorkbook called.');
    if (classes.length === 0) {
      alert('No classes available to open the Workbook.');
      return;
    }
    const classID = classes[0].id;
    handleStartApp('Workbook', { classID });
  }, [classes, handleStartApp]);

  const handleStartViewSites = useCallback(() => {
    console.log('handleStartViewSites called.');
    handleStartApp('ViewSites');
  }, [handleStartApp]);

  const handleStartGames = useCallback(() => {
    console.log('handleStartGames called.');
    if (classes.length === 0) {
      alert('No classes available to open Games.');
      return;
    }
    const classID = classes[0].id;
    handleStartApp('Games', { classID });
  }, [classes, handleStartApp]);

  const handleStartTests = useCallback(() => {
    console.log('handleStartTests called.');
    if (classes.length === 0) {
      alert('No classes available to open Tests.');
      return;
    }
    const classID = classes[0].id;
    handleStartApp('Tests', { classID });
  }, [classes, handleStartApp]);

  // Handler to open Progress Modal
  const handleOpenProgressModal = useCallback(() => {
    console.log('Opening Progress Modal.');
    setProgressBees(activeBees);
    setShowProgressModal(true);
  }, [activeBees]);

  // Handler to close Progress Modal
  const handleCloseProgressModal = useCallback(() => {
    console.log('Closing Progress Modal.');
    setShowProgressModal(false);
  }, []);

  // Synchronize activeBees based on activeClasses and filters
  useEffect(() => {
    const bees = [];
    for (const classId in activeClasses) {
      const classData = activeClasses[classId];
      if (classData.beeNames) {
        for (const beeKey in classData.beeNames) {
          const bee = classData.beeNames[beeKey];
          const beeName = (bee.name || beeKey || '').trim(); // Ensure beeName is a trimmed string

          if (showOnlyOnline) {
            // Show only online bees
            if (bee.logged === 'yes' && beeName.length > 0) {
              bees.push({
                classId,
                name: beeName,
                isOnline: true,
                xp: bee.totalxp || 0, // Assuming totalxp is stored
                currentApp: bee.currentApp || 'None', // Assuming currentApp is stored
              });
              console.log(`Adding bee: "${beeName}" from class: "${classId}" (Online)`);
            }
          } else {
            // Show all bees
            if (beeName.length > 0) {
              bees.push({
                classId,
                name: beeName,
                isOnline: bee.logged === 'yes',
                xp: bee.totalxp || 0, // Assuming totalxp is stored
                currentApp: bee.currentApp || 'None', // Assuming currentApp is stored
              });
              console.log(
                `Adding bee: "${beeName}" from class: "${classId}" (Online: ${bee.logged === 'yes'})`
              );
            }
          }
        }
      }
    }
    setActiveBees(bees);
    console.log('Active bees set to:', bees);
  }, [activeClasses, showOnlyOnline]);

  /**
   * Fetch whiteboard data from RTDB for the given class IDs.
   * @param {Array<string>} classIDs - Array of class IDs.
   * @returns {Promise<Object>} - Combined whiteboard data.
   */
  const fetchWhiteboardData = async (classIDs) => {
    const combinedElements = [];
    const combinedAppState = {};

    for (const classId of classIDs) {
      const whiteboardRef = dbRef(rtdb, `activeclasses/${classId}/whiteboard/Teacher`);
      const snapshot = await get(whiteboardRef);
      if (snapshot.exists()) {
        const classWhiteboardData = snapshot.val();
        if (classWhiteboardData.elements) {
          combinedElements.push(...classWhiteboardData.elements);
        }
        if (classWhiteboardData.appState) {
          Object.assign(combinedAppState, classWhiteboardData.appState);
        }
      }
    }

    return { elements: combinedElements, appState: combinedAppState };
  };

  // Calculate Average XP and Average Level
  useEffect(() => {
    if (activeBees.length === 0) {
      setAverageXP(0);
      setAverageLevel(0);
      return;
    }
    const totalXP = activeBees.reduce((acc, bee) => acc + (bee.xp || 0), 0);
    const avgXP = totalXP / activeBees.length;
    setAverageXP(avgXP);

    const avgLevel = getLevelFromXP(avgXP);
    setAverageLevel(avgLevel);

    console.log(`Calculated averageXP: ${avgXP}, averageLevel: ${avgLevel}`);
  }, [activeBees]);

  // Toggle Timer visibility
  const toggleTimer = () => {
    setIsTimerVisible((prev) => !prev);
  };

  // Toggle Poll active state (placeholder)
  const togglePoll = () => {
    setIsPollActive((prev) => !prev);
  };

  // Toggle Calendar active state (placeholder)
  const toggleCalendar = () => {
    setIsCalendarActive((prev) => !prev);
  };

  return (
    <div className="relative flex flex-col w-full min-h-screen p-0 bg-gradient-to-br from-blue-50 to-green-50">
      {/* Sidebar */}
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        username={username}
        handleStartApp={handleStartApp}
        onManageClasses={handleManageClasses}
        classes={classes}
        setClasses={setClasses}
      />
      
      {/* Main Content Wrapper */}
      <div className="flex flex-col flex-1 min-h-0 p-4">
        {/* Header with Logo */}
        <header className="flex items-center justify-center flex-none h-20 mb-4">
          <img src={logo} alt="Logo" className="w-auto h-20" />
        </header>

        {/* Class Codes Display */}
        <div className="flex-none px-4 mb-4 text-center">
          {isLoading ? (
            <p className="text-gray-500">Loading...</p>
          ) : Object.keys(activeClasses).length === 0 ? (
            <p className="text-gray-500">No active classes</p>
          ) : (
            <div className="flex flex-row flex-wrap justify-center gap-2">
              {Object.entries(activeClasses).map(([id, classData]) => (
                <button
                  key={id}
                  onClick={() => toggleClassCode(classData.id)}
                  className={`px-2 py-1 rounded-md font-bold shadow-md transition-colors duration-300 focus:outline-none text-sm ${
                    shownClassCodes[classData.id]
                      ? 'bg-[#E0A634] text-white hover:bg-[#DFA52E]'
                      : 'bg-gray-500 text-white hover:bg-gray-600'
                  }`}
                >
                  {shownClassCodes[classData.id]
                    ? `${classData.name}: ${classData.classcode}`
                    : `${classData.name}`}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Content Area */}
        <div className="flex flex-col flex-1 min-h-0">
          {/* Tree Image and Bees Container */}
          <div className="relative w-full mx-auto mb-5 bg-transparent">
            <div className="relative flex justify-center inline-block w-full" ref={treeContainerRef}>
              <img
                src={treeImage}
                alt="Welcome Tree"
                className="object-contain h-full"
                style={{ width: '95%', maxHeight: '400px'}}
                onLoad={updateTreeDimensions}
              />

                {/* Bees Flying Around the Tree */}
                {treeDimensions && activeBees.length > 0 && (
                  <div className="absolute inset-0">
                    {activeBees
                      .filter(({ name }) => typeof name === 'string' && name.trim().length > 0)
                      .map(({ classId, name, isOnline }) => (
                        <Bee
                          key={`${classId}-${name}`} // Updated key
                          classId={classId} // Passing classId to Bee
                          name={name} // Passing name to Bee
                          boundaries={treeDimensions} // Passing boundaries to Bee
                          showName={showNames} // Existing prop
                          showActiveApp={showActiveApps} // Existing prop
                          isOnline={isOnline} // New prop
                        />
                      ))}
                  </div>
                )}
              </div>
            </div>

            {/* Tiles Section with Hexagon Arrangement */}
            <div className="relative flex-1 w-full h-full mx-auto mt-20 mb-5 bg-transparent">
              {/* Ensure the container is a flex container to allow Hexagons to fill it */}
              <div className="flex flex-col w-full h-full">
                <Hexagons2
                  className="flex-1 w-full h-full"
                  handleStartSlides={handleStartSlides}
                  handleStartWhiteboard={handleStartWhiteboard}
                  handleStartBookQuest={handleStartBookQuest}
                  handleStartAssessment={handleStartAssessment}
                  handleStartTeacherWorkbook={handleStartTeacherWorkbook}
                  handleStartViewSites={handleStartViewSites}
                  handleStartGames={handleStartGames}
                  handleStartTests={handleStartTests}
                  handleOpenProgressModal={handleOpenProgressModal}
                  averageXP={averageXP}
                  averageLevel={averageLevel} // Pass averageLevel
                />
              </div>
            </div>

            {/* Footer Section */}
            <footer className="w-full py-4 text-center bg-transparent">
              <p className="text-transparent">
                © {new Date().getFullYear()} Your School Name. All rights reserved.
              </p>
            </footer>
          </div>
        </div>

        {/* Timer Component */}
        {isTimerVisible && <Timer classId={classes.length > 0 ? classes[0].id : 'default-class-id'} />}
        {/* Replace "default-class-id" with the actual class ID, possibly from props or context */}

        {/* Toolbar Component */}
        <Toolbar
          isVisible={isToolbarVisible}
          toggleToolbar={() => setIsToolbarVisible((prev) => !prev)}
          showNames={showNames}
          toggleShowNames={() => setShowNames((prev) => !prev)}
          showActiveApps={showActiveApps}
          toggleShowActiveApps={() => setShowActiveApps((prev) => !prev)}
          showOnlyOnline={showOnlyOnline}
          toggleShowOnlyOnline={() => setShowOnlyOnline((prev) => !prev)}
          onToggleTimer={toggleTimer} // Pass the toggle function
          timerActive={isTimerVisible} // Pass the timerActive prop
          pollActive={isPollActive} // Placeholder for Poll active state
          togglePoll={togglePoll} // Placeholder for toggling Poll
          calendarActive={isCalendarActive} // Placeholder for Calendar active state
          toggleCalendar={toggleCalendar} // Placeholder for toggling Calendar
        />

        {/* Fullscreen App Overlays */}
        {fullscreenApp && fullscreenApp.appName && (
          <>
            {fullscreenApp.appName === 'Whiteboard' && (
              <TeacherExcalidrawWhiteboard
                show={true}
                handleClose={handleCloseApp}
                activeClassIDs={fullscreenApp.activeClassIDs}
                initialData={whiteboardInitialData}
                isLoading={isLoadingWhiteboardData}
              />
            )}
            {fullscreenApp.appName === 'Workbook' && (
              <TeacherWorkbook
                onClose={handleCloseApp}
                classID={fullscreenApp.classID}
              />
            )}
            {fullscreenApp.appName === 'Slideshow' && (
              <SlideshowApp
                onClose={handleCloseApp}
                classID={fullscreenApp.classID}
              />
            )}
            {fullscreenApp.appName === 'Assessment' && (
              <AssessmentApp
                onClose={handleCloseApp}
                classID={fullscreenApp.classID}
              />
            )}
            {fullscreenApp.appName === 'ClassManagement' && (
              <ClassManagement
                onClose={handleCloseClassManagement}
                classes={classes}
                setClasses={setClasses}
              />
            )}
            {fullscreenApp.appName === 'ViewSites' && (
              <ViewSites show={true} handleClose={handleCloseApp} />
            )}
            {fullscreenApp.appName === 'Games' && (
              <Games onClose={handleCloseApp} classID={fullscreenApp.classID} />
            )}
            {fullscreenApp.appName === 'Tests' && (
              <Tests onClose={handleCloseApp} classID={fullscreenApp.classID} />
            )}
          </>
        )}

        {/* Class Management Modal */}
        {showClassManagement && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 mb-50">
            <div className="w-11/12 max-w-3xl p-4 bg-white rounded-lg shadow-lg">
              <ClassManagement
                onClose={handleCloseClassManagement}
                classes={classes}
                setClasses={setClasses}
              />
            </div>
          </div>
        )}

        {/* Progress Modal */}
        <Progress
          show={showProgressModal}
          handleClose={handleCloseProgressModal}
          title="Class Progress"
          bees={progressBees}
        />
      </div>
    );
};

export default TeacherHomepage;
