import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import { Text } from '@react-three/drei';

const WorldSphere = memo(({ 
  guidanceSystem, 
  orbitLock,
  viewLock,
  gravity,
  gravityAssist,
  land,
  selectedCelestialBody,
  speed,
  timelapse,
  onUpdatePositions
}) => {
  const groupRef = useRef();
  const earthRef = useRef();
  const moonRef = useRef();
  const moonOrbitRef = useRef();
  const planetRefs = useRef({
    mercury: useRef(),
    venus: useRef(),
    mars: useRef(),
    jupiter: useRef(),
    saturn: useRef(),
    uranus: useRef(),
    neptune: useRef(),
  });

  const moonRefs = useRef({
    // Mars moons
    phobos: { orbit: useRef(), moon: useRef() },
    deimos: { orbit: useRef(), moon: useRef() },
    // Jupiter moons
    io: { orbit: useRef(), moon: useRef() },
    europa: { orbit: useRef(), moon: useRef() },
    ganymede: { orbit: useRef(), moon: useRef() },
    callisto: { orbit: useRef(), moon: useRef() },
    // Saturn moons
    titan: { orbit: useRef(), moon: useRef() },
    enceladus: { orbit: useRef(), moon: useRef() },
    mimas: { orbit: useRef(), moon: useRef() },
    // Uranus moons
    miranda: { orbit: useRef(), moon: useRef() },
    titania: { orbit: useRef(), moon: useRef() },
    oberon: { orbit: useRef(), moon: useRef() },
    // Neptune moons
    triton: { orbit: useRef(), moon: useRef() },
  });

  const labelRefs = useRef({
    sun: useRef(),
    mercury: useRef(),
    venus: useRef(),
    earth: useRef(),
    mars: useRef(),
    jupiter: useRef(),
    saturn: useRef(),
    uranus: useRef(),
    neptune: useRef(),
    // Moon labels
    moon: useRef(),
    phobos: useRef(),
    deimos: useRef(),
    io: useRef(),
    europa: useRef(),
    ganymede: useRef(),
    callisto: useRef(),
    titan: useRef(),
    enceladus: useRef(),
    mimas: useRef(),
    miranda: useRef(),
    titania: useRef(),
    oberon: useRef(),
    triton: useRef(),
  });

  const orbitRefs = useRef({
    mercury: useRef(),
    venus: useRef(),
    earth: useRef(),
    mars: useRef(),
    jupiter: useRef(),
    saturn: useRef(),
    uranus: useRef(),
    neptune: useRef(),
  });

  useEffect(() => {
    console.log('Guidance System State:', guidanceSystem);
    console.log('Orbit Lock State:', orbitLock);
    console.log('View Lock State:', viewLock);
    console.log('Gravity State:', gravity);
    console.log('Gravity Assist State:', gravityAssist);
    console.log('Land State:', land);
    console.log('Selected Body:', selectedCelestialBody);
    console.log('Speed Value:', speed);
    console.log('Timelapse Value:', timelapse);
  }, [
    guidanceSystem,
    orbitLock,
    viewLock,
    gravity,
    gravityAssist,
    land,
    selectedCelestialBody,
    speed,
    timelapse
  ]);

  useEffect(() => {
    Object.values(labelRefs.current).forEach(ref => {
      if (ref.current) {
        ref.current.visible = guidanceSystem;
      }
    });
  }, [guidanceSystem]);

  useFrame(({ clock, camera }) => {
    // Calculate speed multiplier based on timelapse value (-100 to 100)
    const speedMultiplier = timelapse / 100;

    // Add orbital rotation for each planet
    if (orbitRefs.current.mercury.current) {
      orbitRefs.current.mercury.current.rotation.y += 0.0005 * speedMultiplier;
    }
    if (orbitRefs.current.venus.current) {
      orbitRefs.current.venus.current.rotation.y += 0.0004 * speedMultiplier;
    }
    if (orbitRefs.current.earth.current) {
      orbitRefs.current.earth.current.rotation.y += 0.0003 * speedMultiplier;
    }
    if (orbitRefs.current.mars.current) {
      orbitRefs.current.mars.current.rotation.y += 0.00025 * speedMultiplier;
    }
    if (orbitRefs.current.jupiter.current) {
      orbitRefs.current.jupiter.current.rotation.y += 0.0002 * speedMultiplier;
    }
    if (orbitRefs.current.saturn.current) {
      orbitRefs.current.saturn.current.rotation.y += 0.00015 * speedMultiplier;
    }
    if (orbitRefs.current.uranus.current) {
      orbitRefs.current.uranus.current.rotation.y += 0.0001 * speedMultiplier;
    }
    if (orbitRefs.current.neptune.current) {
      orbitRefs.current.neptune.current.rotation.y += 0.00008 * speedMultiplier;
    }

    // Planet rotations
    if (planetRefs.current.mercury.current) {
      planetRefs.current.mercury.current.rotation.y += 0.002 * speedMultiplier;
    }
    if (planetRefs.current.venus.current) {
      planetRefs.current.venus.current.rotation.y += 0.0015 * speedMultiplier;
    }
    if (planetRefs.current.mars.current) {
      planetRefs.current.mars.current.rotation.y += 0.001 * speedMultiplier;
    }
    if (planetRefs.current.jupiter.current) {
      planetRefs.current.jupiter.current.rotation.y += 0.003 * speedMultiplier;
    }
    if (planetRefs.current.saturn.current) {
      planetRefs.current.saturn.current.rotation.y += 0.002 * speedMultiplier;
    }
    if (planetRefs.current.uranus.current) {
      planetRefs.current.uranus.current.rotation.y += 0.001 * speedMultiplier;
    }
    if (planetRefs.current.neptune.current) {
      planetRefs.current.neptune.current.rotation.y += 0.001 * speedMultiplier;
    }

    // Moon orbits and rotations
    if (moonOrbitRef.current) {
      moonOrbitRef.current.rotation.y += 0.005 * speedMultiplier;
    }
    if (moonRef.current) {
      moonRef.current.rotation.y += 0.001 * speedMultiplier;
    }

    // Mars moons
    if (moonRefs.current.phobos.orbit.current) {
      moonRefs.current.phobos.orbit.current.rotation.y += 0.0008 * speedMultiplier;
      moonRefs.current.phobos.orbit.current.rotation.x = Math.PI * 0.1;
    }
    if (moonRefs.current.deimos.orbit.current) {
      moonRefs.current.deimos.orbit.current.rotation.y += 0.0006 * speedMultiplier;
      moonRefs.current.deimos.orbit.current.rotation.x = Math.PI * 0.15;
    }

    // Jupiter moons
    if (moonRefs.current.io.orbit.current) {
      moonRefs.current.io.orbit.current.rotation.y += 0.0005 * speedMultiplier;
      moonRefs.current.io.orbit.current.rotation.x = Math.PI * 0.02;
    }
    if (moonRefs.current.europa.orbit.current) {
      moonRefs.current.europa.orbit.current.rotation.y += 0.0004 * speedMultiplier;
      moonRefs.current.europa.orbit.current.rotation.x = Math.PI * 0.03;
    }
    if (moonRefs.current.ganymede.orbit.current) {
      moonRefs.current.ganymede.orbit.current.rotation.y += 0.0003 * speedMultiplier;
      moonRefs.current.ganymede.orbit.current.rotation.x = Math.PI * 0.04;
    }
    if (moonRefs.current.callisto.orbit.current) {
      moonRefs.current.callisto.orbit.current.rotation.y += 0.0002 * speedMultiplier;
      moonRefs.current.callisto.orbit.current.rotation.x = Math.PI * 0.05;
    }

    // Saturn moons
    if (moonRefs.current.titan.orbit.current) {
      moonRefs.current.titan.orbit.current.rotation.y += 0.0002 * speedMultiplier;
      moonRefs.current.titan.orbit.current.rotation.x = Math.PI * 0.08;
    }
    if (moonRefs.current.enceladus.orbit.current) {
      moonRefs.current.enceladus.orbit.current.rotation.y += 0.0004 * speedMultiplier;
      moonRefs.current.enceladus.orbit.current.rotation.x = Math.PI * 0.06;
    }
    if (moonRefs.current.mimas.orbit.current) {
      moonRefs.current.mimas.orbit.current.rotation.y += 0.0005 * speedMultiplier;
      moonRefs.current.mimas.orbit.current.rotation.x = Math.PI * 0.07;
    }

    // Uranus moons
    if (moonRefs.current.miranda.orbit.current) {
      moonRefs.current.miranda.orbit.current.rotation.y += 0.0004 * speedMultiplier;
      moonRefs.current.miranda.orbit.current.rotation.x = Math.PI * 0.4;
    }
    if (moonRefs.current.titania.orbit.current) {
      moonRefs.current.titania.orbit.current.rotation.y += 0.0003 * speedMultiplier;
      moonRefs.current.titania.orbit.current.rotation.x = Math.PI * 0.42;
    }
    if (moonRefs.current.oberon.orbit.current) {
      moonRefs.current.oberon.orbit.current.rotation.y += 0.0002 * speedMultiplier;
      moonRefs.current.oberon.orbit.current.rotation.x = Math.PI * 0.45;
    }

    // Neptune moon
    if (moonRefs.current.triton.orbit.current) {
      moonRefs.current.triton.orbit.current.rotation.y -= 0.0002 * speedMultiplier;
      moonRefs.current.triton.orbit.current.rotation.x = Math.PI * 0.1;
    }

    Object.values(moonRefs.current).forEach(({ moon }) => {
      if (moon.current) {
        moon.current.rotation.y += 0.001 * speedMultiplier;
      }
    });

    if (earthRef.current) {
      earthRef.current.rotation.y += 0.001 * speedMultiplier;
    }

    Object.entries(labelRefs.current).forEach(([key, ref]) => {
      if (ref.current) {
        ref.current.lookAt(camera.position);
      }
    });

    // Define positions object
    const positions = {};
    const frustum = new THREE.Frustum();
    const projScreenMatrix = new THREE.Matrix4();
    projScreenMatrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
    frustum.setFromProjectionMatrix(projScreenMatrix);

    // Helper function to calculate screen position and visibility
    const calculateScreenPosition = (object, name) => {
      if (!object.current) return null;

      const worldPosition = new THREE.Vector3();
      object.current.getWorldPosition(worldPosition);

      // Check if object is in view frustum
      const isVisible = frustum.containsPoint(worldPosition);

      // Convert world position to screen position
      const screenPosition = worldPosition.clone().project(camera);
      
      // Convert to pixel coordinates
      const x = (screenPosition.x + 1) * window.innerWidth / 2;
      const y = (-screenPosition.y + 1) * window.innerHeight / 2;
      
      // Calculate if object is behind camera
      const isBehindCamera = screenPosition.z > 1;

      return {
        name,
        x,
        y,
        isVisible: isVisible && !isBehindCamera,
        distance: camera.position.distanceTo(worldPosition),
        worldPosition: {
          x: worldPosition.x,
          y: worldPosition.y,
          z: worldPosition.z
        }
      };
    };

    // Calculate positions for all celestial bodies
    positions.sun = calculateScreenPosition(groupRef, 'Sun');
    positions.earth = calculateScreenPosition(earthRef, 'Earth');
    positions.moon = calculateScreenPosition(moonRef, 'Moon');

    // Calculate planet positions
    Object.entries(planetRefs.current).forEach(([planet, ref]) => {
      positions[planet] = calculateScreenPosition(ref, planet.charAt(0).toUpperCase() + planet.slice(1));
    });

    // Calculate moon positions
    Object.entries(moonRefs.current).forEach(([moon, { moon: moonRef }]) => {
      positions[moon] = calculateScreenPosition(moonRef, moon.charAt(0).toUpperCase() + moon.slice(1));
    });

    // Add safety check before calling onUpdatePositions
    if (typeof onUpdatePositions === 'function') {
      onUpdatePositions(positions);
    }
  });

  return (
    <>
      {/* Sun at center of solar system */}
      <group position={[0, 0, 0]}>
        <Text
          ref={labelRefs.current.sun}
          position={[0, 10000000, 0]}
          fontSize={5000000}
          color="white"
          anchorX="center"
          anchorY="bottom"
          renderOrder={10000}
          depthWrite={false}
        >
          {""}
        </Text>
        <mesh renderOrder={2}>
          <sphereGeometry args={[696340, 64, 32]} />
          <meshBasicMaterial 
            color="#FFD700"
            side={THREE.FrontSide}
            depthWrite={true}
            depthTest={true}
            transparent={false}
            opacity={1}
            blending={THREE.NormalBlending}
          />
        </mesh>

        {/* All planets orbit around this central point */}
        
        {/* Mercury */}
        <group ref={orbitRefs.current.mercury}>
          <group position={[57900000, 0, 0]} ref={planetRefs.current.mercury}>
            <Text
              ref={labelRefs.current.mercury}
              position={[0, 50000, 0]}
              fontSize={25000}
              color="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <mesh renderOrder={2}>
              <sphereGeometry args={[2439.7, 32, 32]} />
              <meshBasicMaterial color="#A0522D" side={THREE.FrontSide} />
            </mesh>
          </group>
        </group>

        {/* Venus */}
        <group ref={orbitRefs.current.venus}>
          <group position={[108200000, 0, 0]} ref={planetRefs.current.venus}>
            <Text
              ref={labelRefs.current.venus}
              position={[0, 50000, 0]}
              fontSize={25000}
              color="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <mesh renderOrder={2}>
              <sphereGeometry args={[6051.8, 32, 32]} />
              <meshBasicMaterial color="#DEB887" side={THREE.FrontSide} />
            </mesh>
          </group>
        </group>

        {/* Earth */}
        <group ref={orbitRefs.current.earth}>
          <group position={[149600000, 0, 0]} ref={groupRef}>
            <Text
              ref={labelRefs.current.earth}
              position={[0, 50000, 0]}
              fontSize={25000}
              color="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <group ref={earthRef}>
              {/* Combined Earth sphere */}
              <mesh renderOrder={2}>
                <sphereGeometry args={[6371, 64, 32]} />
                <shaderMaterial
                  fragmentShader={`
                    varying vec2 vUv;
                    void main() {
                      float lat = (vUv.y - 0.5) * 180.0;
                      float lon = (vUv.x - 0.5) * 360.0;
                      
                      vec4 oceanColor = vec4(0.0, 0.41, 0.58, 1.0);
                      vec4 landColor = vec4(0.2, 0.5, 0.2, 1.0);
                      vec4 iceColor = vec4(0.9, 0.9, 0.9, 1.0);
                      
                      vec4 finalColor = oceanColor;
                      
                      // North America
                      if ((lon >= -170.0 && lon <= -50.0 && lat >= 15.0 && lat <= 75.0) &&
                          (
                            // Alaska
                            (lon >= -170.0 && lon <= -140.0 && lat >= 55.0 && lat <= 72.0) ||
                            // Main continent
                            (lon >= -140.0 && lon <= -95.0 && lat >= 25.0 && lat <= 70.0) ||
                            // East coast
                            (lon >= -95.0 && lon <= -65.0 && lat >= 25.0 && lat <= 50.0) ||
                            // Florida peninsula
                            (lon >= -85.0 && lon <= -80.0 && lat >= 25.0 && lat <= 30.0)
                          )) {
                        finalColor = landColor;
                      }
                      
                      // South America
                      else if ((lon >= -85.0 && lon <= -35.0 && lat >= -60.0 && lat <= 15.0) &&
                              (
                                // Brazil bulge
                                (lon >= -65.0 && lon <= -35.0 && lat >= -15.0 && lat <= 5.0) ||
                                // Main continent
                                (lon >= -80.0 && lon <= -55.0 && lat >= -55.0 && lat <= 15.0) ||
                                // West coast
                                (lon >= -85.0 && lon <= -70.0 && lat >= -20.0 && lat <= 15.0)
                              )) {
                        finalColor = landColor;
                      }
                      
                      // Europe
                      else if ((lon >= -10.0 && lon <= 40.0 && lat >= 35.0 && lat <= 70.0) &&
                              (
                                // Scandinavia
                                (lon >= 5.0 && lon <= 30.0 && lat >= 55.0 && lat <= 70.0) ||
                                // Main continent
                                (lon >= -10.0 && lon <= 20.0 && lat >= 35.0 && lat <= 55.0) ||
                                // Eastern Europe
                                (lon >= 20.0 && lon <= 40.0 && lat >= 45.0 && lat <= 60.0)
                              )) {
                        finalColor = landColor;
                      }
                      
                      // Africa
                      else if ((lon >= -20.0 && lon <= 50.0 && lat >= -35.0 && lat <= 35.0) &&
                              (
                                // West Africa bulge
                                (lon >= -15.0 && lon <= 15.0 && lat >= 0.0 && lat <= 15.0) ||
                                // Main continent
                                (lon >= -15.0 && lon <= 40.0 && lat >= -30.0 && lat <= 30.0) ||
                                // Horn of Africa
                                (lon >= 40.0 && lon <= 50.0 && lat >= 5.0 && lat <= 15.0) ||
                                // Madagascar
                                (lon >= 43.0 && lon <= 50.0 && lat >= -25.0 && lat <= -12.0)
                              )) {
                        finalColor = landColor;
                      }
                      
                      // Asia
                      else if ((lon >= 40.0 && lon <= 150.0 && lat >= 0.0 && lat <= 75.0) &&
                              (
                                // Siberia
                                (lon >= 60.0 && lon <= 150.0 && lat >= 50.0 && lat <= 75.0) ||
                                // China
                                (lon >= 75.0 && lon <= 135.0 && lat >= 20.0 && lat <= 50.0) ||
                                // India
                                (lon >= 65.0 && lon <= 90.0 && lat >= 5.0 && lat <= 30.0) ||
                                // Southeast Asia
                                (lon >= 90.0 && lon <= 120.0 && lat >= 0.0 && lat <= 30.0) ||
                                // Japan
                                (lon >= 130.0 && lon <= 145.0 && lat >= 30.0 && lat <= 45.0) ||
                                // Middle East
                                (lon >= 40.0 && lon <= 65.0 && lat >= 15.0 && lat <= 45.0)
                              )) {
                        finalColor = landColor;
                      }
                      
                      // Australia
                      else if ((lon >= 110.0 && lon <= 155.0 && lat >= -40.0 && lat <= -10.0) &&
                              (
                                // Main continent
                                (lon >= 115.0 && lon <= 150.0 && lat >= -35.0 && lat <= -15.0) ||
                                // North coast
                                (lon >= 130.0 && lon <= 140.0 && lat >= -15.0 && lat <= -10.0) ||
                                // Tasmania
                                (lon >= 145.0 && lon <= 150.0 && lat >= -40.0 && lat <= -35.0)
                              )) {
                        finalColor = landColor;
                      }
                      
                      // Antarctica
                      else if (lat <= -60.0 &&
                              (
                                // Main continent
                                (lon >= -180.0 && lon <= 180.0 && lat <= -65.0) ||
                                // Antarctic Peninsula
                                (lon >= -65.0 && lon <= -55.0 && lat >= -65.0 && lat <= -60.0)
                              )) {
                        finalColor = iceColor;
                      }
                      
                      gl_FragColor = finalColor;
                    }
                  `}
                  vertexShader={`
                    varying vec2 vUv;
                    void main() {
                      vUv = uv;
                      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                    }
                  `}
                  depthWrite={true}
                  depthTest={true}
                />
              </mesh>

              {/* Keep existing atmosphere layers */}
              <mesh renderOrder={1}>
                <sphereGeometry args={[7500, 128, 640]} />
                <meshBasicMaterial 
                  color="#87CEEB"
                  transparent={true}
                  opacity={.1}
                  side={THREE.BackSide}
                  depthWrite={false}
                  depthTest={true}
                />
              </mesh>

              <mesh renderOrder={-1}>
                <sphereGeometry args={[6900, 128, 640]} />
                <meshBasicMaterial 
                  color="#87CEEB"
                  side={THREE.BackSide}
                  depthWrite={false}
                  depthTest={true}
                />
              </mesh>
            </group>

            {/* Moon */}
            <group ref={moonOrbitRef}>
              <group position={[384400, 0, 0]} ref={moonRef}>
                <Text
                  ref={labelRefs.current.moon}
                  position={[0, 30000, 0]}
                  fontSize={15000}
                  color="white"
                  anchorX="center"
                  anchorY="bottom"
                  renderOrder={10000}
                  depthWrite={false}
                >
                  {""}
                </Text>
                <mesh renderOrder={2}>
                  <sphereGeometry args={[1737, 32, 32]} />
                  <meshBasicMaterial color="#D3D3D3" side={THREE.FrontSide} />
                </mesh>
              </group>
            </group>
          </group>
        </group>

        {/* Mars */}
        <group ref={orbitRefs.current.mars}>
          <group position={[227900000, 0, 0]} ref={planetRefs.current.mars}>
            <Text
              ref={labelRefs.current.mars}
              position={[0, 50000, 0]}
              fontSize={25000}
              color="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <mesh renderOrder={2}>
              <sphereGeometry args={[3389.5, 32, 32]} />
              <meshBasicMaterial color="#CD5C5C" side={THREE.FrontSide} />
            </mesh>
            
            {/* Phobos */}
            <group ref={moonRefs.current.phobos.orbit}>
              <group position={[60000, 0, 0]} ref={moonRefs.current.phobos.moon}>
                <Text
                  ref={labelRefs.current.phobos}
                  position={[0, 30000, 0]}
                  fontSize={15000}
                  color="white"
                  anchorX="center"
                  anchorY="bottom"
                  renderOrder={10000}
                  depthWrite={false}
                >
                  {""}
                </Text>
                <mesh renderOrder={2}>
                  <sphereGeometry args={[11.2667, 32, 32]} />
                  <meshBasicMaterial color="#8B8989" side={THREE.FrontSide} />
                </mesh>
              </group>
            </group>

            {/* Deimos */}
            <group ref={moonRefs.current.deimos.orbit}>
              <group position={[80000, 0, 0]} ref={moonRefs.current.deimos.moon}>
                <Text
                  ref={labelRefs.current.deimos}
                  position={[0, 30000, 0]}
                  fontSize={15000}
                  color="white"
                  anchorX="center"
                  anchorY="bottom"
                  renderOrder={10000}
                  depthWrite={false}
                >
                  {""}
                </Text>
                <mesh renderOrder={2}>
                  <sphereGeometry args={[6.2, 32, 32]} />
                  <meshBasicMaterial color="#A9A9A9" side={THREE.FrontSide} />
                </mesh>
              </group>
            </group>
          </group>
        </group>

        {/* Jupiter */}
        <group ref={orbitRefs.current.jupiter}>
          <group position={[778500000, 0, 0]} ref={planetRefs.current.jupiter}>
            <Text
              ref={labelRefs.current.jupiter}
              position={[0, 50000, 0]}
              fontSize={25000}
              color="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <mesh renderOrder={2}>
              <sphereGeometry args={[69911, 32, 32]} />
              <meshBasicMaterial color="#DEB887" side={THREE.FrontSide} />
            </mesh>

            {/* Jupiter's moons */}
            <group ref={moonRefs.current.io.orbit}>
              <group position={[1200000, 0, 0]} ref={moonRefs.current.io.moon}>
                <Text
                  ref={labelRefs.current.io}
                  position={[0, 30000, 0]}
                  fontSize={15000}
                  color="white"
                  anchorX="center"
                  anchorY="bottom"
                  renderOrder={10000}
                  depthWrite={false}
                >
                  {""}
                </Text>
                <mesh renderOrder={2}>
                  <sphereGeometry args={[1821.6, 32, 32]} />
                  <meshBasicMaterial color="#FFD700" side={THREE.FrontSide} />
                </mesh>
              </group>
            </group>

            <group ref={moonRefs.current.europa.orbit}>
              <group position={[1500000, 0, 0]} ref={moonRefs.current.europa.moon}>
                <Text
                  ref={labelRefs.current.europa}
                  position={[0, 30000, 0]}
                  fontSize={15000}
                  color="white"
                  anchorX="center"
                  anchorY="bottom"
                  renderOrder={10000}
                  depthWrite={false}
                >
                  {""}
                </Text>
                <mesh renderOrder={2}>
                  <sphereGeometry args={[1560.8, 32, 32]} />
                  <meshBasicMaterial color="#F5F5DC" side={THREE.FrontSide} />
                </mesh>
              </group>
            </group>

            <group ref={moonRefs.current.ganymede.orbit}>
              <group position={[1800000, 0, 0]} ref={moonRefs.current.ganymede.moon}>
                <Text
                  ref={labelRefs.current.ganymede}
                  position={[0, 30000, 0]}
                  fontSize={15000}
                  color="white"
                  anchorX="center"
                  anchorY="bottom"
                  renderOrder={10000}
                  depthWrite={false}
                >
                  {""}
                </Text>
                <mesh renderOrder={2}>
                  <sphereGeometry args={[2634.1, 32, 32]} />
                  <meshBasicMaterial color="#CD853F" side={THREE.FrontSide} />
                </mesh>
              </group>
            </group>

            <group ref={moonRefs.current.callisto.orbit}>
              <group position={[2100000, 0, 0]} ref={moonRefs.current.callisto.moon}>
                <Text
                  ref={labelRefs.current.callisto}
                  position={[0, 30000, 0]}
                  fontSize={15000}
                  color="white"
                  anchorX="center"
                  anchorY="bottom"
                  renderOrder={10000}
                  depthWrite={false}
                >
                  {""}
                </Text>
                <mesh renderOrder={2}>
                  <sphereGeometry args={[2410.3, 32, 32]} />
                  <meshBasicMaterial color="#8B8378" side={THREE.FrontSide} />
                </mesh>
              </group>
            </group>
          </group>
        </group>

        {/* Saturn */}
        <group ref={orbitRefs.current.saturn}>
          <group position={[1434000000, 0, 0]} ref={planetRefs.current.saturn}>
            <Text
              ref={labelRefs.current.saturn}
              position={[0, 50000, 0]}
              fontSize={25000}
              color="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <mesh renderOrder={2}>
              <sphereGeometry args={[58232, 32, 32]} />
              <meshBasicMaterial color="#F4A460" side={THREE.FrontSide} />
            </mesh>
            <mesh rotation={[Math.PI / 6, 0, 0]}>
              <torusGeometry args={[1200000, 200000, 2, 32]} />
              <meshBasicMaterial color="#DEB887" transparent opacity={0.5} />
            </mesh>

            {/* Saturn's moons */}
            <group ref={moonRefs.current.titan.orbit}>
              <group position={[1200000, 0, 0]} ref={moonRefs.current.titan.moon}>
                <Text
                  ref={labelRefs.current.titan}
                  position={[0, 30000, 0]}
                  fontSize={15000}
                  color="white"
                  anchorX="center"
                  anchorY="bottom"
                  renderOrder={10000}
                  depthWrite={false}
                >
                  {""}
                </Text>
                <mesh renderOrder={2}>
                  <sphereGeometry args={[2574.73, 32, 32]} />
                  <meshBasicMaterial color="#DAA520" side={THREE.FrontSide} />
                </mesh>
              </group>
            </group>

            <group ref={moonRefs.current.enceladus.orbit}>
              <group position={[900000, 0, 0]} ref={moonRefs.current.enceladus.moon}>
                <Text
                  ref={labelRefs.current.enceladus}
                  position={[0, 30000, 0]}
                  fontSize={15000}
                  color="white"
                  anchorX="center"
                  anchorY="bottom"
                  renderOrder={10000}
                  depthWrite={false}
                >
                  {""}
                </Text>
                <mesh renderOrder={2}>
                  <sphereGeometry args={[252.1, 32, 32]} />
                  <meshBasicMaterial color="#F0FFFF" side={THREE.FrontSide} />
                </mesh>
              </group>
            </group>

            <group ref={moonRefs.current.mimas.orbit}>
              <group position={[750000, 0, 0]} ref={moonRefs.current.mimas.moon}>
                <Text
                  ref={labelRefs.current.mimas}
                  position={[0, 30000, 0]}
                  fontSize={15000}
                  color="white"
                  anchorX="center"
                  anchorY="bottom"
                  renderOrder={10000}
                  depthWrite={false}
                >
                  {""}
                </Text>
                <mesh renderOrder={2}>
                  <sphereGeometry args={[198.2, 32, 32]} />
                  <meshBasicMaterial color="#C0C0C0" side={THREE.FrontSide} />
                </mesh>
              </group>
            </group>
          </group>
        </group>

        {/* Uranus */}
        <group ref={orbitRefs.current.uranus}>
          <group position={[2871000000, 0, 0]} ref={planetRefs.current.uranus}>
            <Text
              ref={labelRefs.current.uranus}
              position={[0, 50000, 0]}
              fontSize={25000}
              color="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <mesh renderOrder={2}>
              <sphereGeometry args={[25362, 32, 32]} />
              <meshBasicMaterial color="#87CEEB" side={THREE.FrontSide} />
            </mesh>

            {/* Uranus' moons */}
            <group ref={moonRefs.current.miranda.orbit}>
              <group position={[500000, 0, 0]} ref={moonRefs.current.miranda.moon}>
                <Text
                  ref={labelRefs.current.miranda}
                  position={[0, 30000, 0]}
                  fontSize={15000}
                  color="white"
                  anchorX="center"
                  anchorY="bottom"
                  renderOrder={10000}
                  depthWrite={false}
                >
                  {""}
                </Text>
                <mesh renderOrder={2}>
                  <sphereGeometry args={[235.8, 32, 32]} />
                  <meshBasicMaterial color="#E6E6FA" side={THREE.FrontSide} />
                </mesh>
              </group>
            </group>

            <group ref={moonRefs.current.titania.orbit}>
              <group position={[700000, 0, 0]} ref={moonRefs.current.titania.moon}>
                <Text
                  ref={labelRefs.current.titania}
                  position={[0, 30000, 0]}
                  fontSize={15000}
                  color="white"
                  anchorX="center"
                  anchorY="bottom"
                  renderOrder={10000}
                  depthWrite={false}
                >
                  {""}
                </Text>
                <mesh renderOrder={2}>
                  <sphereGeometry args={[788.9, 32, 32]} />
                  <meshBasicMaterial color="#D3D3D3" side={THREE.FrontSide} />
                </mesh>
              </group>
            </group>

            <group ref={moonRefs.current.oberon.orbit}>
              <group position={[900000, 0, 0]} ref={moonRefs.current.oberon.moon}>
                <Text
                  ref={labelRefs.current.oberon}
                  position={[0, 30000, 0]}
                  fontSize={15000}
                  color="white"
                  anchorX="center"
                  anchorY="bottom"
                  renderOrder={10000}
                  depthWrite={false}
                >
                  {""}
                </Text>
                <mesh renderOrder={2}>
                  <sphereGeometry args={[761.4, 32, 32]} />
                  <meshBasicMaterial color="#A9A9A9" side={THREE.FrontSide} />
                </mesh>
              </group>
            </group>
          </group>
        </group>

        {/* Neptune */}
        <group ref={orbitRefs.current.neptune}>
          <group position={[4495000000, 0, 0]} ref={planetRefs.current.neptune}>
            <Text
              ref={labelRefs.current.neptune}
              position={[0, 50000, 0]}
              fontSize={25000}
              color="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <mesh renderOrder={2}>
              <sphereGeometry args={[24622, 32, 32]} />
              <meshBasicMaterial color="#4169E1" side={THREE.FrontSide} />
            </mesh>

            {/* Neptune's moon */}
            <group ref={moonRefs.current.triton.orbit}>
              <group position={[600000, 0, 0]} ref={moonRefs.current.triton.moon}>
                <Text
                  ref={labelRefs.current.triton}
                  position={[0, 30000, 0]}
                  fontSize={15000}
                  color="white"
                  anchorX="center"
                  anchorY="bottom"
                  renderOrder={10000}
                  depthWrite={false}
                >
                  {""}
                </Text>
                <mesh renderOrder={2}>
                  <sphereGeometry args={[1353.4, 32, 32]} />
                  <meshBasicMaterial color="#F5F5F5" side={THREE.FrontSide} />
                </mesh>
              </group>
            </group>
          </group>
        </group>
      </group>
    </>
  );
});

WorldSphere.propTypes = {
  guidanceSystem: PropTypes.bool,
  orbitLock: PropTypes.bool,
  viewLock: PropTypes.bool,
  gravity: PropTypes.bool,
  gravityAssist: PropTypes.bool,
  land: PropTypes.bool,
  selectedCelestialBody: PropTypes.string,
  speed: PropTypes.number,
  timelapse: PropTypes.number,
  onUpdatePositions: PropTypes.func,
};

export default WorldSphere;
