// src/components/Teachers/Build/Scene.jsx

import React, { useEffect, useCallback, Suspense, useMemo, useState, useRef } from 'react';
import { useThree, useLoader, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { EffectComposer, SSAO } from '@react-three/postprocessing';
import FirstPersonControls from './FirstPersonControls';
import PropTypes from 'prop-types';
import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { Stats } from '@react-three/drei';

// GroundComponent remains mostly unchanged but now accepts removeBlock as a prop
const GroundComponent = ({ position, rotation, onPointerDown, removeBlock }) => {
  const handleGroundClick = (event) => {
    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();
    }

    event.stopPropagation();

    const { button, point } = event;

    const x = Math.round(point.x);
    const z = Math.round(point.z);
    const y = 0; // Ground level

    if (button === 0) { // Left-click to add voxel
      onPointerDown(x, y, z, 1); // Pass size=1
    } else if (button === 2) { // Right-click to remove voxel
      // Remove block by passing an array
      removeBlock([x, y, z]);
    }
  };

  return (
    <mesh
      position={position}
      rotation={rotation}
      receiveShadow
      onPointerDown={handleGroundClick}
      onContextMenu={(e) => {
        if (e && typeof e.preventDefault === 'function') {
          e.preventDefault();
        }
      }} // Prevent context menu on right-click
    >
      <planeGeometry args={[21, 21]} />
      <meshStandardMaterial 
        color="#a8e6a8" 
        wireframe={false}
        opacity={0}          // Make the ground invisible
        transparent={true}   // Enable transparency
      />
    </mesh>
  );
};

GroundComponent.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
  rotation: PropTypes.arrayOf(PropTypes.number).isRequired,
  onPointerDown: PropTypes.func.isRequired,
  removeBlock: PropTypes.func.isRequired, // Added prop type
};

const GrassField = React.memo(({ startPos, gridSize, renderOrder, depthWrite }) => {
  const instancedMeshRef = useRef();
  const grassCount = gridSize * gridSize * 4; // 24 grass instances per voxel
  const dummy = useMemo(() => new THREE.Object3D(), []);
  const { camera } = useThree();

  // Update grass geometry to have pointed tips
  const grassGeometry = useMemo(() => {
    const height = .8;      // Total height of grass blade
    const strandWidth = 0.15; // Width of each strand
    const segments = 4;      // Number of segments
    const geo = new THREE.BufferGeometry();
    
    const positions = [];
    const uvs = [];
    
    const rowOffsets = [
      -0.3,  // Back row
      0,     // Middle row
      0.3    // Front row
    ];
    
    const strands = [
      { angle: -0.8, zOffset: -0.2 },
      { angle: -0.6, zOffset: -0.1 },
      { angle: -0.3, zOffset: 0.1 },
      { angle: -0.1, zOffset: 0.2 },
      { angle: 0.1, zOffset: 0.15 },
      { angle: 0.3, zOffset: 0.0 },
      { angle: 0.6, zOffset: -0.15 },
      { angle: 0.8, zOffset: -0.25 }
    ];
    
    // Create strands for each row
    rowOffsets.forEach(rowOffset => {
      strands.forEach(({ angle, zOffset }) => {
        const baseX = angle * 0.01;
        const topX = angle * 1.2;
        const controlX = angle * 0.6;
        
        // Create segments for the blade
        for (let i = 0; i < segments - 1; i++) {
          const t = i / (segments - 1);
          const nextT = (i + 1) / (segments - 1);
          
          const t2 = t * t;
          const t1 = 1 - t;
          const t12 = t1 * t1;
          
          const nextT2 = nextT * nextT;
          const nextT1 = 1 - nextT;
          const nextT12 = nextT1 * nextT1;
          
          const x1 = baseX * t12 + controlX * 2 * t1 * t + topX * t2;
          const y1 = t * height;
          
          const x2 = baseX * nextT12 + controlX * 2 * nextT1 * nextT + topX * nextT2;
          const y2 = nextT * height;
          
          const curveIntensity = Math.pow(t, 1.5) * 0.3;
          const nextCurveIntensity = Math.pow(nextT, 1.5) * 0.3;
          
          const z1 = zOffset * t + rowOffset;
          const z2 = zOffset * nextT + rowOffset;

          // For the last segment, create a pointed tip
          if (i === segments - 2) {
            // Create triangles that converge to a point
            positions.push(
              x1 - strandWidth/2 + curveIntensity, y1, z1,       // bottom left
              x1 + strandWidth/2 + curveIntensity, y1, z1,       // bottom right
              x2, y2, z2                                         // top point
            );
            
            // UVs for the pointed tip
            uvs.push(
              0, t,
              1, t,
              0.5, 1
            );
          } else {
            // Regular segments
            positions.push(
              x1 - strandWidth/2 + curveIntensity, y1, z1,
              x1 + strandWidth/2 + curveIntensity, y1, z1,
              x2 - strandWidth/2 + nextCurveIntensity, y2, z2,
              
              x1 + strandWidth/2 + curveIntensity, y1, z1,
              x2 + strandWidth/2 + nextCurveIntensity, y2, z2,
              x2 - strandWidth/2 + nextCurveIntensity, y2, z2
            );
            
            // UVs for regular segments
            uvs.push(
              0, t,
              1, t,
              0, nextT,
              1, t,
              1, nextT,
              0, nextT
            );
          }
        }
      });
    });
    
    geo.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geo.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
    
    // Add random variation to z-position
    const positionAttribute = geo.attributes.position;
    for (let i = 0; i < positionAttribute.count; i++) {
      const y = positionAttribute.getY(i);
      if (y > 0) {
        const heightFactor = y / height;
        positionAttribute.setZ(i, positionAttribute.getZ(i) + (Math.random() - 0.5) * 0.1 * heightFactor);
      }
    }
    
    geo.computeVertexNormals();
    geo.translate(0, -.5, 0);
    return geo;
  }, []);

  // Optimize shader by reducing precision and calculations
  const grassMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        windStrength: { value: 1.0 },
        customCameraPosition: { value: new THREE.Vector3() },
        darkColor: { value: new THREE.Color(0x0a2200) },  // Keep dark base color
        lightColor: { value: new THREE.Color(0x8fa977) }  // Changed to a duller, lighter green
      },
      vertexShader: `
        // Add precision hints at the top
        precision mediump float;
        precision mediump int;
        
        uniform float time;
        uniform float windStrength;
        uniform vec3 customCameraPosition;
        
        attribute vec3 instanceOffset;
        attribute float windOffset;
        attribute float heightScale;
        attribute float colorOffset;
        
        varying vec2 vUv;
        varying float vVisibility;
        varying float vColorOffset;
        varying float vShouldDiscard;
        
        // Pre-calculate constants
        const float PI = 3.14159;
        const float DISTANCE_NEAR = 25.0;
        const float DISTANCE_FAR = 85.0;
        const float DISTANCE_RANGE = DISTANCE_FAR - DISTANCE_NEAR;
        
        void main() {
          vUv = uv;
          vColorOffset = colorOffset;
          vShouldDiscard = 0.0;
          
          vec3 pos = position;
          pos.y *= heightScale;
          
          vec3 worldPos = instanceOffset + pos;
          
          // Optimize vector calculations
          vec3 toCamera = customCameraPosition - worldPos;
          float distanceToCamera = length(toCamera);
          toCamera *= 1.0/distanceToCamera; // Normalize after length calculation
          
          // Simplified dot product with up vector (0,1,0)
          float dotProduct = toCamera.y;
          
          // Optimized distance culling
          if(distanceToCamera > DISTANCE_NEAR) {
            float cutoffThreshold = (distanceToCamera - DISTANCE_NEAR) / DISTANCE_RANGE;
            if(fract(windOffset * 43758.5453) < cutoffThreshold * 0.85) {
              vShouldDiscard = 1.0;
            }
          }
          
          // Simplified back-face culling
          vShouldDiscard += step(dotProduct, -0.2);
          
          vVisibility = 1.0 - (distanceToCamera - DISTANCE_NEAR) / DISTANCE_RANGE;
          
          // Distance limits
          vShouldDiscard += step(DISTANCE_FAR, distanceToCamera);
          vShouldDiscard += step(distanceToCamera, 0.5);
          
          // Modified wind calculation - apply less movement at base
          float heightRatio = pos.y / (0.9 * heightScale);
          float windPhase = time * 2.0 + windOffset;
          float bendPower = heightRatio * heightRatio;
          
          // Only apply wind if not at the base (y > 0)
          if (pos.y > -0.45) {  // Adjusted threshold to match the grass base position
            // Gradually increase movement from base to tip
            float baseToTip = smoothstep(-0.45, 0.3, pos.y);
            
            // Combined wind movements with base anchoring
            float windX = windStrength * sin(windPhase) * 2.0 * bendPower * 0.8 * baseToTip;
            windX += sin(heightRatio * 3.0 + time * 2.0) * 0.15 * heightRatio * baseToTip;
            
            // Simplified strand movement with base anchoring
            float strandPhase = floor(position.x * 5.0) * 2.0;
            vec2 strandOffset = vec2(
              sin(time * 2.0 + strandPhase),
              cos(time * 1.5 + strandPhase)
            ) * 0.05 * heightRatio * baseToTip;
            
            pos.xz += strandOffset;
            pos.x += windX;
          }

          // Optimized billboarding - Modified to handle camera height smoothly
          float randomAngle = windOffset * 6.28318;
          float c = cos(randomAngle);
          float s = sin(randomAngle);
          
          // Create camera-facing direction that adapts based on camera height
          vec3 cameraDir = normalize(customCameraPosition - instanceOffset);
          vec3 worldUp = vec3(0.0, 1.0, 0.0);
          
          // Calculate camera height factor (0 when camera is at grass level, 1 when directly above)
          float heightFactor = smoothstep(-0.2, 0.8, dot(cameraDir, worldUp));
          
          // Blend between full billboarding and vertical orientation based on camera height
          vec3 right = normalize(cross(cameraDir, worldUp));
          vec3 forward = normalize(cross(worldUp, right));
          
          // Interpolate between regular billboarding and vertical orientation
          vec3 blendedRight = mix(right, vec3(c, 0.0, s), heightFactor);
          vec3 blendedForward = mix(forward, vec3(-s, 0.0, c), heightFactor);
          
          // Apply the blended transformation while maintaining y-axis scaling
          vec3 finalPos = instanceOffset + 
            blendedRight * pos.x + 
            worldUp * pos.y + 
            blendedForward * pos.z;
          
          vec4 finalPosition = modelViewMatrix * vec4(finalPos, 1.0);
          
          gl_Position = projectionMatrix * finalPosition;
        }
      `,
      fragmentShader: `
        precision mediump float;
        
        uniform vec3 darkColor;
        uniform vec3 lightColor;
        varying vec2 vUv;
        varying float vVisibility;
        varying float vColorOffset;
        varying float vShouldDiscard;
        
        vec3 hsv2rgb(vec3 c) {
          vec3 rgb = clamp(abs(mod(c.x * 6.0 + vec3(0.0,4.0,2.0), 6.0) - 3.0) - 1.0, 0.0, 1.0);
          return c.z * mix(vec3(1.0), rgb, c.y);
        }
        
        void main() {
          if(vShouldDiscard > 0.5) discard;
          if(vVisibility <= 0.0) discard;
          
          vec3 baseColor = mix(darkColor, lightColor, vUv.y * 0.7); // Increased mixing factor
          vec3 variedColor = hsv2rgb(vec3(
            0.25 + vColorOffset * 0.08,  // Reduced hue variation
            0.4 + vColorOffset * 0.15,   // Reduced saturation for duller look
            0.25 + vUv.y * 0.15         // Increased brightness but kept muted
          ));
          
          gl_FragColor = vec4(mix(baseColor, variedColor, 0.25), 1.0); // Reduced color variation mixing
          
          if(gl_FragColor.a < 0.1) discard;
        }
      `,
      side: THREE.DoubleSide,
      transparent: false,
      depthWrite: true,
      depthTest: true,
      cullFace: THREE.BackSide,
      blending: THREE.NormalBlending,
      dithering: false,
    });
  }, []);

  // Optimize instance updates with TypedArrays
  useEffect(() => {
    if (!instancedMeshRef.current) return;

    const [startX, startY, startZ] = startPos;
    const instanceCount = grassCount;
    
    // Pre-allocate arrays
    const instanceOffsets = new Float32Array(instanceCount * 3);
    const windOffsets = new Float32Array(instanceCount);
    const heightScales = new Float32Array(instanceCount);    
    const colorOffsets = new Float32Array(instanceCount);
    
    // Use a single random number generator
    const random = () => Math.random();
    
    // Batch process instances - increased spread radius
    for (let i = 0; i < instanceCount; i++) {
      const idx = i * 3;
      // Increased spread radius and added circular distribution
      const angle = random() * Math.PI * 2;
      const radius = 0.35 * Math.sqrt(random()); // Sqrt for more uniform circular distribution
      
      instanceOffsets[idx] = startX + Math.cos(angle) * radius;
      instanceOffsets[idx + 1] = startY;
      instanceOffsets[idx + 2] = startZ + Math.sin(angle) * radius;
      
      windOffsets[i] = random() * Math.PI * 2;
      heightScales[i] = 0.8 + random() * 1.2;  // More height variation    
      colorOffsets[i] = random();                 
    }

    // Batch attribute updates
    const geometry = instancedMeshRef.current.geometry;
    geometry.setAttribute('instanceOffset', new THREE.InstancedBufferAttribute(instanceOffsets, 3));
    geometry.setAttribute('windOffset', new THREE.InstancedBufferAttribute(windOffsets, 1));
    geometry.setAttribute('heightScale', new THREE.InstancedBufferAttribute(heightScales, 1));
    geometry.setAttribute('colorOffset', new THREE.InstancedBufferAttribute(colorOffsets, 1));
  }, [startPos, gridSize, grassCount]);

  // Update uniforms every frame
  useFrame((state) => {
    if (!instancedMeshRef.current?.material?.uniforms) return;
    
    const uniforms = instancedMeshRef.current.material.uniforms;
    uniforms.time.value = state.clock.getElapsedTime();
    uniforms.windStrength.value = 1.0;
    uniforms.customCameraPosition.value.copy(camera.position);
  });

  return (
    <instancedMesh
      ref={instancedMeshRef}
      args={[grassGeometry, grassMaterial, grassCount]}
      frustumCulled={false}
      renderOrder={renderOrder}
    />
  );
});

GrassField.propTypes = {
  startPos: PropTypes.arrayOf(PropTypes.number).isRequired,
  gridSize: PropTypes.number.isRequired,
  renderOrder: PropTypes.number.isRequired,
  depthWrite: PropTypes.bool.isRequired,
};

const Scene = ({
  blocks,
  addBlock,
  removeBlock,
  currentColor,
  placementSize,
  onFaceCount,
  touchMode,
  isInventoryOpen,
  setIsInventoryOpen,
}) => {
  const { camera, scene, gl } = useThree();

  // State to handle cube texture loading errors
  const [cubeTexture, setCubeTexture] = useState(null);

  // Directions for each face
  const FACE_DIRECTIONS = useMemo(() => [
    { name: 'front', normal: [0, 0, 1], rotation: [0, 0, 0] },
    { name: 'back', normal: [0, 0, -1], rotation: [0, Math.PI, 0] },
    { name: 'top', normal: [0, 1, 0], rotation: [-Math.PI / 2, 0, 0] },
    { name: 'bottom', normal: [0, -1, 0], rotation: [Math.PI / 2, 0, 0] },
    { name: 'right', normal: [-1, 0, 0], rotation: [0, -Math.PI / 2, 0] },
    { name: 'left', normal: [1, 0, 0], rotation: [0, Math.PI / 2, 0] },
  ], []);

  // Optimize the occupiedPositions Set creation with useMemo
  const occupiedPositions = useMemo(() => {
    const set = new Set();
    blocks.forEach(block => {
      const [x, y, z] = block.position;
      set.add(`${x},${y},${z}`);
    });
    return set;
  }, [blocks]); // Only recreate when blocks array changes

  // Optimize block lookup with a Map
  const blockMap = useMemo(() => {
    const map = new Map();
    blocks.forEach(block => {
      const key = `${block.position[0]},${block.position[1]},${block.position[2]}`;
      map.set(key, block);
    });
    return map;
  }, [blocks]);

  // Optimize isOpaqueNeighbor function
  const isOpaqueNeighbor = useCallback((x, y, z, isCurrentTransparent) => {
    const key = `${x},${y},${z}`;
    if (!occupiedPositions.has(key)) return false;
    
    const block = blockMap.get(key);
    if (!block) return false;

    const isNeighborTransparent = block.color === 'rgba(33, 150, 143, 0.5)';
    
    if (isCurrentTransparent && isNeighborTransparent) return true;
    return !isCurrentTransparent && !isNeighborTransparent;
  }, [occupiedPositions, blockMap]); // Dependencies updated

  // Create a ref to store grass instances
  const grassInstancesRef = useRef(new Map());

  // Modify the voxelFaces useMemo to handle grass instances with top face culling
  const voxelFaces = useMemo(() => {
    const temp = [];
    const newGrassInstances = new Map();
    
    blocks.forEach(block => {
      const [x, y, z] = block.position;
      const isCurrentTransparent = block.color === 'rgba(33, 150, 143, 0.5)';
      
      // Handle grass instances for green blocks
      if (block.color === '#1a3601') {
        // Check if there's a block above this one
        const hasBlockAbove = isOpaqueNeighbor(x, y + 1, z, isCurrentTransparent);
        
        // Only create grass instance if there's no block above
        if (!hasBlockAbove) {
          const key = `${x},${y},${z}`;
          if (!grassInstancesRef.current.has(key)) {
            newGrassInstances.set(key, {
              position: [x, y + 0.9, z],
              id: block.id
            });
          } else {
            newGrassInstances.set(key, grassInstancesRef.current.get(key));
          }
        }
      }

      FACE_DIRECTIONS.forEach(face => {
        const [dx, dy, dz] = face.normal;
        const neighborX = x + dx;
        const neighborY = y + dy;
        const neighborZ = z + dz;

        // Pass isCurrentTransparent to isOpaqueNeighbor
        if (!isOpaqueNeighbor(neighborX, neighborY, neighborZ, isCurrentTransparent)) {
          const position = [
            x + dx * 0.5,
            y + dy * 0.5,
            z + dz * 0.5,
          ];
          const rotation = face.rotation;
          
          let color;
          if (isCurrentTransparent) {
            color = new THREE.Color(0x87CEEB);
          } else {
            color = new THREE.Color(block.color);
          }

          temp.push({ 
            position, 
            rotation, 
            color,
            isTransparent: isCurrentTransparent,
            blockPosition: block.position, 
            faceNormal: face.normal 
          });
        }
      });
    });

    // Update grass instances ref
    grassInstancesRef.current = newGrassInstances;
    
    return temp;
  }, [blocks, FACE_DIRECTIONS, isOpaqueNeighbor]);

  // Reference to store face data for event handling
  const opaqueFaceDataRef = useRef([]);
  const transparentFaceDataRef = useRef([]);

  // Build merged geometry
  const mergedGeometry = useMemo(() => {
    const opaqueGeos = [];
    const transparentGeos = [];
    opaqueFaceDataRef.current = [];
    transparentFaceDataRef.current = [];

    voxelFaces.forEach(face => {
      const { position, rotation, color, isTransparent } = face;
      const plane = new THREE.PlaneGeometry(1, 1);
      const quaternion = new THREE.Quaternion().setFromEuler(new THREE.Euler(...rotation));
      plane.applyQuaternion(quaternion);
      plane.translate(...position);

      const colors = [];
      for (let i = 0; i < plane.attributes.position.count; i++) {
        colors.push(color.r, color.g, color.b);
      }
      plane.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

      const faceData = {
        blockPosition: face.blockPosition,
        faceNormal: face.faceNormal,
        isTransparent: isTransparent
      };

      if (isTransparent) {
        transparentFaceDataRef.current.push(faceData);
        transparentGeos.push(plane);
      } else {
        opaqueFaceDataRef.current.push(faceData);
        opaqueGeos.push(plane);
      }
    });

    return {
      opaqueGeometry: opaqueGeos.length > 0 ? mergeGeometries(opaqueGeos, true) : null,
      transparentGeometry: transparentGeos.length > 0 ? mergeGeometries(transparentGeos, true) : null
    };
  }, [voxelFaces]);

  // Create a persistent raycaster reference
  const raycaster = useRef(new THREE.Raycaster());

  // Add refs to track the meshes
  const opaqueMeshRef = useRef();
  const transparentMeshRef = useRef();

  const handleAddBlock = useCallback((x, y, z, size) => {
    addBlock(x, y, z, size);
  }, [addBlock]);

  // Modify handleVoxelInteraction to handle the unified entity
  const handleVoxelInteraction = useCallback((event) => {
    const canvas = gl.domElement;
    if (event.target !== canvas) return;
    
    event.preventDefault();
    
    const isTouchEvent = event.type.startsWith('touch');
    const actionType = isTouchEvent ? 
      (touchMode === 'delete' ? 'remove' : 'add') : 
      (event.button === 0 ? 'add' : 'remove');

    // Memoize raycaster calculations
    const ray = raycaster.current || new THREE.Raycaster();
    raycaster.current = ray;
    ray.setFromCamera({ x: 0, y: 0 }, camera);

    // Get intersections only from relevant meshes
    const intersects = [];
    if (opaqueMeshRef.current) {
      intersects.push(...ray.intersectObject(opaqueMeshRef.current));
    }
    if (transparentMeshRef.current) {
      intersects.push(...ray.intersectObject(transparentMeshRef.current));
    }

    if (intersects.length === 0) return;

    // Sort intersections by distance
    intersects.sort((a, b) => a.distance - b.distance);

    // Find the first valid intersection
    const validIntersect = intersects.find(intersect => {
      const isFromOpaqueGeometry = opaqueMeshRef.current?.geometry === intersect.object.geometry;
      const faceIndex = Math.floor(intersect.faceIndex / 2);
      const faceData = isFromOpaqueGeometry ? 
        opaqueFaceDataRef.current[faceIndex] : 
        transparentFaceDataRef.current[faceIndex];
      return faceData != null;
    });

    if (!validIntersect) return;

    const isFromOpaqueGeometry = opaqueMeshRef.current?.geometry === validIntersect.object.geometry;
    const faceIndex = Math.floor(validIntersect.faceIndex / 2);
    const faceData = isFromOpaqueGeometry ? 
      opaqueFaceDataRef.current[faceIndex] : 
      transparentFaceDataRef.current[faceIndex];

    const { blockPosition, faceNormal } = faceData;
    const [bx, by, bz] = blockPosition;
    const [fx, fy, fz] = faceNormal;

    if (actionType === 'add') {
      const newPos = [
        bx + fx * placementSize,
        by + fy * placementSize,
        bz + fz * placementSize,
      ];
      handleAddBlock(newPos[0], newPos[1], newPos[2], placementSize);
    } else if (actionType === 'remove') {
      const key = `${bx},${by},${bz}`;
      const block = blockMap.get(key);

      if (block) {
        removeBlock([bx, by, bz]);
      }
    }
  }, [camera, gl, addBlock, removeBlock, placementSize, touchMode, handleAddBlock, blockMap]);

  useEffect(() => {
    // Add event listeners for both mouse and touch events
    window.addEventListener('mousedown', handleVoxelInteraction);
    window.addEventListener('touchstart', handleVoxelInteraction, { passive: false });
    
    return () => {
      window.removeEventListener('mousedown', handleVoxelInteraction);
      window.removeEventListener('touchstart', handleVoxelInteraction);
    };
  }, [handleVoxelInteraction]);

  // Prevent context menu from appearing on right-click
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    window.addEventListener('contextmenu', handleContextMenu);

    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  // Add this ref near the top of the component
  const hasInitialized = useRef(false);

  // Modify the initialization effect
  useEffect(() => {
    if (hasInitialized.current) return;
    hasInitialized.current = true;

    const gridSize = 40;
    const startX = -Math.floor(gridSize / 2);
    const startZ = -Math.floor(gridSize / 2);
    const y = 0; // Height level for grey base
    const brownY = 1; // Height level for brown layer
    const greenY = 2; // Height level for green layer

    const greyColor = '#808080';
    const brownColor = '#8B4513';
    const greenColor = '#1a3601';

    // Create array to hold blocks
    const newBlocks = [];

    // Grey base layer
    for (let x = startX; x < startX + gridSize; x++) {
      for (let z = startZ; z < startZ + gridSize; z++) {
        newBlocks.push({ x, y, z, color: greyColor });
      }
    }

    // Brown top layer
    for (let x = startX; x < startX + gridSize; x++) {
      for (let z = startZ; z < startZ + gridSize; z++) {
        newBlocks.push({ x, y: brownY, z, color: brownColor });
      }
    }

    // Green top layer (grass will be rendered automatically for green blocks)
    for (let x = startX; x < startX + gridSize; x++) {
      for (let z = startZ; z < startZ + gridSize; z++) {
        newBlocks.push({ x, y: greenY, z, color: greenColor });
      }
    }

    // Batch add blocks
    addBlockBatch(newBlocks);

  }, [addBlock]);

  // Helper function to batch add blocks
  const addBlockBatch = useCallback((blocksToAdd) => {
    blocksToAdd.forEach(block => {
      addBlock(block.x, block.y, block.z, 1, block.color, block.isDeletable);
    });
  }, [addBlock]);

  return (
    <>
      <Stats 
        className="performance-stats"
        showPanel={0}  // 0: FPS, 1: MS, 2: MB
        position="bottom-right" // default is top-left
      />

      {/* Post-processing Effects */}
      <EffectComposer>
        <SSAO
          samples={301}
          radius={20}
          intensity={9000}
          luminanceInfluence={25}
          color="black"
        />
        {/* You can add more post-processing passes here if needed */}
      </EffectComposer>

      {/* Lighting */}
      <ambientLight intensity={0.3} /> {/* Increased ambient light */}
      <directionalLight
        position={[10, 20, 10]}
        intensity={0.5} // Increased intensity
        castShadow
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-bias={-0.00001}
        shadow-normalBias={0.001}
        shadow-camera-left={-50}
        shadow-camera-right={50}
        shadow-camera-top={50}
        shadow-camera-bottom={-50}
        shadow-camera-near={0.5}
        shadow-camera-far={500}
      />
      {/* Additional Point Light for better illumination */}
      <pointLight position={[-10, 10, -10]} intensity={0.5} />

      {/* Controls */}
      <FirstPersonControls 
        isInventoryOpen={isInventoryOpen} 
        setIsInventoryOpen={setIsInventoryOpen} 
      />

      {/* Editable Ground Plane with Pointer Events */}
      <GroundComponent
        position={[0, 0, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        onPointerDown={addBlock}
        removeBlock={removeBlock} // Passed as a prop
      />

      {/* Grid Helper */}
      {/* Removed the gridHelper component */}

      {/* Merged Mesh for Voxel Faces */}
      {mergedGeometry.opaqueGeometry && (
        <mesh
          ref={opaqueMeshRef}
          geometry={mergedGeometry.opaqueGeometry}
          castShadow
          receiveShadow
        >
          <meshStandardMaterial
            vertexColors={true}
            side={THREE.FrontSide}
            metalness={0}
            roughness={1}
          />
        </mesh>
      )}
      {mergedGeometry.transparentGeometry && (
        <mesh
          ref={transparentMeshRef}
          geometry={mergedGeometry.transparentGeometry}
          castShadow
          receiveShadow
          renderOrder={1}
        >
          <meshStandardMaterial
            vertexColors={true}
            side={THREE.FrontSide}
            metalness={0}
            roughness={0.5}
            transparent={true}
            opacity={0.25}
            depthWrite={false}
            blending={THREE.NormalBlending}
          />
        </mesh>
      )}

      {/* Render grass instances from ref */}
      {Array.from(grassInstancesRef.current.values()).map(({ position, id }) => (
        <GrassField 
          key={`grass-${id}`}
          startPos={position}
          gridSize={1}
          renderOrder={3}
          depthWrite={false}
        />
      ))}
    </>
  );
};

Scene.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      position: PropTypes.arrayOf(PropTypes.number).isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  addBlock: PropTypes.func.isRequired,
  removeBlock: PropTypes.func.isRequired,
  currentColor: PropTypes.string.isRequired,
  placementSize: PropTypes.number.isRequired,
  onFaceCount: PropTypes.func,
  touchMode: PropTypes.string,
  isInventoryOpen: PropTypes.bool,
  setIsInventoryOpen: PropTypes.func,
};

export default Scene;
