import React, { useState, useEffect } from 'react';
import { rtdb } from '../../../firebase'; // Ensure rtdb is correctly exported
import { ref, onValue, off, update, get } from 'firebase/database'; // Added 'update' for XP increment
import { useAuth } from '../../../contexts/AuthContext';
import PropTypes from 'prop-types';
import { FaTimes } from '../../assets/icons'; // Ensure icons are correctly imported
import { useDataController, MODULE_STATES } from '../../../contexts/datacontroller';

// Import banner image similar to Whiteboard
import PuzzleBanner from '../../../components/banners/animatedsvg.svg'; // Adjust the path if necessary

const TeacherWorkbook = ({ onClose, classID, filters: importedFilters }) => {
  const { currentUser } = useAuth();
  const [bees, setBees] = useState([]);
  const [loading, setLoading] = useState(true); // Initialize as true
  const [error, setError] = useState(null);
  const [selectedBee, setSelectedBee] = useState(null); // For modal view

  // State for movies and filters
  const [movies, setMovies] = useState([]);
  const [filters, setFilters] = useState({
    rating: '',
    genres: [],
    yearFrom: '',
    yearTo: '',
    classifications: [],
    languages: []
  });
  const [moviesLoading, setMoviesLoading] = useState(false);
  const [moviesError, setMoviesError] = useState(null);

  // Add new state for pagination
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // Add new state for active tab
  const [activeTab, setActiveTab] = useState('browse');

  // Add new state for categorized movies
  const [categorizedMovies, setCategorizedMovies] = useState({
    wouldWatch: [],
    wouldntWatch: []
  });

  // Add state for selected movie
  const [selectedMovie, setSelectedMovie] = useState(null);

  // Add state for new member name input
  const [newMemberName, setNewMemberName] = useState('');

  // Add new state for timer
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [timerActive, setTimerActive] = useState(false);

  // Add timer utility function
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Add timer effect
  useEffect(() => {
    let interval;
    if (timerActive && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining(prev => prev - 1);
      }, 1000);
    } else if (timeRemaining === 0) {
      setTimerActive(false);
      // Optional: Add any logic for when timer ends
    }
    return () => clearInterval(interval);
  }, [timerActive, timeRemaining]);

  // Fetch bees' names from RTDB where logged is "yes"
  useEffect(() => {
    if (!classID) {
      console.error('Missing classID.');
      setError('Invalid class ID.');
      setLoading(false);
      return;
    }

    const beesRef = ref(rtdb, `activeclasses/${classID}/beeNames`);
    console.log('Listening to RTDB path:', `activeclasses/${classID}/beeNames`); // Debug log

    const handleData = (snapshot) => {
      const data = snapshot.val();
      console.log('Fetched data from RTDB:', data); // Debug log

      if (data) {
        console.log(`Data retrieved for classID ${classID}:`, data);
        const beesArray = Object.keys(data)
          .filter((name) => {
            const isLogged = data[name].logged === 'yes'; // Ensure case matches
            if (!isLogged) {
              console.log(`Bee "${name}" is not logged.`);
            }
            return isLogged;
          })
          .map((name) => ({
            name: name.trim(),
            totalxp: data[name].totalxp || 0,
            classId: classID,
          }));
        console.log('Parsed bees array:', beesArray); // Debug log
        setBees(beesArray);
      } else {
        console.warn(`No data found at path activeclasses/${classID}/beeNames.`);
        setBees([]);
      }
      setLoading(false);
    };

    onValue(beesRef, handleData, (err) => {
      console.error('Error fetching bees:', err);
      setError('Failed to load bees. Please try again.');
      setLoading(false);
    });

    console.log(`Listener attached to activeclasses/${classID}/beeNames`);

    // Cleanup listener on unmount
    return () => {
      off(beesRef, 'value', handleData);
      console.log(`Detached listener from activeclasses/${classID}/beeNames`);
    };
  }, [classID]);

  // Function to handle XP increment
  const handleAddXp = (beeName) => {
    const beeIndex = bees.findIndex((bee) => bee.name === beeName);
    if (beeIndex !== -1) {
      const updatedBees = [...bees];
      updatedBees[beeIndex].totalxp += 1;
      setBees(updatedBees);

      // Update in RTDB
      const beeRef = ref(rtdb, `activeclasses/${classID}/beeNames/${beeName}/totalxp`);
      update(beeRef, updatedBees[beeIndex].totalxp)
        .then(() => {
          console.log(`XP updated for ${beeName}`);
        })
        .catch((err) => {
          console.error('Error updating XP:', err);
        });
    }
  };

  // JSX for a single bee's card
  const BeeCard = ({ bee }) => {
    console.log('Rendering BeeCard for:', bee.name); // Debug log

    return (
      <div className="flex flex-col items-center">
        {/* A4 Page Shape */}
        <div
          className="relative transition-transform bg-white rounded-lg shadow-md cursor-pointer hover:scale-105"
          style={{
            width: '150px', // Adjusted width for screen
            height: '212px', // Height maintaining A4 aspect ratio (150 * 297 / 210 ≈ 212)
            maxWidth: '150px',
            maxHeight: '212px',
          }}
          onClick={() => setSelectedBee(bee)}
        >
          {/* Placeholder for typing content */}
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-400">Typing Area</p>
          </div>

          {/* XP Button */}
          <button
            className="absolute p-1 text-lg transition-opacity bg-blue-500 rounded-full shadow opacity-0 bottom-2 right-2 hover:opacity-100"
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering the card click
              handleAddXp(bee.name);
            }}
            aria-label="Add XP"
            title="Add XP"
          >
            ❤️
          </button>
        </div>

        {/* Bee Name */}
        <div className="mt-2 text-sm font-medium text-center text-gray-700">
          {bee.name}
        </div>
      </div>
    );
  };

  BeeCard.propTypes = {
    bee: PropTypes.shape({
      name: PropTypes.string.isRequired,
      totalxp: PropTypes.number,
      classId: PropTypes.string.isRequired,
    }).isRequired,
  };

  // List of bee cards with adjusted grid layout
  const beeList = (
    <div className="grid justify-center grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
      {bees.map((bee) => (
        <BeeCard key={`${bee.classId}-${bee.name}`} bee={bee} />
      ))}
    </div>
  );

  // Modal view for selected bee
  const BeeModal = ({ bee, onClose }) => {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-60">
        <div
          className="relative bg-white rounded-lg shadow-xl"
          style={{
            width: '90%',
            height: '80%',
            maxWidth: '800px',
            maxHeight: '1100px',
            overflow: 'hidden', // Remove scrollbars
          }}
          onClick={onClose}
        >
          <button
            onClick={onClose}
            className="absolute text-2xl text-gray-500 top-4 right-4 hover:text-gray-700"
            aria-label="Close Modal"
            onMouseDown={(e) => e.stopPropagation()} // Prevent closing when clicking the button
          >
            <FaTimes />
          </button>
          <div className="flex flex-col h-full p-12">
            {/* Enlarge the BeeCard inside the modal */}
            <div className="flex items-center justify-center flex-grow">
              <div
                className="relative bg-white rounded-lg shadow-md cursor-default"
                style={{
                  width: '100%', // Make it responsive within the modal
                  height: 'auto',
                  paddingTop: '141.42%', // 210/297 ≈ 0.707, so padding-top: 70.7% to maintain aspect ratio
                  maxWidth: '800px',
                }}
              >
                <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col justify-between h-full p-8">
                  <h2 className="mb-4 text-2xl font-bold text-center">{bee.name}</h2>
                  {/* Additional detailed content can be added here */}
                  <div className="mt-4 text-center">
                    {/* Placeholder for detailed typing content */}
                    <p className="text-gray-400">Detailed Typing Area (To be implemented)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  BeeModal.propTypes = {
    bee: PropTypes.shape({
      name: PropTypes.string.isRequired,
      totalxp: PropTypes.number,
      classId: PropTypes.string.isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
  };

  // Add new MovieDetailsModal component after BeeModal component
  const MovieDetailsModal = ({ movie, onClose }) => {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div 
          className="relative w-full max-w-2xl p-6 mx-4 bg-white rounded-lg shadow-xl"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            onClick={onClose}
            className="absolute text-2xl text-gray-500 top-4 right-4 hover:text-gray-700"
            aria-label="Close Modal"
          >
            <FaTimes />
          </button>

          <div className="flex flex-col md:flex-row md:space-x-6">
            <div className="flex-shrink-0">
              <img
                src={movie.posterUrl}
                alt={movie.title}
                className="object-cover w-full rounded-lg md:w-48"
              />
            </div>
            <div className="flex-grow mt-4 md:mt-0">
              <h2 className="mb-2 text-2xl font-bold">{movie.title}</h2>
              <div className="grid grid-cols-2 gap-2 mb-4">
                <div>
                  <span className="font-semibold">Rating:</span> {(movie.rating * 2).toFixed(1)}/10
                </div>
                <div>
                  <span className="font-semibold">Year:</span> {movie.year}
                </div>
                <div className="col-span-2">
                  <span className="font-semibold">Genre:</span> {movie.genre}
                </div>
              </div>
              <div>
                <h3 className="mb-2 text-lg font-semibold">Overview</h3>
                <p className="text-gray-700">{movie.overview}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  MovieDetailsModal.propTypes = {
    movie: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      posterUrl: PropTypes.string.isRequired,
      rating: PropTypes.string.isRequired,
      genre: PropTypes.string.isRequired,
      year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      overview: PropTypes.string.isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
  };

  // Add new MovieInfoModal component
  const MovieInfoModal = ({ movie, onClose }) => {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div 
          className="relative w-full max-w-lg p-6 mx-4 bg-white rounded-lg shadow-xl"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            onClick={onClose}
            className="absolute text-2xl text-gray-500 top-4 right-4 hover:text-gray-700"
            aria-label="Close Modal"
          >
            <FaTimes />
          </button>

          <h2 className="mb-4 text-2xl font-bold">{movie.title}</h2>
          <p className="text-gray-700">{movie.overview}</p>
        </div>
      </div>
    );
  };

  // Add PropTypes for the new component
  MovieInfoModal.propTypes = {
    movie: PropTypes.shape({
      title: PropTypes.string.isRequired,
      overview: PropTypes.string.isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
  };

  // Modify fetchMovies function to handle loading state better
  const fetchMovies = async (pageNumber = 1) => {
    if (moviesLoading || !hasMore) return; // Prevent multiple simultaneous loads
    
    setMoviesLoading(true);
    setMoviesError(null);

    try {
      const baseUrl = 'https://api.themoviedb.org/3/discover/movie';
      
      const params = new URLSearchParams({
        language: 'en-US',
        sort_by: 'vote_average.desc',
        include_adult: false,
        include_video: false,
        'vote_count.gte': 500,
        page: pageNumber,
        certification_country: 'AU'
      });

      // Add genre filters from imported filters
      if (importedFilters) {
        const genreMap = {
          'Action': 28,
          'Adventure': 12,
          'Animation': 16,
          'Comedy': 35,
          'Crime': 80,
          'Documentary': 99,
          'Drama': 18,
          'Family': 10751,
          'Fantasy': 14,
          'History': 36,
          'Horror': 27,
          'Music': 10402,
          'Mystery': 9648,
          'Romance': 10749,
          'Science Fiction': 878,
          'Thriller': 53,
          'War': 10752,
          'Western': 37
        };

        const genres = [
          importedFilters.genre1,
          importedFilters.genre2,
          importedFilters.genre3
        ].filter(genre => genre && genre !== 'placeholder');

        if (genres.length > 0) {
          const genreIds = genres.map(genre => genreMap[genre]).filter(Boolean);
          if (genreIds.length > 0) {
            params.append('with_genres', genreIds.join(','));
          }
        }

        // Add year range filters
        if (importedFilters.yearFrom && importedFilters.yearFrom !== 'placeholder') {
          params.append('primary_release_date.gte', `${importedFilters.yearFrom}-01-01`);
        }
        if (importedFilters.yearTo && importedFilters.yearTo !== 'placeholder') {
          params.append('primary_release_date.lte', `${importedFilters.yearTo}-12-31`);
        }

        // Add classification filter
        if (importedFilters.classification && importedFilters.classification !== 'placeholder') {
          const certificationMap = {
            'G': 'AU/G',
            'PG': 'AU/PG',
            'M': 'AU/M',
            'MA': 'AU/MA15+'
          };
          
          const tmdbCertification = certificationMap[importedFilters.classification];
          if (tmdbCertification) {
            params.append('certification.lte', tmdbCertification);
          }
        }

        // Add language filters
        const languageFilters = ['English', 'Korean', 'Japanese', 'Chinese', 'Other']
          .filter(lang => importedFilters[lang] === 'true')
          .map(lang => {
            switch(lang) {
              case 'English': return 'en';
              case 'Korean': return 'ko';
              case 'Japanese': return 'ja';
              case 'Chinese': return 'zh';
              case 'Other': return 'other';
              default: return null;
            }
          })
          .filter(Boolean);

        if (languageFilters.length > 0) {
          if (languageFilters.includes('other')) {
            const mainLanguages = ['en', 'ko', 'ja', 'zh'].filter(
              lang => !languageFilters.includes(lang)
            );
            params.append('without_original_language', mainLanguages.join(','));
          } else {
            languageFilters.forEach(lang => {
              params.append('with_original_language', lang);
            });
          }
        }
      }

      const response = await fetch(`${baseUrl}?${params.toString()}`, {
        headers: {
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjYTkyY2Q4ZDA0N2U1MmZjYmE0Y2RhNjJjZDc3NDY3NyIsIm5iZiI6MTczNzAwNTk5Ny43MjgsInN1YiI6IjY3ODg5YmFkYjlkMWQxMGVmMTk3NzZmNyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.-fQQynk1C9DfRHxAqoDXTWDk_9LnaLj8ECj-50K-zbI',
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch movies');
      }

      const data = await response.json();

      // Create a genre mapping
      const genreMap = {
        28: 'Action',
        12: 'Adventure',
        16: 'Animation',
        35: 'Comedy',
        80: 'Crime',
        99: 'Documentary',
        18: 'Drama',
        10751: 'Family',
        14: 'Fantasy',
        36: 'History',
        27: 'Horror',
        10402: 'Music',
        9648: 'Mystery',
        10749: 'Romance',
        878: 'Science Fiction',
        53: 'Thriller',
        10752: 'War',
        37: 'Western'
      };

      const newMovies = data.results
        .map(movie => ({
          id: movie.id,
          title: movie.title,
          posterUrl: movie.poster_path 
            ? `https://image.tmdb.org/t/p/w500${movie.poster_path}`
            : 'placeholder-image-url',
          rating: (movie.vote_average / 2).toFixed(1),
          genre: movie.genre_ids
            .map(id => genreMap[id])
            .filter(Boolean)
            .join(', ') || 'Unknown',
          year: movie.release_date ? new Date(movie.release_date).getFullYear() : 'N/A',
          overview: movie.overview
        }))
        .filter(movie => {
          const isInWouldWatch = categorizedMovies.wouldWatch.some(m => m.id === movie.id);
          const isInWouldntWatch = categorizedMovies.wouldntWatch.some(m => m.id === movie.id);
          return !isInWouldWatch && !isInWouldntWatch;
        })
        .sort((a, b) => b.rating - a.rating);

      setMovies(prev => [...prev, ...newMovies]);
      setHasMore(data.total_pages > pageNumber);
      setPage(pageNumber);
    } catch (error) {
      console.error('Error fetching movies:', error);
      setMoviesError('Failed to load movies. Please try again.');
    } finally {
      setMoviesLoading(false);
    }
  };

  // Update effect to load more movies when list gets low
  useEffect(() => {
    if (movies.length < 10 && !moviesLoading && hasMore) { // Load more when less than 10 movies remain
      console.log('Loading more movies, current page:', page); // Debug log
      fetchMovies(page + 1);
    }
  }, [movies.length, moviesLoading, hasMore, page]);

  // Move handleFilterChange before any JSX that uses it
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === 'rating' || name === 'yearFrom' || name === 'yearTo') {
      setFilters(prev => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const options = [];

    // Last 10 years as individual options
    for (let year = currentYear; year >= currentYear - 10; year--) {
      options.push({ value: year, label: year.toString() });
    }

    // Group years by 5 from 1990 to (currentYear - 11)
    for (let year = currentYear - 11; year >= 1990; year -= 5) {
      const endYear = year;
      const startYear = year - 4;
      options.push({ 
        value: `${startYear}-${endYear}`, 
        label: `${startYear}'s-${endYear}'s` 
      });
    }

    // Decades before 1990
    for (let decade = 1980; decade >= 1900; decade -= 10) {
      options.push({ 
        value: `${decade}-${decade + 9}`, 
        label: `${decade}'s` 
      });
    }

    return options;
  };

  // Now define yearFilterJSX after handleFilterChange is defined
  const yearFilterJSX = (
    <div className="flex gap-4">
      <div>
        <label className="block mb-2 text-sm font-medium text-gray-700">
          From Year
        </label>
        <select
          id="yearFrom"
          name="yearFrom"
          value={filters.yearFrom}
          onChange={handleFilterChange}
          className="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        >
          <option value="">Any</option>
          {generateYearOptions().map((option) => (
            <option key={option.value} value={typeof option.value === 'string' ? option.value.split('-')[0] : option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label className="block mb-2 text-sm font-medium text-gray-700">
          To Year
        </label>
        <select
          id="yearTo"
          name="yearTo"
          value={filters.yearTo}
          onChange={handleFilterChange}
          className="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        >
          <option value="">Any</option>
          {generateYearOptions().map((option) => (
            <option key={option.value} value={typeof option.value === 'string' ? option.value.split('-')[1] : option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  // Add new handler for genre pills
  const handleGenreToggle = (genre) => {
    setFilters(prev => ({
      ...prev,
      genres: prev.genres.includes(genre)
        ? prev.genres.filter(g => g !== genre)
        : [...prev.genres, genre]
    }));
  };

  // Replace the genre filter dropdown with this new pill-based UI
  const genreFilterJSX = (
    <div>
      <label className="block mb-2 text-sm font-medium text-gray-700">
        Genres (click to select multiple)
      </label>
      <div className="flex flex-wrap gap-2">
        {[
          'Action', 'Adventure', 'Animation', 'Comedy', 'Crime',
          'Documentary', 'Drama', 'Family', 'Fantasy', 'History',
          'Horror', 'Music', 'Mystery', 'Romance', 'Science Fiction',
          'Thriller', 'War', 'Western'
        ].map((genre) => (
          <button
            key={genre}
            onClick={() => handleGenreToggle(genre)}
            className={`px-4 py-2 text-sm font-medium rounded-full transition-colors duration-200 ${
              filters.genres.includes(genre)
                ? 'bg-green-500 text-white hover:bg-green-600'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            {genre}
          </button>
        ))}
      </div>
    </div>
  );

  // Add new handler for certification pills
  const handleCertificationToggle = (certification) => {
    setFilters(prev => ({
      ...prev,
      classifications: prev.classifications.includes(certification)
        ? prev.classifications.filter(c => c !== certification)
        : [...prev.classifications, certification]
    }));
  };

  // Add the certification filter UI component
  const certificationFilterJSX = (
    <div>
      <label className="block mb-2 text-sm font-medium text-gray-700">
        Classification (click to select multiple)
      </label>
      <div className="flex flex-wrap gap-2">
        {[
          'G', 'PG', 'M', 'MA'
        ].map((cert) => (
          <button
            key={cert}
            onClick={() => handleCertificationToggle(cert)}
            className={`px-4 py-2 text-sm font-medium rounded-full transition-colors duration-200 ${
              filters.classifications.includes(cert)
                ? 'bg-blue-500 text-white hover:bg-blue-600'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            {cert}
          </button>
        ))}
      </div>
    </div>
  );

  // Add language handler
  const handleLanguageToggle = (language) => {
    setFilters(prev => ({
      ...prev,
      languages: prev.languages.includes(language)
        ? prev.languages.filter(l => l !== language)
        : [...prev.languages, language]
    }));
  };

  // Add language filter UI component
  const languageFilterJSX = (
    <div>
      <label className="block mb-2 text-sm font-medium text-gray-700">
        Language (click to select multiple)
      </label>
      <div className="flex flex-wrap gap-2">
        {[
          { code: 'en', label: 'English' },
          { code: 'ko', label: 'Korean' },
          { code: 'ja', label: 'Japanese' },
          { code: 'zh', label: 'Chinese' },
          { code: 'other', label: 'Other' }
        ].map(({ code, label }) => (
          <button
            key={code}
            onClick={() => handleLanguageToggle(code)}
            className={`px-4 py-2 text-sm font-medium rounded-full transition-colors duration-200 ${
              filters.languages.includes(code)
                ? 'bg-purple-500 text-white hover:bg-purple-600'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );

  // Update useEffect to refetch movies when categorizedMovies changes
  useEffect(() => {
    setPage(1);
    setMovies([]); // Clear existing movies
    fetchMovies(1); // Fetch first page
  }, [filters, categorizedMovies]); // Add categorizedMovies as dependency

  // Create tabs component
  const tabsJSX = (
    <div className="mb-6">
      <div className="border-b border-gray-200">
        <nav className="flex -mb-px">
          <button
            onClick={() => setActiveTab('browse')}
            className={`py-4 px-6 text-sm font-medium ${
              activeTab === 'browse'
                ? 'border-b-2 border-blue-500 text-blue-600'
                : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Browse
          </button>
          <button
            onClick={() => setActiveTab('filtered')}
            className={`py-4 px-6 text-sm font-medium ${
              activeTab === 'filtered'
                ? 'border-b-2 border-blue-500 text-blue-600'
                : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Filtered
          </button>
          <button
            onClick={() => setActiveTab('groupFiltered')}
            className={`py-4 px-6 text-sm font-medium ${
              activeTab === 'groupFiltered'
                ? 'border-b-2 border-blue-500 text-blue-600'
                : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Group Filtered
          </button>
        </nav>
      </div>
    </div>
  );

  // Update handleMovieCategory to check remaining movies
  const handleMovieCategory = (movie, category) => {
    setCategorizedMovies(prev => ({
      ...prev,
      [category]: [...prev[category], { ...movie, selectedBy: selectedBee?.name || 'Unknown' }]
    }));

    setMovies(prev => {
      const newMovies = prev.filter(m => m.id !== movie.id);
      // If we're running low on movies, trigger a fetch
      if (newMovies.length < 10 && hasMore && !moviesLoading) {
        console.log('Triggering fetch for more movies'); // Debug log
        fetchMovies(page + 1);
      }
      return newMovies;
    });
  };

  // Update the movie card to remove the info button
  const movieCard = (movie) => (
    <div className="max-w-2xl mx-auto">
      <div className="overflow-hidden bg-white rounded-lg shadow-lg">
        <div className="relative">
          <img
            src={movie.posterUrl}
            alt={movie.title}
            className="object-cover w-full"
            style={{ height: '500px' }}
          />
          <div className="absolute inset-0 flex">
            <div 
              className="flex flex-col items-center justify-center w-1/2 bg-green-500 bg-opacity-25 cursor-pointer hover:bg-opacity-60"
              onClick={() => handleMovieCategory(movie, 'wouldWatch')}
            >
              <svg className="w-20 h-20 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 13l4 4L19 7" />
              </svg>
              <span className="mt-2 text-xl font-medium text-white">Keep</span>
            </div>
            <div 
              className="flex flex-col items-center justify-center w-1/2 bg-red-500 bg-opacity-25 cursor-pointer hover:bg-opacity-60"
              onClick={() => handleMovieCategory(movie, 'wouldntWatch')}
            >
              <svg className="w-20 h-20 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M6 18L18 6M6 6l12 12" />
              </svg>
              <span className="mt-2 text-xl font-medium text-white">Remove</span>
            </div>
          </div>
        </div>
        <div 
          className="p-6 cursor-pointer hover:bg-gray-50"
          onClick={() => setSelectedMovie(movie)}
        >
          <div className="flex items-center justify-between gap-2">
            <h3 className="text-2xl font-semibold">{movie.title}</h3>
          </div>
          <div className="mt-4 space-y-2">
            <p className="text-lg text-gray-600">Rating: {(movie.rating * 2).toFixed(1)}</p>
            <p className="text-lg text-gray-600">Genre: {movie.genre}</p>
            <p className="text-lg text-gray-600">Year: {movie.year}</p>
            <p className="mt-4 text-gray-600">{movie.overview}</p>
          </div>
        </div>
      </div>
    </div>
  );

  // Add new handler for swapping movie categories
  const handleSwapCategory = (movie) => {
    const fromCategory = categorizedMovies.wouldWatch.find(m => m.id === movie.id)
      ? 'wouldWatch'
      : 'wouldntWatch';
    const toCategory = fromCategory === 'wouldWatch' ? 'wouldntWatch' : 'wouldWatch';

    setCategorizedMovies(prev => ({
      ...prev,
      [fromCategory]: prev[fromCategory].filter(m => m.id !== movie.id),
      [toCategory]: [...prev[toCategory], movie]
    }));
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    const dragIndex = parseInt(e.dataTransfer.getData('text/plain'));
    
    if (dragIndex === dropIndex) return;

    const items = Array.from(categorizedMovies.wouldWatch);
    const [reorderedItem] = items.splice(dragIndex, 1);
    items.splice(dropIndex, 0, reorderedItem);

    setCategorizedMovies(prev => ({
      ...prev,
      wouldWatch: items
    }));
  };

  // Update browseContent to handle movie removal and auto-loading
  const browseContent = (
    <div>
      <div className="flex items-center justify-center min-h-[600px]">
        {moviesLoading && movies.length === 0 ? (
          <div className="text-xl text-center text-gray-600">Loading movies...</div>
        ) : moviesError ? (
          <div className="text-xl text-center text-red-500">{moviesError}</div>
        ) : movies.length === 0 ? (
          <div className="text-xl text-center text-gray-600">No more movies found.</div>
        ) : (
          movieCard(movies[0])
        )}
      </div>
    </div>
  );

  const filteredContent = (
    <div>
      {/* Add Save and Reset Buttons */}
      <div className="flex gap-4 mb-6">
        <button
          onClick={() => {
            // TODO: Implement save functionality
            alert('Save functionality to be implemented');
          }}
          className="px-4 py-2 text-white transition-colors bg-green-500 rounded-md hover:bg-green-600"
        >
          Save Lists
        </button>
        <button
          onClick={() => {
            if (window.confirm('Are you sure? This will remove all movies from both lists.')) {
              setCategorizedMovies({
                wouldWatch: [],
                wouldntWatch: []
              });
            }
          }}
          className="px-4 py-2 text-white transition-colors bg-red-500 rounded-md hover:bg-red-600"
        >
          Reset All Lists
        </button>
      </div>

      <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
        {/* Would Watch Section */}
        <div className="p-4 bg-white rounded-lg shadow">
          <h3 className="mb-2 text-xl font-semibold text-green-600">Would Watch</h3>
          <p className="mb-4 text-sm text-gray-600">Your ranking: best to worst</p>
          {categorizedMovies.wouldWatch.length === 0 ? (
            <p className="text-gray-500">No movies added yet</p>
          ) : (
            <div className="space-y-3">
              {categorizedMovies.wouldWatch.map((movie, index) => (
                <div
                  key={movie.id}
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, index)}
                  className="flex items-center p-2 transition-shadow bg-white border rounded hover:shadow-md"
                >
                  <div className="flex items-center justify-center w-8 h-8 mr-2 text-gray-500 cursor-move">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                    </svg>
                  </div>
                  <button
                    onClick={() => handleSwapCategory(movie)}
                    className="flex items-center justify-center w-8 h-8 mr-2 text-white transition-colors bg-yellow-500 rounded-full hover:bg-yellow-600"
                    aria-label="Swap to Wouldn't Watch"
                    title="Move to Wouldn't Watch"
                  >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m-8 6H4m0 0l4 4m-4-4l4-4" />
                    </svg>
                  </button>
                  <img 
                    src={movie.posterUrl} 
                    alt={movie.title} 
                    className="object-cover w-12 h-12 rounded"
                  />
                  <div className="flex-grow ml-3">
                    <div className="flex items-center justify-between gap-2">
                      <p className="font-medium">{movie.title}</p>
                      <button
                        className="w-8 h-8 text-xl font-bold text-white transition-colors bg-blue-500 rounded-full hover:bg-blue-600"
                        onClick={() => setSelectedMovie(movie)}
                        aria-label="Movie Information"
                      >
                        i
                      </button>
                    </div>
                    <p className="text-sm text-gray-500">{movie.year}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Wouldn't Watch Section */}
        <div className="p-4 bg-white rounded-lg shadow">
          <h3 className="mb-4 text-xl font-semibold text-red-600">Wouldn't Watch</h3>
          {categorizedMovies.wouldntWatch.length === 0 ? (
            <p className="text-gray-500">No movies added yet</p>
          ) : (
            <div className="space-y-3">
              {categorizedMovies.wouldntWatch.map(movie => (
                <div key={movie.id} className="flex items-center p-2 border rounded">
                  <button
                    onClick={() => handleSwapCategory(movie)}
                    className="flex items-center justify-center w-8 h-8 mr-2 text-white transition-colors bg-yellow-500 rounded-full hover:bg-yellow-600"
                    aria-label="Swap to Would Watch"
                    title="Move to Would Watch"
                  >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m-8 6H4m0 0l4 4m-4-4l4-4" />
                    </svg>
                  </button>
                  <img 
                    src={movie.posterUrl} 
                    alt={movie.title} 
                    className="object-cover w-12 h-12 rounded"
                  />
                  <div className="flex-grow ml-3">
                    <div className="flex items-center justify-between gap-2">
                      <p className="font-medium">{movie.title}</p>
                      <button
                        className="w-8 h-8 text-xl font-bold text-white transition-colors bg-blue-500 rounded-full hover:bg-blue-600"
                        onClick={() => setSelectedMovie(movie)}
                        aria-label="Movie Information"
                      >
                        i
                      </button>
                    </div>
                    <p className="text-sm text-gray-500">{movie.year}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  // Add the group filtered content component
  const groupFilteredContent = (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
      {bees.map((bee) => (
        <div key={bee.name} className="p-4 bg-white rounded-lg shadow">
          <h3 className="mb-4 text-xl font-semibold text-blue-600">{bee.name}</h3>
          <div className="grid grid-cols-1 gap-4">
            {/* Would Watch Section */}
            <div className="p-3 rounded-lg bg-green-50">
              <h4 className="mb-2 font-medium text-green-600">Would Watch</h4>
              {categorizedMovies.wouldWatch
                .filter(movie => movie.selectedBy === bee.name)
                .map(movie => (
                  <div key={movie.id} className="flex items-center p-2 mb-2 bg-white border rounded">
                    <img 
                      src={movie.posterUrl} 
                      alt={movie.title} 
                      className="object-cover w-10 h-10 rounded"
                    />
                    <div className="flex-grow ml-2">
                      <p className="text-sm font-medium">{movie.title}</p>
                      <p className="text-xs text-gray-500">{movie.year}</p>
                    </div>
                    <button
                      className="w-6 h-6 text-sm font-bold text-white transition-colors bg-blue-500 rounded-full hover:bg-blue-600"
                      onClick={() => setSelectedMovie(movie)}
                      aria-label="Movie Information"
                    >
                      i
                    </button>
                  </div>
                ))}
            </div>

            {/* Wouldn't Watch Section */}
            <div className="p-3 rounded-lg bg-red-50">
              <h4 className="mb-2 font-medium text-red-600">Wouldn't Watch</h4>
              {categorizedMovies.wouldntWatch
                .filter(movie => movie.selectedBy === bee.name)
                .map(movie => (
                  <div key={movie.id} className="flex items-center p-2 mb-2 bg-white border rounded">
                    <img 
                      src={movie.posterUrl} 
                      alt={movie.title} 
                      className="object-cover w-10 h-10 rounded"
                    />
                    <div className="flex-grow ml-2">
                      <p className="text-sm font-medium">{movie.title}</p>
                      <p className="text-xs text-gray-500">{movie.year}</p>
                    </div>
                    <button
                      className="w-6 h-6 text-sm font-bold text-white transition-colors bg-blue-500 rounded-full hover:bg-blue-600"
                      onClick={() => setSelectedMovie(movie)}
                      aria-label="Movie Information"
                    >
                      i
                    </button>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  // Update mainContent to use tabs
  const mainContent = (
    <div className="container mx-auto">
      {tabsJSX}

      {activeTab === 'browse' ? browseContent : 
       activeTab === 'filtered' ? filteredContent :
       groupFilteredContent}
    </div>
  );

  // Add debug logging to see imported filters
  useEffect(() => {
    console.log('Imported filters:', importedFilters);
  }, [importedFilters]);

  return (
    <div className="fixed inset-0 z-50 flex flex-col bg-white">
      {/* Header Section with SVG Banner and Date/Time */}
      <div className="flex flex-col items-center justify-center w-full px-4 py-4 shadow-md">
        {/* Title */}
        <h1 className="text-3xl font-bold text-center">Movie Picker</h1>
        {/* Current Date and Time */}
        <div className="mt-2 text-xs font-bold text-gray-600">
          {new Date().toLocaleString()}
        </div>
      </div>

      {/* Banner Area with Enhanced Shadow */}
      <div
        className="w-full"
        style={{
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)', // Enhanced shadow retained
        }}
      >
        {/* Banner image with fixed height */}
        <img
          src={PuzzleBanner}
          alt="Puzzle Banner"
          style={{ height: '96px', width: '100%', objectFit: 'cover' }}
        />
      </div>

      {/* Close Button */}
      <button
        onClick={onClose}
        className="absolute text-3xl text-gray-500 top-4 right-4 hover:text-gray-700"
        aria-label="Close Workbook"
      >
        <FaTimes />
      </button>

      {/* Main Content */}
      <div className="flex-grow p-6 mt-4 overflow-auto bg-gray-100">
        {mainContent}
      </div>

      {/* Movie Info Modal */}
      {selectedMovie && (
        <MovieInfoModal 
          movie={selectedMovie} 
          onClose={() => setSelectedMovie(null)} 
        />
      )}
      
      {/* Bee Modal */}
      {selectedBee && <BeeModal bee={selectedBee} onClose={() => setSelectedBee(null)} />}
    </div>
  );
};

// PropTypes for validation
TeacherWorkbook.propTypes = {
  onClose: PropTypes.func.isRequired,
  classID: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    genre1: PropTypes.string,
    genre2: PropTypes.string,
    genre3: PropTypes.string,
    yearFrom: PropTypes.string,
    yearTo: PropTypes.string,
    classification: PropTypes.string,
    English: PropTypes.string,
    Korean: PropTypes.string,
    Japanese: PropTypes.string,
    Chinese: PropTypes.string,
    Other: PropTypes.string,
  }),
};

export default TeacherWorkbook;