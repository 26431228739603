// src/contexts/datacontroller.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  ref,
  get,
  set,
  remove,
  query,
  orderByChild,
  equalTo,
  onValue,
  update, // Import the update function
} from 'firebase/database';
import { rtdb, db } from '../firebase'; // Ensure 'db' is exported from your firebase config
import { useAuth } from './AuthContext';
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore functions

export const MODULE_STATES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  LOCKED: 'locked',
  MOVIE: 'Movie'
};

// Mapping from module status keys to base module names
const MODULE_NAME_MAP = {
  whiteboardactive: 'whiteboard',
  slidesactive: 'slides',
  characteractive: 'yourHive',
  sitesactive: 'sites',
  testsactive: 'tests',
  gamesactive: 'games',
  journalactive: 'journal',
};

// Optional: Reverse mapping if needed elsewhere
const BASE_TO_STATUS_MAP = Object.entries(MODULE_NAME_MAP).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {});

const DataControllerContext = createContext();

export const useDataController = () => {
  return useContext(DataControllerContext);
};

export const DataControllerProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [activeClasses, setActiveClasses] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!currentUser) {
      setActiveClasses({});
      setIsLoading(false);
      return;
    }

    // Create a query for classes where the current user is the teacher
    const activeClassesRef = ref(rtdb, 'activeclasses');
    const teacherClassesQuery = query(
      activeClassesRef,
      orderByChild('teacherID'),
      equalTo(currentUser.uid)
    );

    const unsubscribe = onValue(
      teacherClassesQuery,
      (snapshot) => {
        const data = snapshot.val() || {};
        setActiveClasses(data);
        setIsLoading(false);
      },
      (error) => {
        console.error('Error fetching active classes:', error);
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  // Helper function to generate a 6-character class code matching the validation pattern
  const generateClassCode = () => {
    const characters = 'ACEGHIJKLMNOSTUVWYZ'; // Allowed characters as per validation regex
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  /**
   * Set the current active app for a class.
   * @param {string} classId - The ID of the class.
   * @param {string} appName - The name of the app to set as active.
   */
  const setCurrentApp = async (classId, appName) => {
    if (!currentUser) throw new Error('No authenticated user');

    try {
      const currentAppRef = ref(rtdb, `activeclasses/${classId}/currentApp`);
      await set(currentAppRef, appName);
      console.log(`Set currentApp to "${appName}" for class "${classId}".`);
    } catch (error) {
      console.error(`Error setting current app to ${appName} in class ${classId}:`, error);
      throw error;
    }
  };

  /**
   * Activate a class by transferring data from Firestore to RTDB, including bee names with additional fields.
   * @param {string} classId - The ID of the class to activate.
   */
  const activateClass = async (classId, initialData) => {
    if (!currentUser) throw new Error('No authenticated user');

    try {
      // Fetch class data from Firestore
      const teacherId = currentUser.uid;
      const classDocRef = doc(db, `users/${teacherId}/classes`, classId);
      const classDoc = await getDoc(classDocRef);

      if (!classDoc.exists()) {
        throw new Error('Class does not exist in Firestore');
      }

      const classFirestoreData = classDoc.data();

      // Extract bee names (student names) from Firestore data
      const beeNames = classFirestoreData.users || []; // Adjust the field name if necessary

      // Transform beeNames array into an object with additional fields
      const beeNamesData = {};
      beeNames.forEach((beeName) => {
        beeNamesData[beeName] = {
          logged: 'no',        // Initialize 'logged' to 'no'
          currentApp: 'none',  // Initialize 'currentApp' to 'none'
          totalxp: 0,          // Initialize 'totalxp' to 0 (number field)
        };
      });

      // Define the module statuses with 'inactive' as default
      const moduleStatuses = {
        whiteboardactive: MODULE_STATES.INACTIVE,
        slidesactive: MODULE_STATES.INACTIVE,
        characteractive: MODULE_STATES.INACTIVE,
        sitesactive: MODULE_STATES.INACTIVE,
        testsactive: MODULE_STATES.INACTIVE,
        gamesactive: MODULE_STATES.INACTIVE,
        journalactive: MODULE_STATES.INACTIVE, // Added 'journalactive' if needed
      };

      // Generate a unique 6-letter class code
      const classCode = generateClassCode();

      // Create the RTDB class data structure, including bee names with additional fields
      const newClassData = {
        name: classFirestoreData.name,
        teacherID: currentUser.uid,
        activatedAt: new Date().toISOString(),
        activatedBy: currentUser.uid,
        classcode: classCode,
        teachers: {
          [currentUser.uid]: true,
        },
        whiteboard: {
          appState: {
            viewBackgroundColor: '#ffffff',
            timestamp: Date.now(),
          }
        },
        MoviePicker: {
          movies: {},
          status: 'created',  // Add placeholder status
          filters: {
            genre1: 'placeholder',
            genre2: 'placeholder',
            genre3: 'placeholder',
            classification: 'placeholder',
            yearFrom: 'placeholder',
            yearTo: 'placeholder',
            English: 'placeholder',
            Korean: 'placeholder',
            Japanese: 'placeholder',
            Chinese: 'placeholder',
            Other: 'placeholder'
          }
        },
        modulestatus: moduleStatuses,
        beeNames: beeNamesData,
      };

      console.log('About to write class data with MoviePicker:', newClassData);

      // Set the data at the specific class ID location in RTDB
      const classRef = ref(rtdb, `activeclasses/${classId}`);
      await set(classRef, newClassData);

      // After setting the class data, set the class code in classcodes
      const classCodeRef = ref(rtdb, `classcodes/${classId}`);
      await set(classCodeRef, classCode);
    } catch (error) {
      console.error(`Error activating class ${classId}:`, error);
      throw error;
    }
  };

  /**
   * Deactivate a class by removing it from RTDB.
   * @param {string} classId - The ID of the class to deactivate.
   */
  const deactivateClass = async (classId) => {
    if (!currentUser) throw new Error('No authenticated user');

    try {
      // Check if the class exists and belongs to the current user
      const classRef = ref(rtdb, `activeclasses/${classId}`);
      const snapshot = await get(classRef);

      if (!snapshot.exists()) {
        throw new Error('Class does not exist');
      }

      const classData = snapshot.val();
      if (classData.teacherID !== currentUser.uid) {
        throw new Error('Unauthorized to deactivate this class');
      }

      // Remove the class code from classcodes first
      const classCodeRef = ref(rtdb, `classcodes/${classId}`);
      await remove(classCodeRef);

      // Then remove the class from activeclasses
      await remove(classRef);
    } catch (error) {
      console.error(`Error deactivating class ${classId}:`, error);
      throw error;
    }
  };

  const isClassActive = (classId) => {
    return Boolean(activeClasses[classId]);
  };

  const getActiveClassData = (classId) => {
    return activeClasses[classId] || null;
  };

  /**
   * Set the status of a specific module within a class.
   * @param {string} classId - The ID of the class.
   * @param {string} moduleName - The name of the module (e.g., 'whiteboardactive').
   * @param {string} status - The new status ('active', 'inactive', 'locked').
   * @param {string} [moviePickerStatus] - Optional status for MoviePicker.
   */
  const setModuleStatus = async (classId, moduleName, status, moviePickerStatus) => {
    if (!currentUser) throw new Error('No authenticated user');

    // Validate status
    if (!Object.values(MODULE_STATES).includes(status)) {
      throw new Error(`Invalid status: ${status}`);
    }

    try {
      const updates = {};
      
      // Update module status
      updates[`activeclasses/${classId}/modulestatus/${moduleName}`] = status;
      
      // If moviePickerStatus is provided and the module is games, update MoviePicker status
      if (moduleName === 'gamesactive' && moviePickerStatus) {
        updates[`activeclasses/${classId}/MoviePicker/status`] = moviePickerStatus;
      }

      await update(ref(rtdb), updates);
    } catch (error) {
      console.error(
        `Error setting status for ${moduleName} in class ${classId}:`,
        error
      );
      throw error;
    }
  };

  /**
   * Toggle the lock status of a specific module within a class.
   * If it's locked, unlock it to 'inactive'. If unlocked, set it to 'locked'.
   * Additionally, update the `currentApp` field for each bee accordingly.
   * @param {string} classId - The ID of the class.
   * @param {string} moduleStatusKey - The name of the module status key (e.g., 'testsactive').
   */
  const toggleLockModule = async (classId, moduleStatusKey) => {
    if (!currentUser) throw new Error('No authenticated user');

    try {
      const moduleRef = ref(
        rtdb,
        `activeclasses/${classId}/modulestatus/${moduleStatusKey}`
      );
      const snapshot = await get(moduleRef);
      const currentStatus = snapshot.val() || MODULE_STATES.INACTIVE;

      let newStatus;
      if (currentStatus === MODULE_STATES.LOCKED) {
        // Unlock the module
        newStatus = MODULE_STATES.INACTIVE;
        await set(moduleRef, newStatus);
        console.log(`Unlocked module "${moduleStatusKey}" in class "${classId}".`);

        // **Do not change the bees' currentApp when unlocking**
        // This allows the currentApp to remain as the locked module's app until the student chooses another one.
      } else {
        // Lock the module
        newStatus = MODULE_STATES.LOCKED;
        await set(moduleRef, newStatus);

        // Fetch all bees in the class to update their currentApp to the locked module's app
        const classData = activeClasses[classId];
        if (classData && classData.beeNames) {
          const beeNamesData = classData.beeNames;
          const updates = {};

          // Get the base module name using the mapping
          const baseModuleName = MODULE_NAME_MAP[moduleStatusKey];
          if (!baseModuleName) {
            console.warn(`No base module name found for "${moduleStatusKey}". Skipping currentApp update.`);
            return;
          }

          Object.keys(beeNamesData).forEach((beeName) => {
            updates[`activeclasses/${classId}/beeNames/${beeName}/currentApp`] = baseModuleName;
          });

          if (Object.keys(updates).length > 0) {
            await update(ref(rtdb), updates);
            console.log(
              `Locked module "${moduleStatusKey}" and set it as currentApp ("${baseModuleName}") for all bees in class "${classId}".`
            );
          }
        } else {
          console.warn(`No bees found in class "${classId}" to update.`);
        }
      }
    } catch (error) {
      console.error(
        `Error toggling lock for ${moduleStatusKey} in class ${classId}:`,
        error
      );
      throw error;
    }
  };

  return (
    <DataControllerContext.Provider
      value={{
        activeClasses,
        activateClass,
        deactivateClass,
        isClassActive,
        getActiveClassData,
        setModuleStatus,
        toggleLockModule, // Export the updated function
        setCurrentApp, // Ensure this is exported if needed elsewhere
        isLoading,
      }}
    >
      {children}
    </DataControllerContext.Provider>
  );
};
