// src/components/Teachers/Slideshow/SlideCreator.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from './Button'; // Adjust the path based on your project structure
import PixiCanvas from '../../../pixi/ThreeJS'; // Adjust the path based on your project structure

// Reusable Dial Component
const Dial = ({ label, value, onChange, min, max, step }) => {
  return (
    <div className="flex items-center space-x-2">
      <span className="w-20 text-sm text-gray-700">{label}</span>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        step={step}
        className="flex-1"
      />
      <span className="w-10 text-sm text-gray-700">{value.toFixed(2)}</span>
    </div>
  );
};

// Add this new Slider component near the top
const Slider = ({ label, value, onChange, min = 0, max = 10, step = 0.5 }) => (
  <div className="flex items-center mb-1">
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={onChange}
      className="w-24"
    />
    <span className="ml-1 text-[10px]">{label}: {value}</span>
  </div>
);

const SlideCreator = ({ onClose, onPopSlide }) => {
  // Replace the static genres array with a dynamic one based on slides
  const genres = [
    'Blank',
    'Office',
    'Library',
    'Park',
    'Classroom',
    'Street',
    'Desk',
    'Living Room',
    'Solar System',
  ];

  // Placeholder subtitles with empty strings
  const placeholderSubtitles = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ];

  // Add this object for location coordinates near the top of the component
  const locationCoordinates = {
    Custom: { x: 0, y: 150, z: -1000 },
    Space: { x: 0, y: 3000, z: -1000 },
    Atmosphere: { x: 0, y: 2000, z: -1000 },
    Sky: { x: 0, y: 150, z: -1000 },
    OuterRange: { x: 0, y: 150, z: -1000 },
    MidRange: { x: 0, y: 150, z: -1000 },
    Near: { x: 0, y: 150, z: -1000 },
    Ground: { x: 0, y: 150, z: -1000 },
    UnderGround: { x: 0, y: 150, z: -1000 }
  };

  // Convert locationTextMatrix to state and initialize it
  const [locationTextMatrix, setLocationTextMatrix] = useState({
    Space: ['', '', '', '', '', '', '', '', '', ''],
    Atmosphere: ['', '', '', '', '', '', '', '', '', ''],
    Sky: ['', '', '', '', '', '', '', '', '', ''],
    OuterRange: ['', '', '', '', '', '', '', '', '', ''],
    MidRange: ['', '', '', '', '', '', '', '', '', ''],
    Near: ['', '', '', '', '', '', '', '', '', ''],
    Ground: ['', '', '', '', '', '', '', '', '', ''],
    UnderGround: ['', '', '', '', '', '', '', '', '', '']
  });

  // Add this helper function to update the matrix
  const updateLocationMatrix = (updatedSlides) => {
    const newMatrix = { ...locationTextMatrix };
    
    // Reset all values to empty strings instead of "Blank"
    Object.keys(newMatrix).forEach(location => {
      newMatrix[location] = Array(10).fill('');
    });
    
    // Update matrix based on slides
    updatedSlides.forEach(slide => {
      if (slide.location && slide.themeNumber && slide.themeNumber <= 10) {
        // Ensure we're accessing a valid index (subtract 1 from themeNumber)
        const index = Math.min(Math.max(0, slide.themeNumber - 1), 9);
        newMatrix[slide.location][index] = slide.theme || '';
      }
    });
    
    setLocationTextMatrix(newMatrix);
  };

  // Modified function to generate initial slides with themes and proper numbering
  const generateInitialSlides = () => {
    let currentTheme = null;
    let themeNumber = 1;
    let sequenceNumber = 1;

    return genres.map((genre, index) => {
      if (currentTheme !== genre) {
        if (currentTheme !== null) {
          themeNumber++;
        }
        currentTheme = genre;
        sequenceNumber = 1;
      } else {
        sequenceNumber++;
      }

      return {
        id: Date.now() + index,
        title: `Near ${themeNumber}.${sequenceNumber} ${genre}`,
        subtitle: "", // Initialize with empty subtitle
        theme: genre,
        themeNumber: themeNumber,
        sequenceNumber: sequenceNumber,
        x: 0,
        y: 0,
        z: 0,
        location: 'Near'
      };
    });
  };

  // Initialize slides with genres
  const [slides, setSlides] = useState(generateInitialSlides());

  // State to track the current slide index
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // State to track navigation direction for animation
  const [navigationDirection, setNavigationDirection] = useState(0); // 1 for next, -1 for previous, 0 for direct navigation

  // New state to track if a transition is in progress
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Add this new state near the top of the component with other state declarations
  const [showCoordinates, setShowCoordinates] = useState(false);

  // Make "show/hide matrix" hidden by default
  const [showMatrix, setShowMatrix] = useState(false);

  // Add this new state near other state declarations
  const [showText, setShowText] = useState(true);

  // New state to track character controls
  const [characterScale, setCharacterScale] = useState(0.2);
  const [characterSpeed, setCharacterSpeed] = useState(1);
  // Update armEnergy and legEnergy based on characterSpeed
  const [armEnergy, setArmEnergy] = useState(characterSpeed + 0.5);
  const [legEnergy, setLegEnergy] = useState(characterSpeed);
  const [rightElbowModifier, setRightElbowModifier] = useState(45);
  const [leftElbowModifier, setLeftElbowModifier] = useState(45);

  // Modify eyebrow state to use shared controls
  const [eyebrowHeight, setEyebrowHeight] = useState(2.5);
  const [eyebrowAngle, setEyebrowAngle] = useState(10);

  // Add new state for arm angles
  const [rightArmAngle, setRightArmAngle] = useState(0);
  const [leftArmAngle, setLeftArmAngle] = useState(0);

  // Add new state for skin and hair color
  const [skinHue, setSkinHue] = useState(30); // Default hue for skin tone
  const [hairHue, setHairHue] = useState(20); // Default hue for hair

  // New state for World Settings Speed
  const [worldSpeed, setWorldSpeed] = useState(0); // Default value

  // New state for World Settings Scale
  const [worldScale, setWorldScale] = useState(0); // Default value for world scale

  // Add new state for variable scale
  const [variableScale, setVariableScale] = useState(0.2); // Default value for variable scale

  // Synchronize characterSpeed with worldSpeed and update characterScale
  useEffect(() => {
    setCharacterSpeed(worldSpeed);
    setArmEnergy(worldSpeed + 0.5);
    setLegEnergy(worldSpeed);
    setCharacterScale(variableScale + worldScale);
  }, [worldSpeed, worldScale, variableScale]);

  const currentSlide = slides[currentSlideIndex];

  // Function to handle animation completion
  const handleAnimationComplete = () => {
    setIsTransitioning(false);
  };

  // Handlers for navigating slides
  const handleNext = () => {
    if (currentSlideIndex >= slides.length - 1) return;
    setShowText(false);
    
    // Wait for text to disappear before changing slide
    setTimeout(() => {
      setNavigationDirection(1);
      setCurrentSlideIndex(currentSlideIndex + 1);
      
      // Wait for animation and then show text
      setTimeout(() => setShowText(true), 3000);
    }, 500);
  };

  const handlePrevious = () => {
    if (currentSlideIndex === 0) return;
    setShowText(false);
    
    // Wait for text to disappear before changing slide
    setTimeout(() => {
      setNavigationDirection(-1);
      setCurrentSlideIndex(currentSlideIndex - 1);
      
      // Wait for animation and then show text
      setTimeout(() => setShowText(true), 3000);
    }, 500);
  };

  // Handler for clicking on a slide preview
  const handleSlideClick = (index) => {
    if (index === currentSlideIndex) return;
    setShowText(false);
    
    // Wait for text to disappear before changing slide
    setTimeout(() => {
      setNavigationDirection(index > currentSlideIndex ? 1 : -1);
      setCurrentSlideIndex(index);
      
      // Wait for animation and then show text
      setTimeout(() => setShowText(true), 3000);
    }, 500);
  };

  // Add this handler near other handlers
  const handleThemeChange = (e) => {
    const newTheme = e.target.value;
    
    const updatedSlides = [...slides];
    updatedSlides[currentSlideIndex] = {
      ...slides[currentSlideIndex],
      theme: newTheme
    };

    let currentTheme = null;
    let themeNumber = 1;
    let sequenceNumber = 1;

    const finalSlides = updatedSlides.map((slide, index) => {
      if (currentTheme !== slide.theme) {
        if (currentTheme !== null) {
          themeNumber++;
        }
        currentTheme = slide.theme;
        sequenceNumber = 1;
      } else {
        sequenceNumber++;
      }

      return {
        ...slide,
        title: `${slide.location || 'Field'} ${themeNumber}.${sequenceNumber} ${slide.theme}`,
        themeNumber: themeNumber,
        sequenceNumber: sequenceNumber
      };
    });

    setSlides(finalSlides);
    updateLocationMatrix(finalSlides);
  };

  // Add new handler for adding slides
  const handleAddSlide = () => {
    const newSlide = {
      id: Date.now(),
      theme: 'Scene1',
      subtitle: "", // Initialize with empty subtitle
      x: 0,
      y: 0,
      z: 0,
      location: 'Field',
      themeNumber: 1,
      sequenceNumber: 1
    };

    const updatedSlides = [...slides, newSlide];
    
    // Recalculate numbers for all slides
    let currentTheme = null;
    let themeNumber = 1;
    let sequenceNumber = 1;

    const finalSlides = updatedSlides.map((slide) => {
      if (currentTheme !== slide.theme) {
        if (currentTheme !== null) {
          themeNumber++;
        }
        currentTheme = slide.theme;
        sequenceNumber = 1;
      } else {
        sequenceNumber++;
      }

      return {
        ...slide,
        title: `${slide.theme} ${themeNumber}.${sequenceNumber}`,
        themeNumber: themeNumber,
        sequenceNumber: sequenceNumber
      };
    });

    setSlides(finalSlides);
    setCurrentSlideIndex(finalSlides.length - 1); // Select the new slide
  };

  // Add handler for deleting slides
  const handleDeleteSlide = (index, e) => {
    e.stopPropagation(); // Prevent slide selection when clicking delete
    
    const updatedSlides = slides.filter((_, i) => i !== index);
    
    // Recalculate numbers for remaining slides
    let currentTheme = null;
    let themeNumber = 1;
    let sequenceNumber = 1;

    const finalSlides = updatedSlides.map((slide) => {
      if (currentTheme !== slide.theme) {
        if (currentTheme !== null) {
          themeNumber++;
        }
        currentTheme = slide.theme;
        sequenceNumber = 1;
      } else {
        sequenceNumber++;
      }

      return {
        ...slide,
        title: `${slide.theme} ${themeNumber}.${sequenceNumber}`,
        themeNumber: themeNumber,
        sequenceNumber: sequenceNumber
      };
    });

    setSlides(finalSlides);
    // Update the matrix with the remaining slides
    updateLocationMatrix(finalSlides);
    
    // Adjust current slide index if necessary
    if (index <= currentSlideIndex) {
      setCurrentSlideIndex(Math.max(0, currentSlideIndex - 1));
    }
  };

  // Add function to get highest theme number
  const getHighestThemeNumber = () => {
    if (!slides.length) return 1;
    return Math.max(...slides.map(slide => slide.themeNumber));
  };

  // Add coordinate change handler
  const handleCoordinateChange = (coord, value) => {
    const updatedSlides = [...slides];
    updatedSlides[currentSlideIndex] = {
      ...slides[currentSlideIndex],
      [coord]: Number(value)
    };
    setSlides(updatedSlides);
  };

  // Add this handler near other handlers
  const handleLocationChange = (e) => {
    const location = e.target.value;
    const coordinates = locationCoordinates[location];
    
    if (coordinates) {
      const updatedSlides = [...slides];
      updatedSlides[currentSlideIndex] = {
        ...slides[currentSlideIndex],
        x: coordinates.x,
        y: coordinates.y,
        z: coordinates.z,
        location: location,
        title: `${location} ${currentSlide.themeNumber}.${currentSlide.sequenceNumber} ${currentSlide.theme}`
      };
      setSlides(updatedSlides);
      updateLocationMatrix(updatedSlides);
    }
  };

  // Update armEnergy and legEnergy whenever characterSpeed changes
  const handleSpeedChange = (value) => {
    setCharacterSpeed(value);
    setArmEnergy(value + 0.5);
    setLegEnergy(value);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-60">
      {/* Fullscreen Modal Container */}
      <div className="flex flex-col w-full h-full bg-white rounded-none shadow-none md:flex-row">
        
        {/* **Left Panel (Genres List)** */}
        <div className="flex flex-col w-full p-4 overflow-y-auto border-r md:w-1/5">
          <div className="flex items-center justify-between mb-4">
            <h4 className="text-lg font-medium">Genres</h4>
            <button
              onClick={handleAddSlide}
              className="p-1 text-xl font-bold text-blue-500 hover:text-blue-700"
              title="Add new slide"
            >
              +
            </button>
          </div>
          <ul className="flex-1 space-y-4">
            {slides.map((slide, index) => (
              <li
                key={slide.id}
                onClick={() => handleSlideClick(index)}
                className={`cursor-pointer rounded border w-48 relative ${
                  currentSlideIndex === index
                    ? 'border-blue-500 bg-blue-50'
                    : 'border-gray-200 hover:bg-gray-100'
                }`}
              >
                <div className="flex items-center p-2">
                  <span className="flex-shrink-0 w-6 text-sm font-semibold text-center text-gray-700">
                    {index + 1}
                  </span>

                  <div className="w-32 mx-2">
                    <div className="flex items-center justify-center w-full overflow-hidden rounded aspect-video bg-gray-50">
                      <p className="px-2 text-xs text-center text-gray-700">
                        {slide.title.length > 20
                          ? `${slide.title.substring(0, 20)}...`
                          : slide.title}
                      </p>
                    </div>
                  </div>
                  
                  <button
                    onClick={(e) => handleDeleteSlide(index, e)}
                    className="absolute text-red-500 right-2 top-2 hover:text-red-700"
                    title="Delete slide"
                  >
                    ×
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* **Right Panel (Slide Editor with PixiCanvas)** */}
        <div className="flex flex-col w-full p-6 overflow-y-auto md:w-4/5">
          {/* **Header with Title and Close Button** */}
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-4">
              <h3 className="text-xl font-semibold">
                {currentSlide
                  ? `Slide - ${currentSlide.title}`
                  : 'Select a Genre'}
              </h3>
              {/* Location Dropdown Boxes - Now First */}
              <select 
                className="p-2 text-sm text-gray-700 border rounded-md"
                onChange={handleLocationChange}
                value={currentSlide?.location || 'Field'}
              >
                {Object.keys(locationCoordinates).map(location => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </select>
              {/* Theme Dropdown Boxes - Now Second */}
              <select 
                className="p-2 text-sm text-gray-700 border rounded-md"
                value={currentSlide?.theme}
                onChange={handleThemeChange}
              >
                <option value="Blank">Blank</option>
                <option value="Office">Office</option>
                <option value="Library">Library</option>
                <option value="Park">Park</option>
                <option value="Classroom">Classroom</option>
                <option value="Street">Street</option>
                <option value="Desk">Desk</option>
                <option value="Living Room">Living Room</option>
                <option value="Solar System">Solar System</option>
                <option value="Human Body">Human Body</option>
                <option value="Forest">Forest</option>
                <option value="Ocean">Ocean</option>
                <option value="Museum">Museum</option>
              </select>
              {/* Add toggle button */}
              <button
                onClick={() => setShowCoordinates(!showCoordinates)}
                className="px-3 py-1 text-xs text-gray-400 border rounded-md hover:bg-gray-100"
              >
                {showCoordinates ? 'Hide XYZ' : 'Show XYZ'}
              </button>

              {/* Wrap coordinate inputs in conditional rendering */}
              {showCoordinates && (
                <div className="flex items-center space-x-2">
                  <div className="flex items-center">
                    <span className="mr-1 text-sm text-gray-700">x:</span>
                    <input
                      type="number"
                      value={currentSlide?.x || 0}
                      onChange={(e) => handleCoordinateChange('x', e.target.value)}
                      className="w-16 p-2 text-sm text-gray-700 border rounded-md"
                    />
                  </div>
                  <div className="flex items-center">
                    <span className="mr-1 text-sm text-gray-700">y:</span>
                    <input
                      type="number"
                      value={currentSlide?.y || 0}
                      onChange={(e) => handleCoordinateChange('y', e.target.value)}
                      className="w-16 p-2 text-sm text-gray-700 border rounded-md"
                    />
                  </div>
                  <div className="flex items-center">
                    <span className="mr-1 text-sm text-gray-700">z:</span>
                    <input
                      type="number"
                      value={currentSlide?.z || 0}
                      onChange={(e) => handleCoordinateChange('z', e.target.value)}
                      className="w-16 p-2 text-sm text-gray-700 border rounded-md"
                    />
                  </div>
                </div>
              )}
            </div>
            <Button
              onClick={onClose}
              variant="ghost"
              className="ml-4 text-2xl leading-none"
              aria-label="Close Slide Creator"
              disabled={isTransitioning}
            >
              &times;
            </Button>
          </div>

          {currentSlide && (
            <div className="flex flex-col justify-between flex-1 space-y-4">
              {/* **PixiCanvas Integration** */}
              <div className="flex flex-col items-center justify-center p-4 overflow-hidden bg-gray-100 border rounded-md">
                <div className="relative w-full" style={{ paddingTop: '56.25%' }}> {/* 56.25% = 9/16 */}
                  <div className="absolute inset-0 flex items-center justify-center">
                    <PixiCanvas
                      width={500}
                      height={300}
                      backgroundColor={0x1099bb}
                      theme={currentSlide.theme}
                      currentSlide={currentSlide.themeNumber}
                      totalSlides={getHighestThemeNumber()}
                      cameraPosition={{
                        x: currentSlide.x || 0,
                        y: currentSlide.y || 0,
                        z: currentSlide.z || 0
                      }}
                      characterScale={characterScale}
                      characterSpeed={characterSpeed}
                      armEnergy={armEnergy}
                      legEnergy={legEnergy}
                      rightElbowModifier={rightElbowModifier}
                      leftElbowModifier={leftElbowModifier}
                      leftEyebrowHeight={eyebrowHeight}
                      rightEyebrowHeight={eyebrowHeight}
                      leftEyebrowAngle={eyebrowAngle}
                      rightEyebrowAngle={-eyebrowAngle}
                      leftArmAngle={leftArmAngle}
                      rightArmAngle={rightArmAngle}
                      skinHue={skinHue}
                      hairHue={hairHue}
                    />
                    
                    {/* **Conditional Text Overlay** */}
                    {currentSlide.subtitle && showText && (
                      <div 
                        className={`absolute w-4/5 max-w-2xl transform -translate-x-1/2 bottom-20 left-1/2 transition-opacity duration-500 ${
                          showText ? 'opacity-100' : 'opacity-0'
                        }`}
                      >
                        <div 
                          className="p-4 bg-white rounded-lg shadow-lg bg-opacity-90 backdrop-blur-sm"
                        >
                          <p className="text-lg font-light leading-relaxed text-center text-gray-700">
                            {currentSlide.subtitle}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* Add this before the Navigation Buttons div */}
                    <div className="absolute p-2 bg-white rounded-lg bottom-20 right-4 bg-opacity-80">
                      <div className="mb-1 text-xs font-bold">Character Controls</div>
                      <Dial 
                        label="Scale" 
                        value={variableScale} 
                        onChange={(e) => setVariableScale(parseFloat(e.target.value))}
                        min={0}
                        max={1}
                        step={0.01}
                      />
                      {/* <Dial 
                        label="Scale" 
                        value={characterScale} 
                        onChange={(e) => setCharacterScale(parseFloat(e.target.value))}
                        min={0.01}
                        max={1}
                        step={0.01}
                      /> */}
                      <Slider
                        label="Left Arm Angle"
                        value={leftArmAngle}
                        onChange={(e) => setLeftArmAngle(parseFloat(e.target.value))}
                        min={-90}
                        max={90}
                        step={5}
                      />
                      <Slider
                        label="Right Arm Angle"
                        value={rightArmAngle}
                        onChange={(e) => setRightArmAngle(parseFloat(e.target.value))}
                        min={-90}
                        max={90}
                        step={5}
                      />
                      <Slider
                        label="Right Elbow"
                        value={rightElbowModifier}
                        onChange={(e) => setRightElbowModifier(parseFloat(e.target.value))}
                        max={90}
                        step={5}
                      />
                      <Slider
                        label="Left Elbow"
                        value={leftElbowModifier}
                        onChange={(e) => setLeftElbowModifier(parseFloat(e.target.value))}
                        max={90}
                        step={5}
                      />
                      <div className="mt-2 mb-1 text-xs font-bold">Eyebrow Controls</div>
                      <Slider
                        label="Height"
                        value={eyebrowHeight}
                        onChange={(e) => setEyebrowHeight(parseFloat(e.target.value))}
                        min={-5}
                        max={5}
                        step={0.5}
                      />
                      <Slider
                        label="Angle"
                        value={eyebrowAngle}
                        onChange={(e) => setEyebrowAngle(parseFloat(e.target.value))}
                        min={-45}
                        max={45}
                        step={5}
                      />
                      <div className="mt-2 mb-1 text-xs font-bold">Appearance</div>
                      <Slider
                        label="Skin Hue"
                        value={skinHue}
                        onChange={(e) => setSkinHue(parseFloat(e.target.value))}
                        min={0}
                        max={360}
                        step={1}
                      />
                      <Slider
                        label="Hair Hue"
                        value={hairHue}
                        onChange={(e) => setHairHue(parseFloat(e.target.value))}
                        min={0}
                        max={360}
                        step={1}
                      />
                      
                      {/* New World Settings Section */}
                      <div className="mt-4 mb-1 text-xs font-bold">World Settings</div>
                      <Dial 
                        label="Speed" 
                        value={worldSpeed} 
                        onChange={(e) => setWorldSpeed(parseFloat(e.target.value))}
                        min={0}
                        max={10}
                        step={0.1}
                      />
                      <Dial label="Zoom" value={5} onChange={() => {}} min={1} max={10} step={0.01} />
                      <Dial 
                        label="Scale" 
                        value={worldScale} 
                        onChange={(e) => setWorldScale(parseFloat(e.target.value))}
                        min={0}
                        max={1}
                        step={0.01}
                      />
                      <Dial label="Pan" value={5} onChange={() => {}} min={1} max={10} step={0.1} />
                    </div>

                    {/* Navigation Buttons */}
                    <div className="absolute left-0 right-0 flex justify-center space-x-4 bottom-4">
                      <button
                        type="button"
                        onClick={handlePrevious}
                        disabled={currentSlideIndex === 0 || isTransitioning}
                        className={`px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none ${
                          currentSlideIndex === 0 || isTransitioning
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                        }`}
                      >
                        Previous
                      </button>
                      <button
                        type="button"
                        onClick={handleNext}
                        disabled={currentSlideIndex === slides.length - 1 || isTransitioning}
                        className={`px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none ${
                          currentSlideIndex === slides.length - 1 || isTransitioning
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                        }`}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* New Text Field */}
              <div className="w-full p-4 bg-white border rounded-md">
                <textarea
                  value={currentSlide?.subtitle || ''}
                  onChange={(e) => {
                    const updatedSlides = [...slides];
                    updatedSlides[currentSlideIndex] = {
                      ...currentSlide,
                      subtitle: e.target.value
                    };
                    setSlides(updatedSlides);
                  }}
                  placeholder="Enter slide text here..."
                  className="w-full p-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="3"
                />
              </div>

              {/* **Table** */}
              <div className="flex justify-end mt-8 mb-2">
                <button
                  onClick={() => setShowMatrix(!showMatrix)}
                  className="px-3 py-1 text-sm text-gray-700 border rounded-md hover:bg-gray-100"
                >
                  {showMatrix ? 'Hide Matrix' : 'Show Matrix'}
                </button>
              </div>

              {showMatrix && (
                <div className="overflow-x-auto">
                  <table className="min-w-full border border-collapse border-gray-300">
                    <thead>
                      <tr>
                        <th className="p-2 border border-gray-300">Location</th>
                        {[...Array(10)].map((_, i) => (
                          <th key={i} className="p-2 border border-gray-300">
                            {i + 1}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(locationTextMatrix).map(([location, texts]) => (
                        <tr key={location}>
                          <td className="p-2 font-medium border border-gray-300">{location}</td>
                          {texts.map((text, i) => (
                            <td key={i} className="p-2 border border-gray-300">
                              {text}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SlideCreator.propTypes = {
  onClose: PropTypes.func.isRequired,
  onPopSlide: PropTypes.func,
};

export default SlideCreator;
