import React, { useRef, useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import PropTypes from 'prop-types';

export const SpaceshipControls = ({ speed = 50, onUpdatePositions, planetLabels, guidanceSystem }) => {
  const { camera } = useThree();
  const moveState = useRef({
    forward: false,
    backward: false,
    left: false,
    right: false,
    up: false,
    down: false,
  });
  
  // Add new state for mouse control
  const mouseState = useRef({
    isLocked: false,
    sensitivity: 0.002,
    enabled: true  // New state to track if mouse control is enabled
  });

  // Add velocity state
  const velocity = useRef(new THREE.Vector3());
  const acceleration = 100000; // Units per second²
  const maxSpeed = speed * 90000000; // Maximum speed limit

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.code) {
        case 'KeyW': moveState.current.forward = true; break;
        case 'KeyS': moveState.current.backward = true; break;
        case 'KeyA': moveState.current.left = true; break;
        case 'KeyD': moveState.current.right = true; break;
        case 'Space': moveState.current.up = true; break;
        case 'ShiftLeft': moveState.current.down = true; break;
        case 'Tab': 
          event.preventDefault(); // Prevent default Tab behavior
          mouseState.current.enabled = !mouseState.current.enabled;
          if (!mouseState.current.enabled && document.pointerLockElement) {
            document.exitPointerLock();
          } else if (mouseState.current.enabled) {
            document.body.requestPointerLock();
          }
          break;
        default: break;
      }
    };

    const handleKeyUp = (event) => {
      switch (event.code) {
        case 'KeyW': moveState.current.forward = false; break;
        case 'KeyS': moveState.current.backward = false; break;
        case 'KeyA': moveState.current.left = false; break;
        case 'KeyD': moveState.current.right = false; break;
        case 'Space': moveState.current.up = false; break;
        case 'ShiftLeft': moveState.current.down = false; break;
        default: break;
      }
    };

    const handleMouseMove = (event) => {
      if (mouseState.current.isLocked) {
        // Rotate camera based on mouse movement
        const euler = new THREE.Euler(0, 0, 0, 'YXZ');
        euler.setFromQuaternion(camera.quaternion);

        // Adjust rotation based on mouse movement
        euler.y -= event.movementX * mouseState.current.sensitivity;
        euler.x -= event.movementY * mouseState.current.sensitivity;

        // Clamp vertical rotation to prevent over-rotation
        euler.x = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, euler.x));

        camera.quaternion.setFromEuler(euler);
      }
    };

    const handlePointerLockChange = () => {
      mouseState.current.isLocked = document.pointerLockElement === document.body;
    };

    const handleClick = () => {
      if (!mouseState.current.isLocked && mouseState.current.enabled) {
        document.body.requestPointerLock();
      }
    };

    // Add event listeners
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('pointerlockchange', handlePointerLockChange);
    document.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('pointerlockchange', handlePointerLockChange);
      document.removeEventListener('click', handleClick);
    };
  }, [camera]);

  useFrame((state, delta) => {
    const moveSpeed = acceleration * delta;
    const movement = new THREE.Vector3();
    const currentVelocityDir = velocity.current.clone().normalize();
    const decelerationRate = 10;
    const stopThreshold = 1000; // Speed threshold below which we'll completely stop

    // Handle deceleration with S/Down keys
    if (moveState.current.backward || moveState.current.down) {
      // Calculate current speed
      const currentSpeed = velocity.current.length();
      if (currentSpeed > 0) {
        // If speed is very low, just stop completely
        if (currentSpeed < stopThreshold) {
          velocity.current.set(0, 0, 0);
        } else {
          // Apply deceleration in the opposite direction of current velocity
          const deceleration = currentVelocityDir.multiplyScalar(moveSpeed * decelerationRate);
          velocity.current.sub(deceleration);
        }
      }
    } else {
      // Normal acceleration for other movements
      if (moveState.current.forward) movement.z -= moveSpeed;
      if (moveState.current.left) movement.x -= moveSpeed;
      if (moveState.current.right) movement.x += moveSpeed;
      if (moveState.current.up) movement.y += moveSpeed;

      // Transform movement direction based on camera rotation and add to velocity
      if (movement.length() > 0) {
        movement.applyQuaternion(camera.quaternion);
        velocity.current.add(movement);
      }
    }

    // Limit maximum speed
    if (velocity.current.length() > maxSpeed) {
      velocity.current.normalize().multiplyScalar(maxSpeed);
    }

    // Apply velocity to camera position
    camera.position.add(velocity.current.clone().multiplyScalar(delta));

    // Only calculate and update positions if guidance system is enabled
    if (guidanceSystem && planetLabels) {
      const positions = {};
      const frustum = new THREE.Frustum();
      const projScreenMatrix = new THREE.Matrix4();
      projScreenMatrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
      frustum.setFromProjectionMatrix(projScreenMatrix);

      planetLabels.forEach(planet => {
        const planetPosition = new THREE.Vector3(
          planet.position.x,
          planet.position.y,
          planet.position.z
        );

        // Calculate distance from camera to planet
        const distance = camera.position.distanceTo(planetPosition);

        // Create vector in world space and project to screen space
        const screenVector = planetPosition.clone();
        screenVector.project(camera);
        
        // Convert normalized device coordinates (-1 to +1) to pixels
        const widthHalf = window.innerWidth / 2;
        const heightHalf = window.innerHeight / 2;
        const x = (screenVector.x * widthHalf) + widthHalf;
        const y = -(screenVector.y * heightHalf) + heightHalf;

        // Check if the point is in front of the camera and within frustum
        const isVisible = screenVector.z < 1 && frustum.containsPoint(planetPosition);

        positions[planet.name] = {
          x,
          y,
          distance,
          isVisible,
          worldPosition: planet.position  // Use the original position directly from planetLabels
        };
      });

      // Pass positions up to parent component
      if (typeof onUpdatePositions === 'function') {
        onUpdatePositions(positions);
      }
    } else if (!guidanceSystem && typeof onUpdatePositions === 'function') {
      // Clear positions when guidance system is off
      onUpdatePositions({});
    }
  });

  return null;
};

SpaceshipControls.propTypes = {
  speed: PropTypes.number,
  onUpdatePositions: PropTypes.func,
  planetLabels: PropTypes.array,
  guidanceSystem: PropTypes.bool,
};
