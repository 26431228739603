// src/components/Teachers/Slideshow/SlideCreator.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from './Button'; // Adjust the path based on your project structure
import PixiCanvas from '../../../pixi/ThreeJS'; // Adjust the path based on your project structure

// Reusable Dial Component
const Dial = ({ label, value, onChange, min, max, step }) => {
  return (
    <div className="flex items-center space-x-2">
      <span className="w-20 text-sm text-gray-700">{label}</span>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        step={step}
        className="flex-1"
      />
      <span className="w-10 text-sm text-gray-700">{value.toFixed(2)}</span>
    </div>
  );
};

// Add this new Slider component near the top
const Slider = ({ label, value, onChange, min = 0, max = 10, step = 0.5 }) => (
  <div className="flex items-center mb-1">
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={onChange}
      className="w-24"
    />
    <span className="ml-1 text-[10px]">{label}: {value}</span>
  </div>
);

const SlideCreator = ({ onClose, onPopSlide }) => {
  // Replace the static genres array with a dynamic one based on slides
  const genres = [
    'Blank',
    'Office',
    'Library',
    'Park',
    'Classroom',
    'Street',
    'Desk',
    'Living Room',
    'Solar System',
  ];

  // Placeholder subtitles with empty strings
  const placeholderSubtitles = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ];

  // Add this object for location coordinates near the top of the component
  const locationCoordinates = {
    Custom: { x: 0, y: 150, z: -1000 },
    Space: { x: 0, y: 3500, z: -1000 },
    Atmosphere: { x: 0, y: 1400, z: -600 },
    Sky: { x: 0, y: 550, z: -1000 },
    OuterRange: { x: 0, y: 15, z: 600 },
    MidRange: { x: 0, y: 15, z: 200 },
    Near: { x: 90000, y: -91000, z: -500000 },
    Ground: { x: 0, y: 15, z: -300 },
    UnderGround: { x: 0, y: -500, z: -300 },
    SolarSystem: { x: 0, y: 0, z: 790000000 },
    Sun: { x: 90000000, y: 90000000, z: 790000000 },
    Mercury: { x: 60000000, y: 60000000, z: 590000000 },
    Venus: { x: 30000000, y: 30000000, z: 290000000 },
    Earth: { x: 90000, y: -91000, z: 0 },
    Mars: { x: -30000000, y: -30000000, z: -310000000 },
    Jupiter: { x: -60000000, y: -60000000, z: -610000000 },
    Saturn: { x: -90000000, y: -90000000, z: -910000000 },
    Uranus: { x: -120000000, y: -120000000, z: -1210000000 },
    Neptune: { x: -150000000, y: -150000000, z: -1510000000 },
    
  };

  // Add this after your locationCoordinates object
  const planetLabels = [
    { name: 'Sun', position: { x: 90000000, y: 90000000, z: 790000000 }, color: '#FFD700' },
    { name: 'Mercury', position: { x: 60000000, y: 60000000, z: 590000000 } },
    { name: 'Venus', position: { x: 30000000, y: 30000000, z: 290000000 } },
    { name: 'Earth', position: { x: 90000, y: -91000, z: 0 } },
    { name: 'Mars', position: { x: -30000000, y: -30000000, z: -310000000 } },
    { name: 'Jupiter', position: { x: -60000000, y: -60000000, z: -610000000 } },
    { name: 'Saturn', position: { x: -90000000, y: -90000000, z: -910000000 } },
    { name: 'Uranus', position: { x: -120000000, y: -120000000, z: -1210000000 } },
    { name: 'Neptune', position: { x: -150000000, y: -150000000, z: -1510000000 } }
  ];

  // Convert locationTextMatrix to state and initialize it
  const [locationTextMatrix, setLocationTextMatrix] = useState({
    Space: ['', '', '', '', '', '', '', '', '', ''],
    Atmosphere: ['', '', '', '', '', '', '', '', '', ''],
    Sky: ['', '', '', '', '', '', '', '', '', ''],
    OuterRange: ['', '', '', '', '', '', '', '', '', ''],
    MidRange: ['', '', '', '', '', '', '', '', '', ''],
    Near: ['', '', '', '', '', '', '', '', '', ''],
    Ground: ['', '', '', '', '', '', '', '', '', ''],
    UnderGround: ['', '', '', '', '', '', '', '', '', ''],
    SolarSystem: ['', '', '', '', '', '', '', '', '', ''],
    Sun: ['', '', '', '', '', '', '', '', '', ''],
    Mercury: ['', '', '', '', '', '', '', '', '', ''],
    Venus: ['', '', '', '', '', '', '', '', '', ''],
    Earth: ['', '', '', '', '', '', '', '', '', ''],
    Mars: ['', '', '', '', '', '', '', '', '', ''],
    Jupiter: ['', '', '', '', '', '', '', '', '', ''],
    Saturn: ['', '', '', '', '', '', '', '', '', ''],
    Uranus: ['', '', '', '', '', '', '', '', '', ''],
    Neptune: ['', '', '', '', '', '', '', '', '', '']
  });

  // Add this helper function to update the matrix
  const updateLocationMatrix = (updatedSlides) => {
    const newMatrix = { ...locationTextMatrix };
    
    // Reset all values to empty strings instead of "Blank"
    Object.keys(newMatrix).forEach(location => {
      newMatrix[location] = Array(10).fill('');
    });
    
    // Update matrix based on slides
    updatedSlides.forEach(slide => {
      if (slide.location && slide.themeNumber && slide.themeNumber <= 10) {
        // Ensure we're accessing a valid index (subtract 1 from themeNumber)
        const index = Math.min(Math.max(0, slide.themeNumber - 1), 9);
        newMatrix[slide.location][index] = slide.theme || '';
      }
    });
    
    setLocationTextMatrix(newMatrix);
  };

  // Modified function to generate initial slides with themes and proper numbering
  const generateInitialSlides = () => {
    let currentTheme = null;
    let themeNumber = 1;
    let sequenceNumber = 1;

    return genres.map((genre, index) => {
      if (currentTheme !== genre) {
        if (currentTheme !== null) {
          themeNumber++;
        }
        currentTheme = genre;
        sequenceNumber = 1;
      } else {
        sequenceNumber++;
      }

      return {
        id: Date.now() + index,
        title: `Near ${themeNumber}.${sequenceNumber} ${genre}`,
        subtitle: "", // Initialize with empty subtitle
        theme: genre,
        themeNumber: themeNumber,
        sequenceNumber: sequenceNumber,
        x: 0,
        y: 0,
        z: 0,
        location: 'Near'
      };
    });
  };

  // Initialize slides with genres
  const [slides, setSlides] = useState(generateInitialSlides());

  // State to track the current slide index
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // State to track navigation direction for animation
  const [navigationDirection, setNavigationDirection] = useState(0); // 1 for next, -1 for previous, 0 for direct navigation

  // New state to track if a transition is in progress
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Add this new state near the top of the component with other state declarations
  const [showCoordinates, setShowCoordinates] = useState(false);

  // Make "show/hide matrix" hidden by default
  const [showMatrix, setShowMatrix] = useState(false);

  // Add this new state near other state declarations
  const [showText, setShowText] = useState(true);

  // Add new state for HUD controls
  const [speed, setSpeed] = useState(50);
  const [orbitLock, setOrbitLock] = useState(false);
  const [guidanceSystem, setGuidanceSystem] = useState(false);
  const [timelapse, setTimelapse] = useState(0);

  // Add new state for View Lock
  const [viewLock, setViewLock] = useState(false);

  // Add new state for selected celestial body
  const [selectedCelestialBody, setSelectedCelestialBody] = useState('Sun');

  // Add new state for showing moons
  const [showMoons, setShowMoons] = useState(false);

  // Add new state for selected moon
  const [selectedMoon, setSelectedMoon] = useState('');

  // Add ref for scene initialization status
  const [sceneInitialized, setSceneInitialized] = useState(false);

  // Add new state for the flight sim controls
  const [gravity, setGravity] = useState(true);
  const [gravityAssist, setGravityAssist] = useState(false);
  const [land, setLand] = useState(false);

  // Add new state for zoom control near other state declarations
  const [zoom, setZoom] = useState(10);

  const currentSlide = slides[currentSlideIndex];

  // Function to handle animation completion
  const handleAnimationComplete = () => {
    setIsTransitioning(false);
  };

  // Handlers for navigating slides
  const handleNext = () => {
    if (currentSlideIndex >= slides.length - 1) return;
    setShowText(false);
    
    // Wait for text to disappear before changing slide
    setTimeout(() => {
      setNavigationDirection(1);
      setCurrentSlideIndex(currentSlideIndex + 1);
      
      // Wait for animation and then show text
      setTimeout(() => setShowText(true), 3000);
    }, 500);
  };

  const handlePrevious = () => {
    if (currentSlideIndex === 0) return;
    setShowText(false);
    
    // Wait for text to disappear before changing slide
    setTimeout(() => {
      setNavigationDirection(-1);
      setCurrentSlideIndex(currentSlideIndex - 1);
      
      // Wait for animation and then show text
      setTimeout(() => setShowText(true), 3000);
    }, 500);
  };

  // Handler for clicking on a slide preview
  const handleSlideClick = (index) => {
    if (index === currentSlideIndex) return;
    setShowText(false);
    
    // Wait for text to disappear before changing slide
    setTimeout(() => {
      setNavigationDirection(index > currentSlideIndex ? 1 : -1);
      setCurrentSlideIndex(index);
      
      // Wait for animation and then show text
      setTimeout(() => setShowText(true), 3000);
    }, 500);
  };

  // Add this handler near other handlers
  const handleThemeChange = (e) => {
    const newTheme = e.target.value;
    
    const updatedSlides = [...slides];
    updatedSlides[currentSlideIndex] = {
      ...slides[currentSlideIndex],
      theme: newTheme
    };

    let currentTheme = null;
    let themeNumber = 1;
    let sequenceNumber = 1;

    const finalSlides = updatedSlides.map((slide, index) => {
      if (currentTheme !== slide.theme) {
        if (currentTheme !== null) {
          themeNumber++;
        }
        currentTheme = slide.theme;
        sequenceNumber = 1;
      } else {
        sequenceNumber++;
      }

      return {
        ...slide,
        title: `${slide.location || 'Field'} ${themeNumber}.${sequenceNumber} ${slide.theme}`,
        themeNumber: themeNumber,
        sequenceNumber: sequenceNumber
      };
    });

    setSlides(finalSlides);
    updateLocationMatrix(finalSlides);
  };

  // Add new handler for adding slides
  const handleAddSlide = () => {
    const newSlide = {
      id: Date.now(),
      theme: 'Scene1',
      subtitle: "", // Initialize with empty subtitle
      x: 0,
      y: 0,
      z: 0,
      location: 'Field',
      themeNumber: 1,
      sequenceNumber: 1
    };

    const updatedSlides = [...slides, newSlide];
    
    // Recalculate numbers for all slides
    let currentTheme = null;
    let themeNumber = 1;
    let sequenceNumber = 1;

    const finalSlides = updatedSlides.map((slide) => {
      if (currentTheme !== slide.theme) {
        if (currentTheme !== null) {
          themeNumber++;
        }
        currentTheme = slide.theme;
        sequenceNumber = 1;
      } else {
        sequenceNumber++;
      }

      return {
        ...slide,
        title: `${slide.theme} ${themeNumber}.${sequenceNumber}`,
        themeNumber: themeNumber,
        sequenceNumber: sequenceNumber
      };
    });

    setSlides(finalSlides);
    setCurrentSlideIndex(finalSlides.length - 1); // Select the new slide
  };

  // Add handler for deleting slides
  const handleDeleteSlide = (index, e) => {
    e.stopPropagation(); // Prevent slide selection when clicking delete
    
    const updatedSlides = slides.filter((_, i) => i !== index);
    
    // Recalculate numbers for remaining slides
    let currentTheme = null;
    let themeNumber = 1;
    let sequenceNumber = 1;

    const finalSlides = updatedSlides.map((slide) => {
      if (currentTheme !== slide.theme) {
        if (currentTheme !== null) {
          themeNumber++;
        }
        currentTheme = slide.theme;
        sequenceNumber = 1;
      } else {
        sequenceNumber++;
      }

      return {
        ...slide,
        title: `${slide.theme} ${themeNumber}.${sequenceNumber}`,
        themeNumber: themeNumber,
        sequenceNumber: sequenceNumber
      };
    });

    setSlides(finalSlides);
    // Update the matrix with the remaining slides
    updateLocationMatrix(finalSlides);
    
    // Adjust current slide index if necessary
    if (index <= currentSlideIndex) {
      setCurrentSlideIndex(Math.max(0, currentSlideIndex - 1));
    }
  };

  // Add function to get highest theme number
  const getHighestThemeNumber = () => {
    if (!slides.length) return 1;
    return Math.max(...slides.map(slide => slide.themeNumber));
  };

  // Add coordinate change handler
  const handleCoordinateChange = (coord, value) => {
    const updatedSlides = [...slides];
    updatedSlides[currentSlideIndex] = {
      ...slides[currentSlideIndex],
      [coord]: Number(value)
    };
    setSlides(updatedSlides);
  };

  // Add this handler near other handlers
  const handleLocationChange = (e) => {
    const location = e.target.value;
    const coordinates = locationCoordinates[location];
    
    if (coordinates) {
      const updatedSlides = [...slides];
      updatedSlides[currentSlideIndex] = {
        ...slides[currentSlideIndex],
        x: coordinates.x,
        y: coordinates.y,
        z: coordinates.z,
        location: location,
        title: `${location} ${currentSlide.themeNumber}.${currentSlide.sequenceNumber} ${currentSlide.theme}`
      };
      setSlides(updatedSlides);
      updateLocationMatrix(updatedSlides);
    }
  };

  // Add these helper functions
  const hasMoons = (planet) => {
    const moonsMap = {
      Earth: ['Moon'],
      Mars: ['Phobos', 'Deimos'],
      Jupiter: ['Io', 'Europa', 'Ganymede', 'Callisto'],
      Saturn: ['Titan', 'Enceladus', 'Mimas', 'Rhea'],
      Uranus: ['Miranda', 'Ariel', 'Umbriel', 'Titania', 'Oberon'],
      Neptune: ['Triton', 'Nereid', 'Naiad']
    };
    return !!moonsMap[planet];
  };

  const getMoonsForPlanet = (planet) => {
    const moonsMap = {
      Earth: ['Moon'],
      Mars: ['Phobos', 'Deimos'],
      Jupiter: ['Io', 'Europa', 'Ganymede', 'Callisto'],
      Saturn: ['Titan', 'Enceladus', 'Mimas', 'Rhea'],
      Uranus: ['Miranda', 'Ariel', 'Umbriel', 'Titania', 'Oberon'],
      Neptune: ['Triton', 'Nereid', 'Naiad']
    };
    return moonsMap[planet] || [];
  };

  // Add this useEffect to handle key events
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Prevent default behavior for WASD and Space keys
      if (['w', 'a', 's', 'd', ' '].includes(e.key.toLowerCase())) {
        e.preventDefault();
      }

      // Handle speed adjustments
      if (e.key === '-' || e.key === '_') {
        setSpeed(prevSpeed => Math.max(0, prevSpeed - 5));
      }
      if (e.key === '+' || e.key === '=') {
        setSpeed(prevSpeed => Math.min(100, prevSpeed + 5));
      }
    };

    // Add the event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array since we don't need any dependencies

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-60">
      {/* Fullscreen Modal Container */}
      <div className="flex flex-col w-full h-full bg-white rounded-none shadow-none md:flex-row">
        
        {/* **Left Panel (Genres List)** */}
        <div className="flex flex-col w-full p-4 overflow-y-auto border-r md:w-1/5">
          <div className="flex items-center justify-between mb-4">
            <h4 className="text-lg font-medium">Genres</h4>
            <button
              onClick={handleAddSlide}
              className="p-1 text-xl font-bold text-blue-500 hover:text-blue-700"
              title="Add new slide"
            >
              +
            </button>
          </div>
          <ul className="flex-1 space-y-4">
            {slides.map((slide, index) => (
              <li
                key={slide.id}
                onClick={() => handleSlideClick(index)}
                className={`cursor-pointer rounded border w-48 relative ${
                  currentSlideIndex === index
                    ? 'border-blue-500 bg-blue-50'
                    : 'border-gray-200 hover:bg-gray-100'
                }`}
              >
                <div className="flex items-center p-2">
                  <span className="flex-shrink-0 w-6 text-sm font-semibold text-center text-gray-700">
                    {index + 1}
                  </span>

                  <div className="w-32 mx-2">
                    <div className="flex items-center justify-center w-full overflow-hidden rounded aspect-video bg-gray-50">
                      <p className="px-2 text-xs text-center text-gray-700">
                        {slide.title.length > 20
                          ? `${slide.title.substring(0, 20)}...`
                          : slide.title}
                      </p>
                    </div>
                  </div>
                  
                  <button
                    onClick={(e) => handleDeleteSlide(index, e)}
                    className="absolute text-red-500 right-2 top-2 hover:text-red-700"
                    title="Delete slide"
                  >
                    ×
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* **Right Panel (Slide Editor with PixiCanvas)** */}
        <div className="flex flex-col w-full p-6 overflow-y-auto md:w-4/5">
          {/* **Header with Title and Close Button** */}
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-4">
              <h3 className="text-xl font-semibold">
                {currentSlide
                  ? `Slide - ${currentSlide.title}`
                  : 'Select a Genre'}
              </h3>
              {/* Location Dropdown Boxes - Now First */}
              <select 
                className="p-2 text-sm text-gray-700 border rounded-md"
                onChange={handleLocationChange}
                value={currentSlide?.location || 'Field'}
              >
                {Object.keys(locationCoordinates).map(location => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </select>
              {/* Theme Dropdown Boxes - Now Second */}
              <select 
                className="p-2 text-sm text-gray-700 border rounded-md"
                value={currentSlide?.theme}
                onChange={handleThemeChange}
              >
                <option value="Blank">Blank</option>
                <option value="Office">Office</option>
                <option value="Library">Library</option>
                <option value="Park">Park</option>
                <option value="Classroom">Classroom</option>
                <option value="Street">Street</option>
                <option value="Desk">Desk</option>
                <option value="Living Room">Living Room</option>
                <option value="Solar System">Solar System</option>
                <option value="Human Body">Human Body</option>
                <option value="Forest">Forest</option>
                <option value="Ocean">Ocean</option>
                <option value="Museum">Museum</option>
              </select>
            </div>
            <Button
              onClick={onClose}
              variant="ghost"
              className="ml-4 text-2xl leading-none"
              aria-label="Close Slide Creator"
              disabled={isTransitioning}
            >
              &times;
            </Button>
          </div>

          {currentSlide && (
            <div className="flex flex-col justify-between flex-1 space-y-4">
              {/* **PixiCanvas Integration** */}
              <div className="flex flex-col items-center justify-center p-4 overflow-hidden bg-gray-100 border rounded-md">
                <div className="relative w-full" style={{ paddingTop: '56.25%' }}> {/* 16:9 aspect ratio */}
                  <div className="absolute inset-0">
                    {/* Only render PixiCanvas when scene is ready to be initialized */}
                    {currentSlide && (
                      <PixiCanvas
                        key={`canvas-${currentSlideIndex}`}
                        width={1600}
                        height={900}
                        backgroundColor={0x1099bb}
                        theme={currentSlide.theme}
                        currentSlide={currentSlideIndex + 1}
                        totalSlides={slides.length}
                        speed={speed}
                        orbitLock={orbitLock}
                        guidanceSystem={guidanceSystem}
                        timelapse={timelapse}
                        viewLock={viewLock}
                        selectedCelestialBody={selectedCelestialBody}
                        selectedMoon={selectedMoon}
                        planetLabels={planetLabels}
                        onSceneReady={() => setSceneInitialized(true)}
                        zoom={zoom}
                      />
                    )}
                    
                    {/* **Conditional Text Overlay** */}
                    {currentSlide.subtitle && showText && (
                      <div 
                        className={`absolute w-4/5 max-w-2xl transform -translate-x-1/2 bottom-20 left-1/2 transition-opacity duration-500 ${
                          showText ? 'opacity-100' : 'opacity-0'
                        }`}
                      >
                        <div 
                          className="p-4 bg-white rounded-lg shadow-lg bg-opacity-90 backdrop-blur-sm"
                        >
                          <p className="text-lg font-light leading-relaxed text-center text-gray-700">
                            {currentSlide.subtitle}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* Replace the character controls div with this new HUD controls */}
                    <div className="absolute z-50 w-64 bottom-4 right-4">
                      <div className="p-4 font-mono text-green-400 bg-black rounded-lg bg-opacity-40 backdrop-blur-sm">
                        {/* Speed Dial */}
                        <div className="mb-4">
                          <div className="flex items-center justify-between">
                            <span className="text-xs">SPEED</span>
                            <span className="text-lg">{speed.toFixed(0)}</span>
                          </div>
                          <input
                            type="range"
                            min="0"
                            max="100"
                            value={speed}
                            onChange={(e) => setSpeed(parseFloat(e.target.value))}
                            className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                          />
                        </div>

                        {/* Control Buttons */}
                        <div className="grid grid-cols-3 gap-2 mb-4">
                          {/* Orbit Lock Button */}
                          <button
                            onClick={() => setOrbitLock(!orbitLock)}
                            className={`px-3 py-1 border rounded ${
                              orbitLock 
                                ? 'border-green-400 bg-green-400 bg-opacity-20' 
                                : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                            }`}
                          >
                            <div className="text-xs">ORBIT LOCK</div>
                            <div className="text-sm font-bold">{orbitLock ? 'ON' : 'OFF'}</div>
                          </button>

                          {/* View Lock Button */}
                          <button
                            onClick={() => setViewLock(!viewLock)}
                            className={`px-3 py-1 border rounded ${
                              viewLock 
                                ? 'border-green-400 bg-green-400 bg-opacity-20' 
                                : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                            }`}
                          >
                            <div className="text-xs">VIEW LOCK</div>
                            <div className="text-sm font-bold">{viewLock ? 'ON' : 'OFF'}</div>
                          </button>

                          {/* Guidance System Button */}
                          <button
                            onClick={() => setGuidanceSystem(!guidanceSystem)}
                            className={`px-3 py-1 border rounded ${
                              guidanceSystem 
                                ? 'border-green-400 bg-green-400 bg-opacity-20' 
                                : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                            }`}
                          >
                            <div className="text-xs">GUIDANCE</div>
                            <div className="text-sm font-bold">{guidanceSystem ? 'ON' : 'OFF'}</div>
                          </button>

                          {/* Gravity Button */}
                          <button
                            onClick={() => setGravity(!gravity)}
                            className={`px-3 py-1 border rounded ${
                              gravity 
                                ? 'border-green-400 bg-green-400 bg-opacity-20' 
                                : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                            }`}
                          >
                            <div className="text-xs">GRAVITY</div>
                            <div className="text-sm font-bold">{gravity ? 'ON' : 'OFF'}</div>
                          </button>

                          {/* Gravity Assist Button */}
                          <button
                            onClick={() => setGravityAssist(!gravityAssist)}
                            className={`px-3 py-1 border rounded ${
                              gravityAssist 
                                ? 'border-green-400 bg-green-400 bg-opacity-20' 
                                : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                            }`}
                          >
                            <div className="text-xs">GRAVITY ASSIST</div>
                            <div className="text-sm font-bold">{gravityAssist ? 'ON' : 'OFF'}</div>
                          </button>

                          {/* Land Button */}
                          <button
                            onClick={() => setLand(!land)}
                            className={`px-3 py-1 border rounded ${
                              land 
                                ? 'border-green-400 bg-green-400 bg-opacity-20' 
                                : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                            }`}
                          >
                            <div className="text-xs">LAND</div>
                            <div className="text-sm font-bold">{land ? 'ON' : 'OFF'}</div>
                          </button>
                        </div>

                        {/* Planet Selection Buttons */}
                        <div className="mb-4">
                          <div className="flex flex-col gap-1">
                            <button
                              onClick={() => {
                                setSelectedCelestialBody('Sun');
                                setShowMoons(false);
                                setSelectedMoon('');
                              }}
                              className={`px-2 py-1 text-xs border rounded w-20 ${
                                selectedCelestialBody === 'Sun'
                                  ? 'border-yellow-400 bg-yellow-400 bg-opacity-20' 
                                  : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                              }`}
                            >
                              Sun
                            </button>
                            {['Mercury', 'Venus', 'Earth', 'Mars', 'Jupiter', 'Saturn', 'Uranus', 'Neptune'].map((planet) => (
                              <div key={planet} className="flex items-center space-x-1">
                                <button
                                  onClick={() => {
                                    setSelectedCelestialBody(planet);
                                    setShowMoons(hasMoons(planet));
                                    setSelectedMoon('');
                                  }}
                                  className={`px-2 py-1 text-xs border rounded w-20 ${
                                    selectedCelestialBody === planet
                                      ? 'border-green-400 bg-green-400 bg-opacity-20' 
                                      : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                                  }`}
                                >
                                  {planet}
                                </button>
                                {selectedCelestialBody === planet && hasMoons(planet) && 
                                  getMoonsForPlanet(planet).map((moon) => (
                                    <button
                                      key={moon}
                                      onClick={() => setSelectedMoon(moon)}
                                      className={`px-1.5 py-0.5 text-[10px] border rounded ${
                                        selectedMoon === moon
                                          ? 'border-orange-400 bg-orange-400 bg-opacity-20' 
                                          : 'border-orange-400 hover:bg-orange-400 hover:bg-opacity-20'
                                      }`}
                                    >
                                      {moon}
                                    </button>
                                  ))
                                }
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Timelapse Dial */}
                        <div className="relative">
                          <div className="flex items-center justify-between">
                            <span className="text-xs">TIMELAPSE</span>
                            <span className="text-lg">{timelapse > 0 ? '+' : ''}{timelapse.toFixed(0)}</span>
                          </div>
                          <input
                            type="range"
                            min="-100"
                            max="100"
                            value={timelapse}
                            onChange={(e) => setTimelapse(parseFloat(e.target.value))}
                            className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                          />
                        </div>

                        {/* New Zoom Dial */}
                        <div className="relative mt-4">
                          <div className="flex items-center justify-between">
                            <span className="text-xs">ZOOM</span>
                            <span className="text-lg">{zoom.toFixed(1)}</span>
                          </div>
                          <input
                            type="range"
                            min="0"
                            max="20"
                            step="0.1"
                            value={zoom}
                            onChange={(e) => setZoom(parseFloat(e.target.value))}
                            className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Navigation Buttons */}
                    <div className="absolute left-0 right-0 flex justify-center space-x-4 bottom-4">
                      <button
                        type="button"
                        onClick={handlePrevious}
                        disabled={currentSlideIndex === 0 || isTransitioning}
                        className={`px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none ${
                          currentSlideIndex === 0 || isTransitioning
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                        }`}
                      >
                        Previous
                      </button>
                      <button
                        type="button"
                        onClick={handleNext}
                        disabled={currentSlideIndex === slides.length - 1 || isTransitioning}
                        className={`px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none ${
                          currentSlideIndex === slides.length - 1 || isTransitioning
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                        }`}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* New Text Field */}
              <div className="w-full p-4 bg-white border rounded-md">
                <textarea
                  value={currentSlide?.subtitle || ''}
                  onChange={(e) => {
                    const updatedSlides = [...slides];
                    updatedSlides[currentSlideIndex] = {
                      ...currentSlide,
                      subtitle: e.target.value
                    };
                    setSlides(updatedSlides);
                  }}
                  placeholder="Enter slide text here..."
                  className="w-full p-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="3"
                />
              </div>

              {/* **Table** */}
              <div className="flex justify-end mt-8 mb-2">
                <button
                  onClick={() => setShowMatrix(!showMatrix)}
                  className="px-3 py-1 text-sm text-gray-700 border rounded-md hover:bg-gray-100"
                >
                  {showMatrix ? 'Hide Matrix' : 'Show Matrix'}
                </button>
              </div>

              {showMatrix && (
                <div className="overflow-x-auto">
                  <table className="min-w-full border border-collapse border-gray-300">
                    <thead>
                      <tr>
                        <th className="p-2 border border-gray-300">Location</th>
                        {[...Array(10)].map((_, i) => (
                          <th key={i} className="p-2 border border-gray-300">
                            {i + 1}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(locationTextMatrix).map(([location, texts]) => (
                        <tr key={location}>
                          <td className="p-2 font-medium border border-gray-300">{location}</td>
                          {texts.map((text, i) => (
                            <td key={i} className="p-2 border border-gray-300">
                              {text}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SlideCreator.propTypes = {
  onClose: PropTypes.func.isRequired,
  onPopSlide: PropTypes.func,
};

export default SlideCreator;
