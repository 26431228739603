// src/components/Management/Sidebar.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { LogOut, Lock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { useAuth } from '../../../contexts/AuthContext';
import { auth } from '../../../firebase';
import { useDataController, MODULE_STATES } from '../../../contexts/datacontroller';
import { ChevronLeft, ChevronRight } from 'lucide-react'; // Ensure Chevron icons are imported

const Sidebar = ({
  isOpen,
  toggleSidebar,
  username,
  handleStartApp,
  onManageClasses,
  classes,
  setClasses,
}) => {
  const navigate = useNavigate();
  const { currentUser, userRole } = useAuth();
  const [isUpdating, setIsUpdating] = useState(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [showClassPopup, setShowClassPopup] = useState(false);
  const [error, setError] = useState(null);

  // Refs for accessibility
  const logoutConfirmRef = useRef(null);
  const classPopupRef = useRef(null);

  const {
    activeClasses,
    activateClass,
    deactivateClass,
    isClassActive,
    setModuleStatus,
    toggleLockModule,
    isLoading,
  } = useDataController();

  // Handle user logout
  const handleLogout = async () => {
    try {
      setIsUpdating(true);
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
      alert('Failed to logout properly.');
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle focus trapping and escape key for popups
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        if (showLogoutConfirm) setShowLogoutConfirm(false);
        if (showClassPopup) setShowClassPopup(false);
      }
    };

    if (showLogoutConfirm || showClassPopup) {
      document.addEventListener('keydown', handleKeyDown);
      // Focus the first button in the popup
      if (showLogoutConfirm && logoutConfirmRef.current) {
        logoutConfirmRef.current.focus();
      }
      if (showClassPopup && classPopupRef.current) {
        classPopupRef.current.focus();
      }
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showLogoutConfirm, showClassPopup]);

  // Handle class button click
  const handleClassButtonClick = useCallback(
    (cls) => {
      if (userRole !== 'teacher') {
        alert('Only teachers can activate or deactivate classes.');
        return;
      }
      setSelectedClass(cls);
      setShowClassPopup(true);
    },
    [userRole]
  );

  // Handle confirmation from class popup
  const handleClassConfirmation = async (confirm) => {
    if (selectedClass) {
      const { id, name } = selectedClass;
      const isActive = isClassActive(id);

      if (confirm) {
        try {
          setIsUpdating(true);
          if (!isActive) {
            console.log('Activating class with data:', {
              id,
              name,
              teacherID: currentUser.uid,
              activatedAt: new Date().toISOString(),
            });
            
            await activateClass(id, {
              name,
              teacherID: currentUser.uid,
              activatedAt: new Date().toISOString(),
              activatedBy: currentUser.uid,
              MoviePicker: {
                movies: {}
              }
            });
          } else {
            await deactivateClass(id);
          }
        } catch (error) {
          console.error('Error updating active classes:', error);
          alert('Failed to update class status.');
        } finally {
          setIsUpdating(false);
        }
      }
    }
    setShowClassPopup(false);
    setSelectedClass(null);
  };

  // Define module names and their corresponding RTDB keys
  const modules = [
    { name: 'White-board', key: 'whiteboardactive' },
    { name: 'Slides', key: 'slidesactive' },
    { name: 'Character', key: 'characteractive' },
    { name: 'Sites', key: 'sitesactive' },
    { name: 'Tests', key: 'testsactive' },
    { name: 'Games', key: 'gamesactive' },
    { name: 'Journal', key: 'journalactive' },
  ];

  // Determine the current class (for simplicity, pick the first active class)
  const activeClassIds = Object.keys(activeClasses);
  const currentClassId = activeClassIds.length > 0 ? activeClassIds[0] : null;
  const currentClassData = currentClassId ? activeClasses[currentClassId] : null;

  // Handle Module Button Click
  const handleModuleToggle = async (moduleKey) => {
    if (!currentClassId) {
      alert('No active class selected.');
      return;
    }

    const currentStatus = currentClassData.modulestatus[moduleKey] || MODULE_STATES.INACTIVE;

    if (currentStatus === MODULE_STATES.LOCKED) {
      alert('This module is locked and cannot be modified.');
      return;
    }

    const newStatus =
      currentStatus === MODULE_STATES.ACTIVE ? MODULE_STATES.INACTIVE : MODULE_STATES.ACTIVE;

    try {
      setIsUpdating(true);
      await setModuleStatus(currentClassId, moduleKey, newStatus);
    } catch (err) {
      console.error(`Error updating status for ${moduleKey}:`, err);
      alert('Failed to update module status.');
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle Lock Button Click
  const handleLockToggle = async (moduleKey) => {
    if (!currentClassId) {
      alert('No active class selected.');
      return;
    }

    const currentStatus = currentClassData.modulestatus[moduleKey] || MODULE_STATES.INACTIVE;
    const isCurrentlyLocked = currentStatus === MODULE_STATES.LOCKED;

    try {
      setIsUpdating(true);
      if (!isCurrentlyLocked) {
        // Set all other locked modules to ACTIVE instead of unlocking them
        const activatePromises = modules
          .filter(
            (mod) =>
              currentClassData.modulestatus[mod.key] === MODULE_STATES.LOCKED &&
              mod.key !== moduleKey
          )
          .map((mod) => setModuleStatus(currentClassId, mod.key, MODULE_STATES.ACTIVE));

        await Promise.all(activatePromises);
      }

      // Toggle lock on the selected module
      await toggleLockModule(currentClassId, moduleKey);
    } catch (err) {
      console.error(`Error toggling lock for ${moduleKey}:`, err);
      alert('Failed to toggle lock status.');
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <>
      <div
        className={`fixed left-0 top-0 h-full bg-white shadow-lg transition-all duration-500 ease-in-out ${
          isOpen ? 'w-64' : 'w-0'
        } overflow-hidden flex flex-col z-50`}
      >
        <div className="flex-grow p-4 space-y-4 overflow-y-auto">
          {/* Manage Classes Section */}
          <div className="p-3 border rounded">
            <button
              onClick={onManageClasses}
              className="w-full px-3 py-2 mb-4 text-sm text-white bg-blue-500 rounded hover:bg-blue-600"
              disabled={isUpdating || userRole !== 'teacher'}
            >
              Manage Classes
            </button>
          </div>

          {/* Classes Section */}
          {userRole === 'teacher' && (
            <div className="p-3 border rounded">
              <h2 className="mb-2 text-lg font-semibold">Your Classes</h2>
              <div className="flex flex-col space-y-2">
                {classes.map((cls) => (
                  <button
                    key={cls.id}
                    onClick={() => handleClassButtonClick(cls)}
                    className={`w-full px-3 py-2 text-left text-sm rounded ${
                      isClassActive(cls.id) ||
                      (currentClassId === cls.id &&
                        modules.some(
                          (mod) =>
                            currentClassData.modulestatus[mod.key] === MODULE_STATES.LOCKED
                        ))
                        ? 'bg-orange-500 text-white'
                        : 'bg-gray-300 text-gray-800'
                    } hover:bg-opacity-75 transition-colors`}
                    disabled={isUpdating}
                  >
                    {cls.name}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Modules Section */}
          <div className="p-3 border rounded">
            <h2 className="mb-2 text-lg font-semibold">Modules</h2>
            {isLoading ? (
              <p className="text-sm text-gray-500">Loading modules...</p>
            ) : currentClassId ? (
              <div className="flex flex-col space-y-2">
                {modules.map((module) => {
                  const status =
                    currentClassData.modulestatus[module.key] || MODULE_STATES.INACTIVE;
                  const isLocked = status === MODULE_STATES.LOCKED;
                  const isActive = status === MODULE_STATES.ACTIVE;

                  return (
                    <div key={module.key} className="flex items-center justify-between">
                      <button
                        onClick={() => handleModuleToggle(module.key)}
                        className={`flex-1 px-3 py-2 text-left text-sm rounded ${
                          isActive || isLocked
                            ? 'bg-green-500 text-white'
                            : 'bg-gray-300 text-gray-800'
                        } ${isLocked ? 'cursor-not-allowed' : 'hover:bg-opacity-75'} transition-colors`}
                        disabled={isLocked || isUpdating}
                        title={isLocked ? 'Module is locked' : ''}
                      >
                        {module.name}
                      </button>
                      <button
                        onClick={() => handleLockToggle(module.key)}
                        className={`ml-2 p-2 rounded-full focus:outline-none transition-colors ${
                          isLocked ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-800'
                        } hover:bg-opacity-75`}
                        aria-label={`Lock ${module.name} module`}
                        disabled={isUpdating}
                      >
                        <Lock size={16} />
                      </button>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="text-sm text-gray-500">No active class selected.</p>
            )}
            {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
          </div>

          {/* View Data Section */}
          <div className="p-3 border rounded">
            <button
              onClick={() => handleStartApp(null, 'Data')}
              className="w-full px-3 py-2 text-sm text-white transition-colors bg-indigo-500 rounded hover:bg-indigo-600"
              disabled={isUpdating}
            >
              View Data
            </button>
          </div>
        </div>

        {/* Footer Section */}
        <div className="p-4 border-t">
          <div className="mb-4">
            <button
              onClick={() => alert('Settings clicked')}
              className="w-full px-3 py-2 text-sm text-white transition-colors bg-purple-500 rounded hover:bg-purple-600"
              disabled={isUpdating}
            >
              Open Settings
            </button>
          </div>
          <button
            onClick={() => setShowLogoutConfirm(true)}
            className="flex items-center justify-center w-full px-3 py-2 text-sm text-white transition-colors bg-red-500 rounded hover:bg-red-600"
            disabled={isUpdating}
          >
            <LogOut className="mr-2" /> Log Out
          </button>
        </div>
      </div>

      {/* Chevron Toggle Button */}
      <button
        onClick={toggleSidebar}
        className={`fixed top-1/2 left-64 transform -translate-y-1/2 p-1 text-blue-500 transition-all duration-500 bg-white rounded-r-2xl shadow-lg z-50`}
        aria-label={isOpen ? 'Close Sidebar' : 'Open Sidebar'}
        aria-expanded={isOpen}
        style={{
          // Positioning the chevron button based on sidebar state
          left: isOpen ? '240px' : '0px', // 16rem = 64px (Tailwind's w-64)
          zIndex: 1, // Ensure toggle button is below the toolbar
        }}
      >
        {isOpen ? <ChevronLeft size={40} /> : <ChevronRight size={40} />}
      </button>

      {/* Popups */}
      {showLogoutConfirm && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          role="dialog"
          aria-modal="true"
          style={{ zIndex: 1000 }}
        >
          <div className="relative p-6 bg-white rounded-lg shadow-lg w-80">
            <h2 className="mb-4 text-xl font-semibold">
              Are you sure you want to log out?
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowLogoutConfirm(false)}
                className="px-4 py-2 text-gray-800 transition-colors bg-gray-300 rounded hover:bg-gray-400"
                disabled={isUpdating}
                ref={logoutConfirmRef}
              >
                No
              </button>
              <button
                onClick={() => {
                  setShowLogoutConfirm(false);
                  handleLogout();
                }}
                className="px-4 py-2 text-white transition-colors bg-red-500 rounded hover:bg-red-600"
                disabled={isUpdating}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {showClassPopup && selectedClass && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          role="dialog"
          aria-modal="true"
          style={{ zIndex: 1000 }}
        >
          <div className="relative p-6 bg-white rounded-lg shadow-lg w-80">
            <h2 className="mb-4 text-xl font-semibold">
              {isClassActive(selectedClass.id)
                ? 'Deactivate Class?'
                : 'Activate Class?'}
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => handleClassConfirmation(false)}
                className="px-4 py-2 text-gray-800 transition-colors bg-gray-300 rounded hover:bg-gray-400"
                ref={classPopupRef}
              >
                No
              </button>
              <button
                onClick={() => handleClassConfirmation(true)}
                className="px-4 py-2 text-white transition-colors bg-blue-500 rounded hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
