// src/pixi/CharacterThreeJS.js

import React, { useEffect, useState, useRef, memo, useMemo } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, Text, Sprite } from '@react-three/drei';
import * as THREE from 'three';
import { gsap } from 'gsap';
import PropTypes from 'prop-types';
import { BackgroundScene } from './BackgroundScene';
import { SpaceshipControls } from './SpaceshipControls';
import WorldSphere from './WorldSphere';

/* ------------------------------------------------------------------
  5. Main ThreeSVGRenderer
------------------------------------------------------------------ */

const ThreeSVGRenderer = ({ 
  guidanceSystem,
  orbitLock,
  viewLock,
  gravity,
  gravityAssist,
  land,
  selectedCelestialBody,
  speed,
  timelapse,
  planetLabels,
  zoom
}) => {
  const [positions, setPositions] = useState({});

  const handleUpdatePositions = (newPositions) => {
    setPositions(newPositions);
  };

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      <Canvas
        camera={{
          position: [149600000 + 10000, 5000, 10000],
          fov: 40,
          near: 1,
          far: 9000000000,
          up: [0, 1, 0],
        }}
        style={{ 
          width: '100vw',
          height: '100vh',
          position: 'absolute',
          top: 0,
          right: 0,
          background: 'transparent',
        }}
        gl={{
          antialias: true,
          alpha: true,
          depth: true,
          powerPreference: 'high-performance',
          logarithmicDepthBuffer: true
        }}
      >
        <BackgroundScene />
        <SpaceshipControls 
          speed={speed} 
          onUpdatePositions={handleUpdatePositions}
          planetLabels={planetLabels}
          guidanceSystem={guidanceSystem}
          zoom={zoom}
        />
        <WorldSphere 
          guidanceSystem={guidanceSystem}
          orbitLock={orbitLock}
          viewLock={viewLock}
          gravity={gravity}
          gravityAssist={gravityAssist}
          land={land}
          selectedCelestialBody={selectedCelestialBody}
          speed={speed}
          timelapse={timelapse}
          planetLabels={planetLabels}
          onUpdatePositions={handleUpdatePositions}
          zoom={zoom}
        />
      </Canvas>
      
      {/* Overlay for planet labels */}
      <div style={{ 
        position: 'absolute', 
        top: 0,
        left: 0,
        width: '100%', 
        height: '100%', 
        pointerEvents: 'none' 
      }}>
        {Object.entries(positions).map(([name, data]) => {
          if (!data?.isVisible) return null;
          
          // Clamp the position values to stay within viewport
          const clampedX = Math.max(50, Math.min(window.innerWidth - 50, data.x));
          const clampedY = Math.max(50, Math.min(window.innerHeight - 50, data.y));
          
          return (
            <div
              key={name}
              style={{
                position: 'absolute',
                left: `${clampedX - 0}px`,
                top: `${clampedY - 50}px `,
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontSize: '14px',
                textShadow: '2px 2px 2px rgba(0,0,0,0.5)',
                whiteSpace: 'nowrap',
                pointerEvents: 'none',
                textAlign: 'center',
                padding: '5px'
              }}
            >
              <div>{name}</div>
              <div>({(data.distance / 1000).toFixed(2)} km)</div>
              {data.worldPosition && (
                <div>
                  x: {data.worldPosition.x.toFixed(0)} 
                  y: {data.worldPosition.y.toFixed(0)} 
                  z: {data.worldPosition.z.toFixed(0)}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

ThreeSVGRenderer.propTypes = {
  guidanceSystem: PropTypes.bool,
  orbitLock: PropTypes.bool,
  viewLock: PropTypes.bool,
  gravity: PropTypes.bool,
  gravityAssist: PropTypes.bool,
  land: PropTypes.bool,
  selectedCelestialBody: PropTypes.string,
  speed: PropTypes.number,
  timelapse: PropTypes.number,
  zoom: PropTypes.number,
  planetLabels: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    position: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      z: PropTypes.number
    }),
    color: PropTypes.string
  }))
};

export default ThreeSVGRenderer;