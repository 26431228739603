// src/components/ui/Button.js

import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ onClick, children, variant = 'primary', className = '', ...props }) => {
  const baseClasses = 'px-4 py-2 rounded-md focus:outline-none';
  const variantClasses = {
    primary: 'bg-blue-500 text-white hover:bg-blue-600',
    ghost: 'bg-transparent text-gray-700 hover:bg-gray-100',
    // Add more variants as needed
  };

  return (
    <button
      onClick={onClick}
      className={`${baseClasses} ${variantClasses[variant] || ''} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default Button;
