// src/components/Students/StudentHomepage.js

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/beetitle.svg';
import { Hexagons1 } from './Homepage/HexagonButton1';
import './Homepage/HexagonButton1.css';
import Bee from './Homepage/Bee copy';
import treeImage from './Homepage/gif1.gif';
import '../../styles/StudentHomepage.css';

// Import Student apps and Modal
import StudentWhiteboard from './apps/studentwhiteboard';
import StudentSlides from './apps/StudentSlides';
import StudentYourHive from './apps/StudentYourHive';
import StudentSites from './apps/StudentSites';
import StudentTests from './apps/StudentTests';
import StudentJournal from './apps/StudentJournal';
import StudentGames from './apps/StudentGames';
import Modal from '../common/modal';
import MoviePicker from './apps/MoviePicker';

import { useStudentManager } from '../../contexts/Studentmanager';
import { rtdb } from '../../firebase';
import { ref, onValue, off, update } from 'firebase/database';

const StudentHomepage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { studentInfo } = useStudentManager();
  const [moduleStatuses, setModuleStatuses] = useState({});

  const [loading, setLoading] = useState(true);
  const [studentData, setStudentData] = useState(null);

  const hasLoggedOutRef = useRef(false);

  const treeContainerRef = useRef(null);
  const [treeDimensions, setTreeDimensions] = useState(null);

  // State to manage modal visibility for each app
  const [isWhiteboardOpen, setIsWhiteboardOpen] = useState(false);
  const [isSlidesOpen, setIsSlidesOpen] = useState(false);
  const [isYourHiveOpen, setIsYourHiveOpen] = useState(false);
  const [isSitesOpen, setIsSitesOpen] = useState(false);
  const [isTestsOpen, setIsTestsOpen] = useState(false);
  const [isJournalOpen, setIsJournalOpen] = useState(false);
  const [isGamesOpen, setIsGamesOpen] = useState(false);
  const [isMoviePickerOpen, setIsMoviePickerOpen] = useState(false);

  // Ref to keep track of previous moduleStatuses
  const prevModuleStatusesRef = useRef({});

  // State to store active bees
  const [activeBees, setActiveBees] = useState([]);

  // Extract classId and studentName from studentInfo
  const { classId, studentName } = studentInfo;

  // State to store movie filters
  const [movieFilters, setMovieFilters] = useState(null);

  // Function to set the current app in RTDB
  const setCurrentApp = useCallback(
    async (appName) => {
      if (!classId || !studentName) {
        console.error('Class ID or Student Name is missing.');
        return;
      }
      try {
        const beeRef = ref(rtdb, `activeclasses/${classId}/beeNames/${studentName}`);
        await update(beeRef, { currentApp: appName });
      } catch (error) {
        console.error('Error setting current app:', error);
      }
    },
    [classId, studentName]
  );

  // Function to clear the current app in RTDB
  const clearCurrentApp = useCallback(async () => {
    if (!classId || !studentName) {
      console.error('Class ID or Student Name is missing.');
      return;
    }
    try {
      const beeRef = ref(rtdb, `activeclasses/${classId}/beeNames/${studentName}`);
      await update(beeRef, { currentApp: null });
    } catch (error) {
      console.error('Error clearing current app:', error);
    }
  }, [classId, studentName]);

  // Fetch module statuses from RTDB
  useEffect(() => {
    if (!studentInfo || !studentInfo.classId) return;

    const moduleStatusRef = ref(
      rtdb,
      `activeclasses/${studentInfo.classId}/modulestatus`
    );

    const unsubscribe = onValue(moduleStatusRef, (snapshot) => {
      if (snapshot.exists()) {
        setModuleStatuses(snapshot.val());
      }
    });

    return () => {
      off(moduleStatusRef, 'value', unsubscribe);
    };
  }, [studentInfo]);

  // Fetch active bees from RTDB
  useEffect(() => {
    if (!studentInfo || !studentInfo.classId) return;

    const beeNamesRef = ref(
      rtdb,
      `activeclasses/${studentInfo.classId}/beeNames`
    );

    const unsubscribe = onValue(beeNamesRef, (snapshot) => {
      if (snapshot.exists()) {
        const beeData = snapshot.val();
        const activeBeeNames = Object.keys(beeData).filter(
          (beeName) => beeData[beeName].logged === 'yes'
        );
        setActiveBees(activeBeeNames);
      } else {
        setActiveBees([]);
      }
    });

    return () => {
      off(beeNamesRef, 'value', unsubscribe);
    };
  }, [studentInfo]);

  // Effect to handle locked modules and ensure only one modal is open
  useEffect(() => {
    if (!moduleStatuses) return;

    const prevModuleStatuses = prevModuleStatusesRef.current;

    // Identify modules that have changed to 'locked'
    const newlyLockedModules = Object.keys(moduleStatuses).filter(
      (key) =>
        moduleStatuses[key] === 'locked' &&
        prevModuleStatuses[key] !== 'locked'
    );

    if (newlyLockedModules.length > 0) {
      // Close all modals first
      setIsWhiteboardOpen(false);
      setIsSlidesOpen(false);
      setIsYourHiveOpen(false);
      setIsSitesOpen(false);
      setIsTestsOpen(false);
      setIsJournalOpen(false);
      setIsGamesOpen(false);

      // Open the new locked modals
      newlyLockedModules.forEach((moduleKey) => {
        switch (moduleKey) {
          case 'whiteboardactive':
            setIsWhiteboardOpen(true);
            break;
          case 'slidesactive':
            setIsSlidesOpen(true);
            break;
          case 'characteractive':
            setIsYourHiveOpen(true);
            break;
          case 'sitesactive':
            setIsSitesOpen(true);
            break;
          case 'testsactive':
            setIsTestsOpen(true);
            break;
          case 'journalactive':
            setIsJournalOpen(true);
            break;
          case 'gamesactive':
            setIsGamesOpen(true);
            break;
          default:
            break;
        }
      });
    }

    // Update the previous moduleStatuses ref
    prevModuleStatusesRef.current = moduleStatuses;
  }, [moduleStatuses]);

  // Handlers to open modals and set currentApp
  const handleOpenWhiteboard = useCallback(async () => {
    setIsWhiteboardOpen(true);
    await setCurrentApp('whiteboard');
  }, [setCurrentApp]);

  const handleOpenSlides = useCallback(async () => {
    setIsSlidesOpen(true);
    await setCurrentApp('slides');
  }, [setCurrentApp]);

  const handleOpenYourHive = useCallback(async () => {
    setIsYourHiveOpen(true);
    await setCurrentApp('yourHive');
  }, [setCurrentApp]);

  const handleOpenSites = useCallback(async () => {
    setIsSitesOpen(true);
    await setCurrentApp('sites');
  }, [setCurrentApp]);

  const handleOpenTests = useCallback(async () => {
    setIsTestsOpen(true);
    await setCurrentApp('tests');
  }, [setCurrentApp]);

  const handleOpenJournal = useCallback(async () => {
    setIsJournalOpen(true);
    await setCurrentApp('journal');
  }, [setCurrentApp]);

  const handleOpenGames = useCallback(async () => {
    setIsGamesOpen(true);
    await setCurrentApp('games');
  }, [setCurrentApp]);

  // Handlers to close modals and clear currentApp
  const handleCloseWhiteboard = useCallback(async () => {
    if (moduleStatuses.whiteboardactive === 'locked') {
      return;
    }
    setIsWhiteboardOpen(false);
    await clearCurrentApp();
  }, [moduleStatuses.whiteboardactive, clearCurrentApp]);

  const handleCloseSlides = useCallback(async () => {
    if (moduleStatuses.slidesactive === 'locked') {
      return;
    }
    setIsSlidesOpen(false);
    await clearCurrentApp();
  }, [moduleStatuses.slidesactive, clearCurrentApp]);

  const handleCloseYourHive = useCallback(async () => {
    if (moduleStatuses.characteractive === 'locked') {
      return;
    }
    setIsYourHiveOpen(false);
    await clearCurrentApp();
  }, [moduleStatuses.characteractive, clearCurrentApp]);

  const handleCloseSites = useCallback(async () => {
    if (moduleStatuses.sitesactive === 'locked') {
      return;
    }
    setIsSitesOpen(false);
    await clearCurrentApp();
  }, [moduleStatuses.sitesactive, clearCurrentApp]);

  const handleCloseTests = useCallback(async () => {
    if (moduleStatuses.testsactive === 'locked') {
      return;
    }
    setIsTestsOpen(false);
    await clearCurrentApp();
  }, [moduleStatuses.testsactive, clearCurrentApp]);

  const handleCloseJournal = useCallback(async () => {
    if (moduleStatuses.journalactive === 'locked') {
      return;
    }
    setIsJournalOpen(false);
    await clearCurrentApp();
  }, [moduleStatuses.journalactive, clearCurrentApp]);

  const handleCloseGames = useCallback(async () => {
    if (moduleStatuses.gamesactive === 'locked') {
      return;
    }
    setIsGamesOpen(false);
    await clearCurrentApp();
  }, [moduleStatuses.gamesactive, clearCurrentApp]);

  /**
   * Handles user logout.
   */
  const handleLogout = useCallback(async () => {
    if (hasLoggedOutRef.current) return;
    hasLoggedOutRef.current = true;

    console.log('handleLogout triggered.');

    if (!currentUser) {
      console.log('No authenticated user. Redirecting to home.');
      navigate('/');
      return;
    }

    // Add your logout logic here if needed

    navigate('/');
  }, [currentUser, navigate]);

  /**
   * Fetches student data and updates the loading state.
   */
  useEffect(() => {
    const fetchStudentData = async () => {
      if (!currentUser) {
        setLoading(false);
        navigate('/');
        return;
      }

      // Simulate fetching data
      setTimeout(() => {
        setStudentData({ name: 'Student Name' });
        setLoading(false);
      }, 1000);
    };

    fetchStudentData();
  }, [currentUser, navigate]);

  /**
   * Updates tree dimensions based on the tree container's bounding rectangle.
   */
  const updateTreeDimensions = useCallback(() => {
    if (treeContainerRef.current) {
      const rect = treeContainerRef.current.getBoundingClientRect();
      setTreeDimensions({
        width: rect.width,
        height: rect.height,
      });
    }
  }, []);

  // Update tree dimensions on window resize
  useEffect(() => {
    updateTreeDimensions(); // Initial dimension calculation
    window.addEventListener('resize', updateTreeDimensions);

    return () => {
      window.removeEventListener('resize', updateTreeDimensions);
    };
  }, [updateTreeDimensions]);

  // Add new effect to listen for MoviePicker status
  useEffect(() => {
    if (!studentInfo || !studentInfo.classId) return;

    const moviePickerRef = ref(
      rtdb,
      `activeclasses/${studentInfo.classId}/MoviePicker/status`
    );

    const unsubscribe = onValue(moviePickerRef, (snapshot) => {
      if (snapshot.exists()) {
        const status = snapshot.val();
        if (status === 'started') {
          setIsMoviePickerOpen(true);
        } else {
          setIsMoviePickerOpen(false);
        }
      }
    });

    return () => {
      off(moviePickerRef, 'value', unsubscribe);
    };
  }, [studentInfo]);

  // Add new effect to fetch MoviePicker filters
  useEffect(() => {
    if (!studentInfo || !studentInfo.classId) return;

    const filtersRef = ref(
      rtdb,
      `activeclasses/${studentInfo.classId}/MoviePicker/filters`
    );

    const unsubscribe = onValue(filtersRef, (snapshot) => {
      if (snapshot.exists()) {
        setMovieFilters(snapshot.val());
      }
    });

    return () => {
      off(filtersRef, 'value', unsubscribe);
    };
  }, [studentInfo]);

  // Add this console.log above the return statement to verify filters are being received
  useEffect(() => {
    if (movieFilters) {
      console.log('StudentHomepage movieFilters:', movieFilters);
    }
  }, [movieFilters]);

  /**
   * Renders a loading screen while data is being fetched.
   */
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-50 to-green-50">
        <div className="text-xl">Loading...</div>
      </div>
    );
  }

  /**
   * Renders the main homepage UI.
   */
  return (
    <div className="relative flex flex-col w-full min-h-screen p-0 bg-gradient-to-br from-blue-50 to-green-50">
      {/* Header with Logo */}
      <header className="flex items-center justify-center flex-none h-20 mb-4">
        <img src={logo} alt="Logo" className="w-auto h-20" />
      </header>

      {/* Main Content Wrapper */}
      <div className="flex flex-col flex-1 min-h-0 p-4">
        {/* Tree Image and Bees Container */}
        <div className="relative w-full mx-auto mb-5 bg-transparent">
          <div className="relative flex justify-center inline-block w-full" ref={treeContainerRef}>
            <img
              src={treeImage}
              alt="Welcome Tree"
              className="object-contain h-full"
              style={{ width: '95%', maxHeight: '400px'}}
              onLoad={updateTreeDimensions}
            />

            {/* Bees Flying Around the Tree */}
            {treeDimensions && (
              <div className="absolute inset-0">
                {activeBees.map((beeName, index) => (
                  <Bee
                    key={index}
                    name={beeName}
                    boundaries={treeDimensions}
                    isCurrentUser={beeName === studentName}
                  />
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Tiles Section with Hexagon Arrangement */}
        <div className="relative flex-1 w-full h-full mx-auto mt-20 mb-5 bg-transparent">
          {/* Ensure the container is a flex container to allow Hexagons to fill it */}
          <div className="flex flex-col w-full h-full">
            <Hexagons1
              className="flex-1 w-full h-full"
              handleStartWhiteboard={handleOpenWhiteboard}
              handleStartSlides={handleOpenSlides}
              handleStartYourHive={handleOpenYourHive}
              handleStartSites={handleOpenSites}
              handleStartTests={handleOpenTests}
              handleStartJournal={handleOpenJournal}
              handleStartGames={handleOpenGames}
              moduleStatuses={moduleStatuses}
            />
          </div>
        </div>

        {/* Footer */}
        <footer className="w-full py-4 text-center text-gray-600">
          <p>&copy; 2024 Learning Resources. All rights reserved.</p>
        </footer>
      </div>

      {/* Add MoviePicker Modal */}
      {isMoviePickerOpen && classId && (
        <Modal
          onClose={() => setIsMoviePickerOpen(false)}
          disableClose={true}
          noPadding
        >
          <MoviePicker 
            classID={classId} 
            filters={movieFilters}
            onClose={() => setIsMoviePickerOpen(false)}
          />
        </Modal>
      )}

      {/* Modals for each app */}
      {isWhiteboardOpen && classId && (
        <Modal
          onClose={handleCloseWhiteboard}
          disableClose={moduleStatuses.whiteboardactive === 'locked'}
          noPadding
        >
          <StudentWhiteboard classId={classId} />
        </Modal>
      )}

      {isSlidesOpen && classId && (
        <Modal
          onClose={handleCloseSlides}
          disableClose={moduleStatuses.slidesactive === 'locked'}
          noPadding
        >
          <StudentSlides classId={classId} />
        </Modal>
      )}

      {isYourHiveOpen && classId && (
        <Modal
          onClose={handleCloseYourHive}
          disableClose={moduleStatuses.characteractive === 'locked'}
          noPadding
        >
          <StudentYourHive classId={classId} />
        </Modal>
      )}

      {isSitesOpen && classId && (
        <Modal
          onClose={handleCloseSites}
          disableClose={moduleStatuses.sitesactive === 'locked'}
          noPadding
        >
          <StudentSites classId={classId} />
        </Modal>
      )}

      {isTestsOpen && classId && (
        <Modal
          onClose={handleCloseTests}
          disableClose={moduleStatuses.testsactive === 'locked'}
          noPadding
        >
          <StudentTests classId={classId} />
        </Modal>
      )}

      {isJournalOpen && classId && (
        <Modal
          onClose={handleCloseJournal}
          disableClose={moduleStatuses.journalactive === 'locked'}
          noPadding
        >
          <StudentJournal classId={classId} />
        </Modal>
      )}

      {isGamesOpen && classId && (
        <Modal
          onClose={handleCloseGames}
          disableClose={moduleStatuses.gamesactive === 'locked'}
          noPadding
        >
          <StudentGames classId={classId} />
        </Modal>
      )}
    </div>
  );
};

export default React.memo(StudentHomepage);
