// src/components/Teachers/HexagonButton2.js

import React from 'react';
import PropTypes from 'prop-types';
import './HexagonButton2.css';
import SVGhexagon from '../../assets/SVGhexagon.svg'; // Import the SVG hexagon
import XPProgressArc from './TeacherXPProgressArc'; // Import XPProgressArc

// HexagonButton2 Component
const HexagonButton2 = ({ label, onClick, children = null, disabled = false }) => {
  const handleClick = (e) => {
    if (disabled) return; // Prevent action if disabled
    onClick && onClick(e);
  };

  return (
    <div
      className={`hexagon-button2 ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
      role="button"
      aria-label={label}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      onKeyPress={(e) => {
        if (e.key === 'Enter' && !disabled) onClick(e);
      }}
    >
      {/* Use the imported SVG as an image */}
      <img
        src={SVGhexagon}
        alt=""
        className="hexagon-svg2"
        aria-hidden="true"
      />
      <div className="hexagon-label2">{label}</div>
      {/* Render any additional children */}
      {children}
    </div>
  );
};

HexagonButton2.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node, // Allow children
  disabled: PropTypes.bool,  // Optional: Handle disabled state
};

// Hexagons2 Component with Spacer
const Hexagons2 = ({
  handleStartSlides,
  handleStartWhiteboard,
  handleStartBookQuest,
  handleStartAssessment,
  handleStartTeacherWorkbook,
  handleStartViewSites,
  handleStartGames,
  handleStartTests,
  handleOpenProgressModal, // New prop
  moduleStatuses, // Optional: If you have module statuses for disabling
  averageXP, // **New prop to receive averageXP**
}) => {
  const hexagons2 = [
    {
      label: 'Sites',
      onClick: handleStartViewSites,
      position: 'row-start-1 col-start-1',
      translateX: '104%',
      translateY: '54%',
      zIndex: 1,
    },
    {
      label: 'Lessons',
      onClick: handleStartSlides,
      position: 'row-start-1 col-start-1',
      translateX: '-52%',
      translateY: '132%',
      zIndex: 1,
    },
    {
      label: 'White Board',
      onClick: handleStartWhiteboard,
      position: 'row-start-1 col-start-1',
      translateX: '52%',
      translateY: '132%',
      zIndex: 1,
    },
    {
      label: 'Progress',
      onClick: handleOpenProgressModal, // Updated to use the handler
      position: 'row-start-1 col-start-1',
      translateX: '0%',
      translateY: '54%',
      zIndex: 1,
      isProgress: true, // Custom flag to identify the Progress hexagon
      moduleKey: 'progressactive', // Optional: For handling disabled state
    },
    {
      label: 'Build',
      onClick: handleStartTests,
      position: 'row-start-1 col-start-1',
      translateX: '-52%',
      translateY: '-24%',
      zIndex: 1,
    },
    {
      label: 'Journal',
      onClick: handleStartTeacherWorkbook,
      position: 'row-start-1 col-start-1',
      translateX: '-104%',
      translateY: '54%',
      zIndex: 1,
    },
    {
      label: 'Read',
      onClick: handleStartGames,
      position: 'row-start-1 col-start-1',
      translateX: '52%',
      translateY: '-24%',
      zIndex: 1,
    },
    // Removed duplicate 'Games' entry
  ];

  return (
    <>
      <div className="grid items-center max-w-5xl grid-cols-1 grid-rows-1 gap-20 mx-auto mb-20 justify-items-center">
        {hexagons2.map((hex, index) => {
          // Determine if the hexagon should be disabled
          const isDisabled =
            hex.moduleKey &&
            moduleStatuses &&
            moduleStatuses[hex.moduleKey] !== 'active' &&
            moduleStatuses[hex.moduleKey] !== 'locked';

          return (
            <div
              key={index}
              className={`transition-transform duration-300 ${
                isDisabled ? '' : 'hover:scale-105'
              }`}
              style={{
                gridRowStart: hex.position.split(' ')[0].split('-')[2],
                gridColumnStart: hex.position.split(' ')[1].split('-')[2],
                transform: `translate(${hex.translateX}, ${hex.translateY})`,
                /* Ensure the hexagon shape via clipping */
                clipPath: 'polygon(100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%, 50% 0%)',
                WebkitClipPath: 'polygon(100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%, 50% 0%)',
                overflow: 'hidden', /* Ensure child elements are clipped */
                zIndex: hex.zIndex,
                opacity: isDisabled ? 0.5 : 1, // Optional: Adjust opacity if disabled
              }}
            >
              {hex.isProgress ? (
                // Render HexagonButton2 with XPProgressArc as a child
                <HexagonButton2
                  label={hex.label}
                  onClick={hex.onClick} // Now linked to handleOpenProgressModal
                  disabled={isDisabled}
                >
                  {/* Always render XPProgressArc, regardless of disabled state */}
                  <div className="xp-progress-arc-container">
                    <XPProgressArc averageXP={averageXP} /> {/* **Pass averageXP from props** */}
                  </div>
                </HexagonButton2>
              ) : (
                // Render HexagonButton2 for all other hexagons
                <HexagonButton2
                  label={hex.label}
                  onClick={hex.onClick}
                  disabled={isDisabled}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="h-[100px]"></div>
    </>
  );
};

Hexagons2.propTypes = {
  handleStartSlides: PropTypes.func.isRequired,
  handleStartWhiteboard: PropTypes.func.isRequired,
  handleStartBookQuest: PropTypes.func.isRequired,
  handleStartAssessment: PropTypes.func.isRequired,
  handleStartTeacherWorkbook: PropTypes.func.isRequired,
  handleStartViewSites: PropTypes.func.isRequired,
  handleStartGames: PropTypes.func.isRequired,
  handleStartTests: PropTypes.func.isRequired,
  handleOpenProgressModal: PropTypes.func.isRequired, // New prop validation
  moduleStatuses: PropTypes.object, // Optional: If using module statuses
  averageXP: PropTypes.number.isRequired, // **Validate averageXP**
};

export { HexagonButton2, Hexagons2 };
