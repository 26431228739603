// XPProgressArc Component

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'; // Ensure PropTypes is imported

// Constants and utility functions
const MAX_LEVEL = 250;
const BASE_XP = 100;
const XP_INCREMENT_RATIO = 1.1;
const NUM_BEES = 3; // Number of bees (can be removed if no longer needed)

const computeXpPerLevel = () => {
  const xpPerLevel = [BASE_XP];
  for (let i = 1; i < MAX_LEVEL; i++) {
    const nextXp = Math.round(xpPerLevel[i - 1] * XP_INCREMENT_RATIO);
    xpPerLevel.push(nextXp);
  }
  return xpPerLevel;
};

const xpPerLevel = computeXpPerLevel();

const XPProgressArc = ({ averageXP }) => {
  const [averageLevel, setAverageLevel] = useState(0);
  const [averageCurrentXP, setAverageCurrentXP] = useState(0);
  const [displayXP, setDisplayXP] = useState(0);
  const [canLevelUp, setCanLevelUp] = useState(false);
  const animationRef = useRef(null);
  const levelUpTimeoutRef = useRef(null);

  // Compute average level based on average XP
  useEffect(() => {
    let accumulatedXP = 0;
    let level = 0;
    while (level < MAX_LEVEL && accumulatedXP + xpPerLevel[level] <= averageXP) {
      accumulatedXP += xpPerLevel[level];
      level++;
    }
    setAverageLevel(level);
    setAverageCurrentXP(averageXP - accumulatedXP);
  }, [averageXP]);

  // Current XP required for next level
  const currentXPRequired =
    averageLevel < MAX_LEVEL ? xpPerLevel[averageLevel] : xpPerLevel[MAX_LEVEL - 1];

  // Calculate progress percentage based on displayXP
  const progress = Math.min(displayXP / currentXPRequired, 1);

  // Arc parameters
  const radius = 80;
  const strokeWidth = 10;
  const center = 100;

  // Calculate the SVG path for the arc
  const getArcPath = (percentage) => {
    const angleStart = 180;
    const angleEnd = 180 + 180 * percentage;

    const startRad = (angleStart * Math.PI) / 180;
    const endRad = (angleEnd * Math.PI) / 180;

    const startX = center + radius * Math.cos(startRad);
    const startY = center + radius * Math.sin(startRad);
    const endX = center + radius * Math.cos(endRad);
    const endY = center + radius * Math.sin(endRad);

    const sweepAngle = 180 * percentage;
    const largeArcFlag = sweepAngle > 180 ? 1 : 0;
    const sweepFlag = 1;

    return `M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} ${sweepFlag} ${endX} ${endY}`;
  };

  // Animation Effect for Smooth XP Transition
  useEffect(() => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }

    const duration = 2000; // Duration of the animation in ms
    const frameRate = 60; // Frames per second
    const totalFrames = Math.round((duration / 1000) * frameRate);
    let frame = 0;

    const startXP = displayXP;
    const endXP = Math.min(averageCurrentXP, currentXPRequired);
    const deltaXP = endXP - startXP;

    const easeInOutCubic = (t) =>
      t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;

    const animate = () => {
      frame++;
      const progressFraction = frame / totalFrames;
      const easeProgress = easeInOutCubic(progressFraction);
      const newDisplayXP = Math.round(startXP + deltaXP * easeProgress);

      setDisplayXP(newDisplayXP);

      if (frame < totalFrames) {
        animationRef.current = requestAnimationFrame(animate);
      } else {
        setDisplayXP(endXP);
      }
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationRef.current);
  }, [averageCurrentXP, currentXPRequired]);

  // Update canLevelUp state when XP reaches 100%
  useEffect(() => {
    setCanLevelUp(averageCurrentXP >= currentXPRequired && averageLevel < MAX_LEVEL);
  }, [averageCurrentXP, currentXPRequired, averageLevel]);

  // Auto Level-Up Effect
  useEffect(() => {
    if (canLevelUp) {
      // Set a timeout to auto level up after 2000ms
      levelUpTimeoutRef.current = setTimeout(() => {
        handleLevelUp();
      }, 2000);
    }

    // Cleanup the timeout if canLevelUp changes before timeout completes
    return () => {
      if (levelUpTimeoutRef.current) {
        clearTimeout(levelUpTimeoutRef.current);
      }
    };
  }, [canLevelUp]);

  const handleLevelUp = () => {
    if (averageLevel >= MAX_LEVEL || !canLevelUp) return;

    // Implement any level-up related logic here.
    console.log('Average XP reached for level up.');
  };

  return (
    <div className="relative flex flex-col items-center gap-0 p-6 pointer-events-none">
      {/* Replace style jsx with regular style tag */}
      <style>
        {`
          /* Add your CSS styles here */
        `}
      </style>

      {/* Main Container has pointer-events: none to allow clicks to pass through */}
      <div className="relative">
        <div className="w-20 h-20 bg-gray-100 rounded-full" />

        {/* Adjusted SVG Positioning */}
        <svg
          className="absolute z-30 w-20 h-20 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          viewBox="0 0 200 200"
        >
          <path
            d={getArcPath(1)}
            fill="none"
            stroke="#e5e7eb"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
          <path
            d={getArcPath(progress)}
            fill="none"
            stroke="#4f46e5"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
        </svg>

        {/* Removed Floating Bees */}

        {/* Updated Hive and Level Text */}
        <div className="absolute z-20 text-center transform -translate-x-1/2 -translate-y-0 top-7 left-1/2">
          <div className="text-sm font-bold text-gray-800">Hive</div>
          <div className="text-[10px] font-bold text-gray-800 whitespace-nowrap">
            Level: {averageLevel}
          </div>
        </div>
      </div>

      {/* Level Up Indicator */}
      <div className="mt-2">
        {canLevelUp && (
          <span className="text-sm font-bold text-green-500">
            Average Level Up!
          </span>
        )}
      </div>
    </div>
  );
};

XPProgressArc.propTypes = {
  averageXP: PropTypes.number.isRequired, // Ensure PropTypes is defined
};

export default XPProgressArc;