import React, { useEffect, useMemo, memo } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';

export const BackgroundScene = memo(() => {
  const { gl, scene, camera } = useThree();
  const backgroundScene = useMemo(() => new THREE.Scene(), []);
  const backgroundCamera = useMemo(() => new THREE.PerspectiveCamera(
    camera.fov,
    window.innerWidth / window.innerHeight,
    1,
    2000
  ), [camera.fov]);

  useEffect(() => {
    backgroundScene.background = new THREE.Color(0x000000);

    const starsGeometry = new THREE.BufferGeometry();
    const starColors = new Float32Array(10000 * 3);
    const starSizes = new Float32Array(10000);
    const starsVertices = [];
    
    const flickerSpeeds = new Float32Array(10000);
    const flickerPhases = new Float32Array(10000);
    const flickerIntensities = new Float32Array(10000);
    const originalColors = new Float32Array(10000 * 3);

    for (let i = 0; i < 10000; i++) {
      const x = (Math.random() - 0.5) * 2000;
      const y = (Math.random() - 0.5) * 2000;
      const z = (Math.random() - 0.5) * 2000;
      starsVertices.push(x, y, z);

      starSizes[i] = Math.random() * 7 + 1;

      const hue = Math.random() * 0.2;
      const saturation = Math.random() * 0.5;
      const lightness = 0.7 + Math.random() * 0.3;
      const color = new THREE.Color().setHSL(hue, saturation, lightness);
      
      starColors[i * 3] = color.r;
      starColors[i * 3 + 1] = color.g;
      starColors[i * 3 + 2] = color.b;
      
      originalColors[i * 3] = color.r;
      originalColors[i * 3 + 1] = color.g;
      originalColors[i * 3 + 2] = color.b;

      flickerSpeeds[i] = Math.random() * 0.05;
      flickerPhases[i] = Math.random() * Math.PI * 2;
      flickerIntensities[i] = Math.random();
    }

    starsGeometry.setAttribute('position', new THREE.Float32BufferAttribute(starsVertices, 3));
    starsGeometry.setAttribute('color', new THREE.Float32BufferAttribute(starColors, 3));
    starsGeometry.setAttribute('size', new THREE.Float32BufferAttribute(starSizes, 1));

    const starsMaterial = new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 }
      },
      vertexShader: `
        attribute float size;
        attribute vec3 color;
        varying vec3 vColor;
        void main() {
          vColor = color;
          vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
          gl_Position = projectionMatrix * mvPosition;
          gl_PointSize = size * (300.0 / -mvPosition.z);
        }
      `,
      fragmentShader: `
        varying vec3 vColor;
        void main() {
          vec2 center = gl_PointCoord * 2.0 - 1.0;
          float r = length(center);
          float a = atan(center.y, center.x);
          
          // Create a 5-pointed star shape
          float spike = cos(a * 5.0) * 0.5 + 0.5;
          float star = 1.0 - smoothstep(spike * 0.5, spike * 0.5 + 0.1, r);
          
          // Add a glow effect
          float glow = 1.0 - smoothstep(0.5, 1.0, r);
          
          if (star + glow * 0.3 < 0.1) discard;
          
          gl_FragColor = vec4(vColor, star + glow * 0.3);
        }
      `,
      transparent: true,
      depthWrite: false,
      depthTest: true,
      blending: THREE.AdditiveBlending
    });

    const stars = new THREE.Points(starsGeometry, starsMaterial);
    backgroundScene.add(stars);

    let time = 0;
    const animate = () => {
      time += 0.016;
      starsMaterial.uniforms.time.value = time;
      const colors = starsGeometry.attributes.color.array;
      const sizes = starsGeometry.attributes.size.array;

      for (let i = 0; i < 10000; i++) {
        if (flickerIntensities[i] > 0.2) {
          const flicker = Math.sin(time * flickerSpeeds[i] * 40 + flickerPhases[i]);
          const brightness = 0.7 + (Math.pow(flicker, 2) * 0.6 * flickerIntensities[i]);
          
          colors[i * 3] = originalColors[i * 3] * brightness;
          colors[i * 3 + 1] = originalColors[i * 3 + 1] * brightness;
          colors[i * 3 + 2] = originalColors[i * 3 + 2] * brightness;
          
          sizes[i] = starSizes[i] * (0.9 + Math.pow(flicker, 2) * 0.2);
        }
      }

      starsGeometry.attributes.color.needsUpdate = true;
      starsGeometry.attributes.size.needsUpdate = true;
      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      starsGeometry.dispose();
      starsMaterial.dispose();
    };
  }, [backgroundScene]);

  useFrame(({ gl }) => {
    backgroundCamera.fov = camera.fov;
    backgroundCamera.aspect = camera.aspect;
    backgroundCamera.updateProjectionMatrix();
    
    backgroundCamera.quaternion.copy(camera.quaternion);
    
    gl.autoClear = true;
    gl.clear();
    gl.render(backgroundScene, backgroundCamera);
    gl.autoClear = false;
  }, -1);

  return null;
});

export default BackgroundScene; 