import React, { useRef, useEffect, useState } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import PropTypes from 'prop-types';

const FirstPersonControls = ({ joystickInput, isInventoryOpen, setIsInventoryOpen }) => {
  const { camera, gl: { domElement } } = useThree();
  
  const [isLocked, setIsLocked] = useState(false);

  const move = useRef({
    forward: false,
    backward: false,
    left: false,
    right: false,
    up: false,
    down: false,
  });

  // Make move ref accessible globally for the buttons
  window.moveRef = move;

  const velocity = 5;

  const rotation = useRef({ yaw: 0, pitch: 0 });
  const sensitivity = 0.002;

  // Add touch controls state
  const [touchState, setTouchState] = useState({
    lookTouch: null,
    moveTouch: null,
  });

  useEffect(() => {
    camera.rotation.set(0, 0, 0);
    camera.up.set(0, 1, 0);
    camera.rotation.order = 'YXZ';
  }, [camera]);

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
      console.log('Context menu opened and prevented.');
    };
    domElement.addEventListener('contextmenu', handleContextMenu);

    return () => {
      domElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [domElement]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const key = event.key.toLowerCase();
      
      if (key === 't') {
        if (isInventoryOpen) {
          // If inventory is open, close it and request pointer lock
          setIsInventoryOpen(false);
          domElement.requestPointerLock().catch((err) => {
            console.error('Failed to request pointer lock:', err);
          });
        } else {
          // If inventory is closed, open it and exit pointer lock
          setIsInventoryOpen(true);
          if (document.pointerLockElement === domElement) {
            document.exitPointerLock();
          }
        }
        return;
      }

      // Don't handle movement if inventory is open
      if (isInventoryOpen) return;
      
      switch (event.code) {
        case 'KeyS':
          move.current.forward = true;
          break;
        case 'KeyW':
          move.current.backward = true;
          break;
        case 'KeyD':
          move.current.left = true;
          break;
        case 'KeyA':
          move.current.right = true;
          break;
        case 'Space':
          move.current.up = true;
          break;
        case 'ShiftLeft':
        case 'ShiftRight':
          move.current.down = true;
          break;
        // Arrow keys added below
        case 'ArrowDown':
          move.current.forward = true;
          break;
        case 'ArrowUp':
          move.current.backward = true;
          break;
        case 'ArrowLeft':
          move.current.right = true;
          break;
        case 'ArrowRight':
          move.current.left = true;
          break;
        default:
          break;
      }
      // Prevent default behavior for specific keys including arrow keys
      if (['KeyQ', 'KeyE', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.code)) {
        event.preventDefault();
      }
    };

    const onKeyUp = (event) => {
      switch (event.code) {
        case 'KeyS':
          move.current.forward = false;
          break;
        case 'KeyW':
          move.current.backward = false;
          break;
        case 'KeyD':
          move.current.left = false;
          break;
        case 'KeyA':
          move.current.right = false;
          break;
        case 'Space':
          move.current.up = false;
          console.log('Key Released: Space (Stop Moving Up)');
          break;
        case 'ShiftLeft':
        case 'ShiftRight':
          move.current.down = false;
          console.log('Key Released: Shift (Stop Moving Down)');
          break;
        // Arrow keys added below
        case 'ArrowDown':
          move.current.forward = false;
          break;
        case 'ArrowUp':
          move.current.backward = false;
          break;
        case 'ArrowLeft':
          move.current.right = false;
          break;
        case 'ArrowRight':
          move.current.left = false;
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [isInventoryOpen, setIsInventoryOpen, domElement]);

  useEffect(() => {
    const onPointerLockChange = () => {
      const lockedElement = document.pointerLockElement;
      const newLockedState = lockedElement === domElement;
      setIsLocked(newLockedState);
      
      // If pointer lock is exited, open inventory
      if (!newLockedState) {
        setIsInventoryOpen(true);
      }
    };

    const onPointerLockError = () => {
      console.error('Pointer Lock Error');
      setIsLocked(false);
      setIsInventoryOpen(true);
    };

    document.addEventListener('pointerlockchange', onPointerLockChange);
    document.addEventListener('pointerlockerror', onPointerLockError);

    return () => {
      document.removeEventListener('pointerlockchange', onPointerLockChange);
      document.removeEventListener('pointerlockerror', onPointerLockError);
      if (document.pointerLockElement === domElement) {
        document.exitPointerLock();
      }
    };
  }, [domElement, setIsInventoryOpen]);

  useEffect(() => {
    const onClick = () => {
      if (isInventoryOpen) {
        // If inventory is open, close it and request pointer lock
        setIsInventoryOpen(false);
        domElement.requestPointerLock().catch((err) => {
          console.error('Failed to request pointer lock:', err);
        });
      } else if (!isLocked) {
        // If inventory is closed and not locked, request pointer lock
        domElement.requestPointerLock().catch((err) => {
          console.error('Failed to request pointer lock:', err);
        });
      }
    };

    domElement.addEventListener('click', onClick);

    return () => {
      domElement.removeEventListener('click', onClick);
    };
  }, [domElement, isLocked, isInventoryOpen, setIsInventoryOpen]);

  // Disable mouse movement when inventory is open
  useEffect(() => {
    if (!isLocked || isInventoryOpen) return;

    const onMouseMove = (event) => {
      const { movementX, movementY } = event;

      rotation.current.yaw -= movementX * sensitivity;
      rotation.current.pitch -= movementY * sensitivity;

      const PI_2 = Math.PI / 2;
      rotation.current.pitch = Math.max(-PI_2, Math.min(PI_2, rotation.current.pitch));

      camera.rotation.set(rotation.current.pitch, rotation.current.yaw, 0);
    };

    document.addEventListener('mousemove', onMouseMove);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [isLocked, isInventoryOpen, camera, sensitivity]);

  // Add joystick effect
  useEffect(() => {
    if (joystickInput) {
      const { x, y } = joystickInput;
      // Update movement based on joystick input
      move.current.forward = y > 0.1;
      move.current.backward = y < -0.1;
      move.current.left = x < -0.1;
      move.current.right = x > 0.1;
      
      // Optional: Log joystick values for debugging
      console.log('Joystick input:', { x, y });
    }
  }, [joystickInput]);

  useFrame((state, delta) => {
    const direction = new THREE.Vector3();
    const velocityVector = new THREE.Vector3();

    if (move.current.forward) velocityVector.z -= 1;
    if (move.current.backward) velocityVector.z += 1;
    if (move.current.left) velocityVector.x -= 1;
    if (move.current.right) velocityVector.x += 1;
    if (move.current.up) velocityVector.y += 1;
    if (move.current.down) velocityVector.y -= 1;

    if (
      !move.current.forward &&
      !move.current.backward &&
      !move.current.left &&
      !move.current.right &&
      !move.current.up &&
      !move.current.down
    ) {
      return;
    }

    direction.copy(velocityVector).normalize();
    const moveDistance = velocity * delta;

    const forward = new THREE.Vector3();
    camera.getWorldDirection(forward);
    forward.y = 0;
    forward.normalize();

    const right = new THREE.Vector3();
    right.crossVectors(camera.up, forward).normalize();

    const deltaPosition = new THREE.Vector3()
      .addScaledVector(forward, direction.z * moveDistance)
      .addScaledVector(right, direction.x * moveDistance)
      .add(new THREE.Vector3(0, direction.y * moveDistance, 0));

    camera.position.add(deltaPosition);

    // Clamp camera position within the boundary
    const boundary = 25;
    camera.position.x = THREE.MathUtils.clamp(camera.position.x, -boundary, boundary);
    camera.position.z = THREE.MathUtils.clamp(camera.position.z, -boundary, boundary);
    camera.position.y = THREE.MathUtils.clamp(camera.position.y, 0, boundary);
  });

  // Handle touch controls
  useEffect(() => {
    const handleTouchStart = (event) => {
      event.preventDefault();
      const touches = event.touches;
      
      // First touch controls looking
      if (touches.length >= 1) {
        const touch = touches[0];
        if (touch.clientX > window.innerWidth / 2) {
          setTouchState(prev => ({ ...prev, lookTouch: touch }));
        } else {
          setTouchState(prev => ({ ...prev, moveTouch: touch }));
        }
      }
    };

    const handleTouchMove = (event) => {
      event.preventDefault();
      const touches = event.touches;
      
      // Handle looking
      if (touchState.lookTouch) {
        const touch = Array.from(touches).find(t => t.identifier === touchState.lookTouch.identifier);
        if (touch) {
          const movementX = touch.clientX - touchState.lookTouch.clientX;
          const movementY = touch.clientY - touchState.lookTouch.clientY;

          rotation.current.yaw -= movementX * sensitivity;
          rotation.current.pitch -= movementY * sensitivity;

          rotation.current.pitch = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, rotation.current.pitch));
          camera.rotation.set(rotation.current.pitch, rotation.current.yaw, 0);

          setTouchState(prev => ({ ...prev, lookTouch: touch }));
        }
      }

      // Handle movement
      if (touchState.moveTouch) {
        const touch = Array.from(touches).find(t => t.identifier === touchState.moveTouch.identifier);
        if (touch) {
          const centerX = window.innerWidth / 4;
          const centerY = window.innerHeight / 2;
          
          const deltaX = touch.clientX - centerX;
          const deltaY = touch.clientY - centerY;
          
          move.current.left = deltaX > 20;
          move.current.right = deltaX < -20;
          move.current.backward = deltaY > 20;
          move.current.forward = deltaY > -20;
          move.current.backward =  deltaY < 20;
        }
      }
    };

    const handleTouchEnd = (event) => {
      event.preventDefault();
      const touches = event.changedTouches;
      
      for (let touch of touches) {
        if (touchState.lookTouch?.identifier === touch.identifier) {
          setTouchState(prev => ({ ...prev, lookTouch: null }));
        }
        if (touchState.moveTouch?.identifier === touch.identifier) {
          setTouchState(prev => ({ ...prev, moveTouch: null }));
          // Reset movement state
          move.current = {
            forward: false,
            backward: false,
            left: false,
            right: false,
            up: false,
            down: false,
          };
        }
      }
    };

    domElement.addEventListener('touchstart', handleTouchStart, { passive: false });
    domElement.addEventListener('touchmove', handleTouchMove, { passive: false });
    domElement.addEventListener('touchend', handleTouchEnd, { passive: false });

    return () => {
      domElement.removeEventListener('touchstart', handleTouchStart);
      domElement.removeEventListener('touchmove', handleTouchMove);
      domElement.removeEventListener('touchend', handleTouchEnd);
    };
  }, [camera, domElement, sensitivity, touchState]);

  return null;
};

FirstPersonControls.propTypes = {
  joystickInput: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  isInventoryOpen: PropTypes.bool.isRequired,
  setIsInventoryOpen: PropTypes.func.isRequired,
};

export default FirstPersonControls;